import { Component } from '@angular/core';
import { OptionsFilterInput, FilterSelection } from '../options-filter-input.base';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { Q_OP_CON } from 'src/app/core/constants/query.constants';
import { QueryFilterExpression } from 'src/app/core/interfaces/query/filters/query-filter';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-list-options-filter-input',
  templateUrl: './list-options-filter-input.component.html',
  styleUrls: ['../options-filter-input.component.css'],
})
export class ListOptionsFilterInputComponent extends OptionsFilterInput {
  private oldValue: any;
  private inputFilters: FilterSelection[];

  constructor(utilsService: UtilsService) {
    super(utilsService);
    this.oldValue = '';
    this.inputFilters = [];
  }

  public onSelect(event: MatAutocompleteSelectedEvent, input: HTMLInputElement) {
    if (event && event.option) {
      const filter: FilterSelection = this.currentFilters.find((f) => f.cmpQueryfilters(event.option.value));
      filter.selected = !filter.selected;
    }

    // clear the text box so it doesn't trigger the onBlur event
    if (input && !!input.value) {
      input.value = '';
    }

    this.emitIfChanged();
  }

  public remove(filter: FilterSelection) {
    this.oldValue = '';
    const selection: FilterSelection = this.currentFilters.find((f) => f.cmpQueryfilters(filter));
    selection.selected = false;
    const ind = this.inputFilters.findIndex((x) => x.cmpQueryfilters(filter));
    if (ind >= 0) {
      this.inputFilters.splice(ind, 1);
    }

    this.emitIfChanged();
  }

  /**
   * When the the input is blurred, check to see if there's plain text input.
   * If so, build a "contains" filter for that text.
   */
  onBlur(event: MatChipInputEvent, panelOpen?: MatAutocomplete) {
    if (!!event && !!event.value && this.oldValue !== event.value) {
      const newFilter = new QueryFilterExpression(this.filter.propertyName, Q_OP_CON, event.value, event.value);
      const exists = !!this.inputFilters.filter((f) => f.cmpQueryfilters(newFilter)).length;
      if (!exists) {
        const newWithSelection = Object.assign(newFilter, { selected: true });
        this.currentFilters.push(newWithSelection);
        this.inputFilters.push(newWithSelection);
        this.emitIfChanged();
        this.clearControl();
      }
      event.input.value = '';
      this.oldValue = event.value;
    }
  }
}
