import { Component } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { OptionsFilterInput, FilterSelection } from '../options-filter-input.base';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-button-options-filter-input',
  templateUrl: './button-options-filter-input.component.html',
  styleUrls: ['./button-options-filter-input.component.scss'],
})
export class ButtonOptionsFilterInputComponent extends OptionsFilterInput {
  constructor(utilsService: UtilsService) {
    super(utilsService);
  }

  public buttonToggled(event: MatButtonToggleChange): void {
    if (!!event && !!event.value) {
      this.currentFilters.forEach((x) => (x.selected = false));
      if (!!event.value.length) {
        const value: FilterSelection[] = event.value;
        for (const filter of value) {
          filter.selected = true;
        }
      }
      this.emitIfChanged();
    }
  }
}
