import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { IAppModule } from '../interfaces/models';
import { AuthenticationService } from '../services';
import { Module, ModuleType } from 'src/app/shared/enums';
import { AppModuleTenantAssignmentsService } from '../services/app-module-tenant-assignments.service';
import { IModule } from '../interfaces/definitions/module-route.definition';
import { signsRoutes } from 'src/app/shared/descriptors/signs-routes.descriptor';

@Injectable({
  providedIn: 'root',
})
export class ModuleRouteResolver implements Resolve<IModule> {
  constructor(
    private _appModuleService: AppModuleTenantAssignmentsService,
    private _authService: AuthenticationService
  ) {}

  /**
   * Resolves the current route's module and return a descriptor of the available routes
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IModule> {
    return this._authService.currentUser().pipe(
      switchMap((user) => {
        if (!!user) {
          const userRoles = user.userRoles.map((x) => x.role.name);
          return this.resolveCurrentModule(state).pipe(map((res) => this.filterRoutes(userRoles, res)));
        } else {
          return of(null);
        }
      })
    );
  }

  /**
   * Determine the module we are visiting based on the url we are navigating to.
   * @param state The current router state
   */
  private resolveCurrentModule(state: RouterStateSnapshot): Observable<IModule> {
    const segments = state.url.split('/');
    const moduleName = segments.length > 1 ? segments[1] : '';
    switch (Module.moduleTypeFromString(moduleName)) {
      case ModuleType.signs:
        return of(signsRoutes);
      default:
        // we are on the dashboard, see what modules we have assigned to us and transform them into IModules
        return this._appModuleService.tenantAppModules.pipe(
          map((res: IAppModule[]) => {
            return {
              name: '',
              url: null,
              icon: null,
              subRoutes: res.map((x) => ({
                url: x.webRoute,
                icon: x.iconUrl,
                name: x.name,
              })),
            } as IModule;
          }),
          catchError((err) => of(null))
        );
    }
  }

  /**
   * Go through a module's sub routes and replace the permission property with true
   * if the current user has permission to view the route and false otherwise.
   * @param userRoles The current user's assigned roles
   * @param route The module we are navigating
   */
  private filterRoutes(userRoles: string[], route: IModule): IModule {
    if (route) {
      const routeCopy: IModule = {
        name: route.name,
        url: route.url,
        icon: route.icon,
        matIcon: route.matIcon,
        perm:
          typeof route.perm !== 'boolean'
            ? !route.perm || !!route.perm.find((role) => userRoles.includes(role))
            : route.perm,
        subRoutes: route.subRoutes ? route.subRoutes.map((subRoute) => this.filterRoutes(userRoles, subRoute)) : null,
      };
      return routeCopy;
    }
  }
}
