import { Directive, Renderer2, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appVirtualScroll]',
})
export class VirtualScrollDirective implements OnChanges {
  @Input() items: any[];
  @Input() itemHeight = 50; // Default to height of mat-option row
  private _domElement: any;

  constructor(elementRef: ElementRef, private _renderer: Renderer2) {
    this._domElement = elementRef.nativeElement;
  }

  ngOnChanges(): void {
    this.setScrollHeight();
  }

  public setScrollHeight(): void {
    if (this._domElement) {
      const n = this.items ? this.items.length : 0;
      const height = Math.max(n * this.itemHeight, this.itemHeight);
      this._renderer.setStyle(this._domElement, 'height', height + 'px');
    }
  }
}
