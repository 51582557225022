export enum SignsComponentType {
  invalid = -1,
  request = 0,
  maintenance = 1,
  installedSign = 2,
  sentinel = 3,
}

export const requestRoute = 'work-order-request';
export const maintenanceRoute = 'work-order-maintenance';
export const installedSignRoute = 'installed-traffic-sign';

export class SignsModule {
  static isValid(componentId: number): boolean {
    return componentId >= 0 && componentId <= SignsComponentType.sentinel;
  }

  static componentTypeToString(component: SignsComponentType): string {
    switch (component) {
      case SignsComponentType.request:
        return 'Work Order Request';
      case SignsComponentType.maintenance:
        return 'Work Order Maintenance';
      case SignsComponentType.installedSign:
        return 'Installed Traffic Signs';
      default:
        return '';
    }
  }

  // Static method to get the route for the module type.
  static componentTypeRoute(component: SignsComponentType): string {
    switch (component) {
      case SignsComponentType.request:
        return requestRoute;
      case SignsComponentType.maintenance:
        return maintenanceRoute;
      case SignsComponentType.installedSign:
        return installedSignRoute;
      default:
        return '';
    }
  }

  static componentTypeFromRoute(route: string): SignsComponentType {
    switch (route) {
      case requestRoute:
        return SignsComponentType.request;
      case maintenanceRoute:
        return SignsComponentType.maintenance;
      case installedSignRoute:
        return SignsComponentType.installedSign;
      default:
        return null;
    }
  }

  static componentTypeIcon(component: SignsComponentType): string {
    switch (component) {
      case SignsComponentType.request:
        return `assets/images/icons/work-order-requests.svg`;
      case SignsComponentType.maintenance:
        return `assets/images/icons/work-order-maintenance.svg`;
      case SignsComponentType.installedSign:
        return `assets/images/icons/installed-traffic-sign.svg`;
      default:
        return '';
    }
  }
}
