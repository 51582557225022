import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { UtilsService } from 'src/app/core/services/utils.service';
import { FilterInput } from '../filter-input';
import { QueryFilterExpression } from 'src/app/core/interfaces/query/filters/query-filter';
import { FormControl } from '@angular/forms';
import { Q_OP_EQ, Q_OP_CON, Q_OP_SW } from 'src/app/core/constants/query.constants';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-string-filter-input',
  templateUrl: './string-filter-input.component.html',
  styleUrls: ['./string-filter-input.component.css'],
})
export class StringFilterInputComponent extends FilterInput implements OnInit {
  @ViewChild('stringInput') stringInput: ElementRef;
  private currentFilter: QueryFilterExpression;
  private oldValue: any;
  private displayOp: string;

  constructor(_utilsService: UtilsService) {
    super(_utilsService);
    this.oldValue = '';
    this.control = new FormControl(this.oldValue, { updateOn: 'blur' });
  }

  ngOnInit() {
    this.filter.filter.pipe(takeUntil(this._destroy$)).subscribe((expr: QueryFilterExpression) => {
      this.control.setValue(expr ? expr.value : expr, { emitEvent: false });
    });

    // Map empty string to null and update the current filter based on the new filter string value.
    this.control.valueChanges
      .pipe(
        map((s: string) => (s === '' ? null : s)),
        takeUntil(this._destroy$)
      )
      .subscribe((filterValue: string) => this.filterStringUpdated(filterValue));

    const propId = this.filter.propertyName;
    if (propId.toLowerCase() === 'id') {
      this.currentFilter = new QueryFilterExpression(propId, Q_OP_SW, null);
      this.displayOp = 'Starts With';
    } else {
      this.currentFilter = new QueryFilterExpression(propId, Q_OP_CON, null);
      this.displayOp = 'Contains';
    }
  }

  /**
   * Use the element reference to blur the control, avoids having
   * the control re-trigger after hitting enter and clicking off it
   */
  public blurControl(): void {
    this.stringInput.nativeElement.blur();
  }

  public filterStringUpdated(value: string): void {
    if (value !== this.oldValue) {
      if (this._utilsService.isEmpty(value)) {
        this.clearFilter();
      } else {
        this.currentFilter.value = value;
        this.filter.filter.next(this.currentFilter);
        this.currentFilter.displayName = `${this.displayOp} "${value}"`;
      }
      this.oldValue = value;
    }
  }
}
