import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { IBaseDialogData } from './base-dialog.data';
import { BaseFormBuilder } from 'src/app/shared/components/forms/builder/base-form-builder';
import { IFormGroup } from 'src/app/shared/components/forms/builder/form-builder.definition';
import { ISubmitAction } from 'src/app/shared/components/forms/base-form/base-form.component';
import { take } from 'rxjs/operators';

@Component({
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent<T> implements OnInit {
  builder: BaseFormBuilder;
  fg: IFormGroup;
  submitActions: ISubmitAction[];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IBaseDialogData,
    private _confirmDialog: MatDialog,
    public dialogRef: MatDialogRef<T>
  ) {
    this.builder = data.formBuilder;
    this.builder.build();
    this.fg = this.builder.getFormGroups()[0];
    this.submitActions = [
      { name: 'CANCEL', value: false, color: 'accent' },
      { name: 'SAVE', value: true, color: 'primary' },
    ];
  }

  ngOnInit(): void {}

  submitForm(action: ISubmitAction): void {
    if (action.value) {
      if (this.builder.isValid()) {
        this.builder
          .create()
          .pipe(take(1))
          .subscribe((res: T) => this.dialogRef.close(res));
      } else {
        this._confirmDialog.open(ConfirmDialogComponent, {
          data: {
            message: 'Missing required fields or invalid input.',
          },
        });
      }
    } else {
      this.onCancel();
    }
  }

  onNoClick(): void {
    this.onCancel();
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
