import { Component, Input, ViewChild, OnDestroy } from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseFormControl } from '../../base-form-control';

@Component({
  selector: 'app-choices-input-control',
  templateUrl: './choices-input-control.component.html',
  styleUrls: ['./choices-input-control.component.scss'],
})
export class ChoicesInputControlComponent implements OnDestroy {
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) autocompleteTrigger: MatAutocompleteTrigger;
  @Input() addOnBlur: boolean;
  @Input()
  set control(value: BaseFormControl) {
    this.ctrl = value;
    if (this.ctrl.multiple) {
      this.chips = this.ctrl.form.value;
    }

    this.ctrl.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((val: any) => {
      if (val instanceof Array) {
        this.chips = val;
      }
    });

    if (this.ctrl.filterChoices) {
      this.ctrl.filterChoices
        .pipe(takeUntil(this._destroy$))
        .subscribe((choices: any[]) => (this.numChoices = choices.length));
    }
  }
  public separatorKeyCodes: number[];
  public ctrl: BaseFormControl;
  public chips: any[];
  public numChoices: number;
  private _destroy$ = new Subject();

  constructor() {
    this.chips = [];
    this.numChoices = 0;
    this.separatorKeyCodes = [ENTER];
    this.addOnBlur = true;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Returns the index of the value if it exists in the current values list, else -1.
   * @param value : the value to return the index for
   */
  private _indexOf(value: { id: any }): number {
    return value ? this.chips.findIndex((x: { id: any }) => x.id === value.id) : -1;
  }

  /**
   * Update the current list of values: add the value if it is not already in the current
   * list of values and the add flag has been set, else if the value exists already and/or
   * the add flag is not set, remove the value from the current list of values.
   * @param value : the value to add or remove
   * @param add : true if value 'trying' to be added
   */
  private _updateValues(value: { id: any }, add: boolean) {
    if (this.ctrl.multiple) {
      const index = this._indexOf(value);
      if (add && index < 0) {
        this.chips.push(value);
        this.ctrl.form.setValue(this.chips);
      } else if (index >= 0) {
        this.chips.splice(index, 1);
        this.ctrl.form.setValue(this.chips);
      }
    } else {
      this.ctrl.form.setValue(value);
    }
  }

  /**
   * Intercept and stop the input element click event, and open the autocmplete panel.
   * @param event : the mouse click event to intercept.
   */
  public autocompleteInputClicked(event: MouseEvent): void {
    if (this.ctrl.multiple) {
      event.stopPropagation();
      this.autocompleteTrigger.openPanel();
    }
  }

  /**
   * Intercept and stop the autocmplete option click event, and keep [open] the autocmplete panel.
   * @param event : the mouse click event to intercept.
   */
  public optionClicked(event: MouseEvent): void {
    if (this.ctrl.multiple) {
      event.stopPropagation();
      this.autocompleteTrigger.openPanel();
    }
  }

  /**
   * Return true if the value is currently in the list of selected values.
   * @param value : the value to check
   */
  public isSelected(value: { id: any }): boolean {
    return this._indexOf(value) >= 0;
  }

  /**
   * Remove the value from the current list of values.
   * @param value : the value to remove
   */
  public remove(value: { id: any }) {
    this._updateValues(value, false);
  }

  /**
   * Add the mat option value to the current list of values.
   * @param event : the mat option select event, containing the selected option.
   */
  public choiceSelected(event: MatAutocompleteSelectedEvent) {
    if (!!event && !!event.option.value) {
      this._updateValues(event.option.value, true);
    }
  }

  /**
   * Ignore the event triggered to add a chip (adding will be handled via the choiceSelected handler), and
   * clear the input value.
   * @param event : the mat chip input event to ignore
   */
  public ignoreMatChipEvent(event: MatChipInputEvent) {
    // Disable adding chip via MatChipInputEvent - chips will be added by selecting from autocomplete.
    if (!!event && event.input) {
      event.input.value = '';
    }
  }
}
