import {
  Directive,
  Output,
  ElementRef,
  EventEmitter,
  HostListener,
  Renderer2,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
} from '@angular/core';
import { AnimationMetadata, animate, style, keyframes, AnimationBuilder } from '@angular/animations';
import { RefreshWrapperComponent } from '../components/widgets/refresh-wrapper/refresh-wrapper.component';
@Directive({
  selector: '[appPullToRefresh]',
})
export class PullToRefreshDirective {
  @Output() refresh: EventEmitter<void>;
  private _domElement: any;
  private _lastScroll: number;
  private _startTouchY: number;
  private _currentTouchY: number;
  private _top: boolean;
  private shouldRefresh: boolean;
  private _componentRef: ComponentRef<RefreshWrapperComponent>;

  constructor(
    private _renderer: Renderer2,
    private builder: AnimationBuilder,
    private _viewContainer: ViewContainerRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    _elementRef: ElementRef
  ) {
    this._domElement = _elementRef.nativeElement;
    this.refresh = new EventEmitter();
    this._startTouchY = -1;
    this._currentTouchY = 0;
  }

  /**
   * Calculate if we are at the scroll top of the current element and calculate the delta position between the touch
   * start and the current touch. If this is above a certain threshold then indicate we should emit a refresh event.
   * @param event The emitted touch event.
   */
  @HostListener('touchmove', ['$event'])
  onTouch(event: Event): void {
    if (window.TouchEvent && event instanceof TouchEvent) {
      this._top = this._domElement.scrollTop <= 0 && this._lastScroll <= 0;
      if (this._top) {
        const lastTouch: Touch = event.changedTouches[0];
        this._currentTouchY = lastTouch.clientY;
        const offset = this._currentTouchY - this._startTouchY;
        // don't emit now, allow to release it without refreshing.
        this.shouldRefresh = offset > 75;
        // if we're between the initial element top and the threshold, play the pull down animation
        if (offset > 0 && !this.shouldRefresh) {
          this._renderer.setStyle(this._componentRef.location.nativeElement, 'top', `calc(${offset}px / 4)`);
          this._componentRef.instance.value = (offset / 75) * 100;
          this._componentRef.changeDetectorRef.detectChanges();
          const factory = this.builder.build(this.pullDown(offset));
          factory.create(this._domElement).play();
        }
      }
      this._lastScroll = this._domElement.scrollTop;
    }
  }

  /**
   * When a user initiates a touch, store the initial position.
   * @param event The emitted touch event.
   */
  @HostListener('touchstart', ['$event'])
  onTouchStart(event: Event): void {
    if (window.TouchEvent && event instanceof TouchEvent) {
      this._startTouchY = event.changedTouches[0].clientY;
      // create the loading bar, it gets inserted after the element this directive is on so style it to be above it
      this.createLoadingBar();
      this._renderer.setStyle(this._componentRef.location.nativeElement, 'position', 'absolute');
      this._renderer.setStyle(this._componentRef.location.nativeElement, 'top', '0');
      // center progress bar
      // todo: be less hardcoded, use width of componentRef directly? (tried but it was always '')
      this._renderer.setStyle(this._componentRef.location.nativeElement, 'left', 'calc((100vw / 2) - (15px / 2))');
    }
  }

  /**
   * When a user releases their touch, check if we should refresh and play the release animation.
   */
  @HostListener('touchend')
  onTouchEnd(): void {
    if (this._top && this.shouldRefresh) {
      this.refresh.emit();
    }
    const factory = this.builder.build(this.release());
    factory.create(this._domElement).play();
    this._viewContainer.remove(0);
  }

  pullDown(offset: number): AnimationMetadata[] {
    return [animate('500ms 0ms cubic-bezier(0, 1, 0.5, 1)', keyframes([style({ 'margin-top': `${offset}px` })]))];
  }

  release(): AnimationMetadata[] {
    return [animate('500ms 0ms cubic-bezier(0, 1, 0.5, 1)', keyframes([style({ 'margin-top': 0 })]))];
  }

  private createLoadingBar(): void {
    const factoryResolver = this._componentFactoryResolver.resolveComponentFactory(RefreshWrapperComponent);
    this._componentRef = this._viewContainer.createComponent(factoryResolver, 0);
    this._componentRef.instance.value = 0;
  }
}
