import { Injectable, OnDestroy } from '@angular/core';
import { Observable, from, of, Subject, BehaviorSubject, fromEvent, Subscriber } from 'rxjs';
import { take, map, switchMap, takeUntil, debounceTime, tap } from 'rxjs/operators';
import { EsriModuleProvider } from './../providers/esri-module.provider';
import { AssetService } from './asset.service';
import { DateTimeService } from './datetime.service';
import { UtilsService } from './utils.service';
import { IInstalledTrafficSign } from '../interfaces/models';
import { IMapInfo } from '../interfaces/definitions/esri-map-info.defintion';
import { popupTemplate as installedSignPopupTemplate } from 'src/app/core/esri/templates/installed-sign-popup.template';
import * as esri from '../esri/constants/constants';
import * as esriModules from '../esri/constants/module-names.constants';

/** Interface to define all the necessary info for a feature layer highlighting. */
interface IFeatureHighlightInfo {
  objectId: string;
  layerView: __esri.FeatureLayerView | __esri.GraphicsLayerView;
}

/** Interface defines handles and object id for a highlighted feature. */
interface IFeatureHighlight {
  handle: __esri.Handle;
  objectId: any;
  layerId: string;
}

@Injectable({
  providedIn: 'root',
})
export class EsriMapService implements OnDestroy {
  // Main esri objects
  private _map: __esri.Map;
  private _mapView: __esri.MapView;
  // 'Singleton' widgets
  private _basemapGallery: __esri.BasemapGallery;
  private _layerList: __esri.LayerList;
  private _searchWidget: __esri.widgetsSearch;
  private _sketchWidget: __esri.Sketch;
  private _sketchLayer: __esri.GraphicsLayer;
  private _featureWidget: __esri.Feature;
  // Keep list of handles to destroy when the service is destroyed.
  private _handles: IHandle[];
  // Keep track of objects that are loading to avoid loading duplicates.
  private _loading = {};
  private _uiItems = {};
  // Subjects to forward Map View events
  ready$: BehaviorSubject<boolean>;
  suspended$: BehaviorSubject<boolean>;
  loading$: BehaviorSubject<boolean>;
  click$: Observable<MouseEvent>;
  move$: Observable<MouseEvent>;
  private _destroyEvents$: Subject<void>;
  // Map of layer id to highlight info -- used to perform feature layer selection/higlighting
  _layerViewHighlightInfo: { [layerId: string]: IFeatureHighlightInfo };
  private _highlight: IFeatureHighlight;
  private _selection: IFeatureHighlight;

  constructor(
    private _esriModule: EsriModuleProvider,
    private _assetService: AssetService,
    private _datetimeService: DateTimeService
  ) {
    this._mapView = null;
    this._mapView = null;
    this._featureWidget = null;
    this._basemapGallery = null;
    this._layerList = null;
    this._searchWidget = null;
    this._sketchWidget = null;
    this._handles = [];
    this._layerViewHighlightInfo = {};
    this._clearHighlight();
    this._clearSelection();
    this._destroyEvents$ = new Subject();
  }

  ngOnDestroy(): void {
    this._handles.forEach((handle: IHandle) => handle.remove());
    if (this._mapView) {
      this._mapView.container = null;
    }
  }

  /**
   * Check whether or not the Map and MapView objects have been initialized, before
   * attempting to use them.
   * @param verbose : Send error message to console.
   */
  initialized(verbose: boolean = true): boolean {
    const init = this._map !== null && this._mapView !== null;

    if (verbose && !init) {
      console.error('Map and/or MapView not initialized');
    }

    return init;
  }

  /**
   * Reset to a 'clean' state; any local map objects are cleared or closed
   * (e.g. popups, expanded widgets, feature layer graphics, etc.) and
   * MOST IMPORTANTLY the MapView.container is set to null, and ready to be re-set
   * for the next component to display the map.
   *
   * **NOTE**: this should be called when the component that displays
   * the map/view in their template, is destroyed (e.g. MapComponent.ngOnDestroy).
   */
  reset(): void {
    // Clear any feature selection or highlighting -- for some reason this need to happen first
    // or else we get a feature layer connection lost error.
    this._clearSelection();
    this._clearHighlight();

    // Clear the search bar
    if (this._searchWidget) {
      this._searchWidget.clear();
    }

    // Close any widgets left expanded
    Object.keys(this._uiItems).forEach((id: string) => {
      const widget = this._uiItems[id];
      if (widget.hasOwnProperty('expanded')) {
        widget.expanded = false;
      }
    });

    // Remove any layers from the map -- TODO: we could be smarter about this and only
    // remove layers when we need to to avoid reloading.
    this._map.removeAll();
    this._layerViewHighlightInfo = {};

    if (this._mapView) {
      this._mapView.popup.close();

      // Must set the container HTMLDivEleemnt to null, or else MapView on reload
      // will lose all mouse listener events
      this._mapView.container = null;
    }
  }

  /**
   * Set the map view container to the given HTML div element. This is used when reloading the
   * map, and need to set the view to a new DOM element. Reloads any LayerViews that existed
   * before we updated the view's container.
   * @param container : an HTML div element
   */
  async setMapViewContainer(container: HTMLDivElement): Promise<void> {
    if (this.initialized()) {
      this._mapView.container = container;

      // Event handling must be set up again since the container changed.
      this._setupMouseEventHandling();

      // Reset the LayerView objects -- ArcGIS will close the connection on the LayerViews
      // when the view clears or changes its containier DOM element (e.g. when component
      // holding the map/view is destroyed).
      for (const fl of Object.keys(this._layerViewHighlightInfo)) {
        const layer = this._map.findLayerById(fl);
        if (layer) {
          const layerView = (await this._mapView.whenLayerView(layer)) as
            | __esri.FeatureLayerView
            | __esri.GraphicsLayerView;
          this._layerViewHighlightInfo[fl].layerView = layerView;
        }
      }
    }
  }

  /**
   * Set the map view properties from the given properties object. This is used when reloading the
   * map, and properties need to be reset. Supports only setting the center and zoom properties
   * for now.
   * @param props : the map view properties
   */
  async setViewProperties(props: __esri.MapViewProperties): Promise<void> {
    if (props.center) {
      this._mapView.center = await this.getPoint(props.center[0], props.center[1]);
    }

    this._mapView.zoom = props.zoom ? props.zoom : esri.DEFAULT_ZOOM;
  }

  /**
   * Load the custom map, given the properties for the map and view, into the HTML element mapEl.
   * This loads specifically a [2D] MapView, if a [3D] SceneView is required, this
   * function needs to be modified to accept a `view type` parameter to replace `MapView`.
   * @param mapProps : Properties to build the map.
   * @param mapViewProps : Properties to build the map view.
   * @param mapEl : The HTML element containing the map.
   */
  async loadMap(
    mapProps: __esri.MapProperties,
    mapViewProps: __esri.MapViewProperties,
    mapEl: HTMLDivElement
  ): Promise<IMapInfo> {
    if (!this.initialized(false)) {
      const [Map, MapView] = await this._esriModule.require([esriModules.MOD_MAP, esriModules.MOD_VIEW_MAPVIEW]);

      // Create the Map and MapView objects (only once)
      this._map = new Map(mapProps);
      const newViewProps = this._prepareViewProps(mapViewProps, this._map, mapEl);
      this._mapView = new MapView(newViewProps);

      // Setup event handling for watching specific view properties and events by forwarding these changes
      // via observables (subjects/subscriptions). Keep track of the handles to remove once the service
      // is destroyed.
      this.ready$ = new BehaviorSubject(this._mapView.ready);
      this.suspended$ = new BehaviorSubject(this._mapView.suspended);
      this.loading$ = new BehaviorSubject(this._mapView.updating);
      this._handles.push(this._mapView.watch('ready', (val: boolean) => this.ready$.next(val)));
      this._handles.push(this._mapView.watch('suspended', (val: boolean) => this.suspended$.next(val)));
      this._handles.push(this._mapView.watch('updating', (val: boolean) => this.loading$.next(val)));
      this._setupMouseEventHandling();
    }

    return {
      map: this._map,
      view: this._mapView,
    };
  }

  /**
   * Load an existing WebMap, given the map and view properties, into the HTML element mapEl.
   * @param webMapProps : properties to buld the WebMap.
   * @param viewProps : properties to build the view.
   * @param mapEl : the HTML element to load the WebMap into.
   * @param viewType : the type of view (MapView, SceneView)
   */
  async loadWebMap(
    webMapProps: __esri.WebMapProperties,
    viewProps: __esri.ViewProperties,
    mapEl: HTMLDivElement,
    viewType: string = esri.VIEW_TYPE_MAPVIEW
  ): Promise<IMapInfo> {
    if (this.initialized(false)) {
      this._mapView.container = mapEl;
    } else {
      const [WebMap, View] = await this._esriModule.require([
        esriModules.MOD_WEBMAP,
        `${esriModules.MOD_VIEWS}/${viewType}`,
      ]);
      this._map = new WebMap(webMapProps);
      const newViewProps = this._prepareViewProps(viewProps, this._map, mapEl.id);
      this._mapView = new View(newViewProps);
    }

    return {
      map: this._map,
      view: this._mapView,
    };
  }

  /**
   * Load the BasemapGallery widget and add it to the MapView ui.
   * A single BaseMmapGallery widget will be created in the lifetime of this service for the map for reuse.
   */
  async loadBasemapGalleryWidget(): Promise<void> {
    if (this.initialized() && !this._basemapGallery) {
      const [BasemapGallery] = await this._esriModule.require([esriModules.MOD_WID_BASEMAPGAL]);
      this._basemapGallery = new BasemapGallery({ id: esri.WID_BASEMAPGAL_ID, view: this._mapView });
      this.addContent(this._basemapGallery, esri.POSITION_TOP_LEFT, true, 'Basemap Options');
    }
  }

  /**
   * Load the LayerList widget and add it to the MapView ui.
   * A single LayerList widget will be created in the lifetime of this service for the map for reuse.
   */
  async loadLayerListWidget(): Promise<void> {
    if (this.initialized() && !this._layerList) {
      const [LayerList] = await this._esriModule.require([esriModules.MOD_WID_LAYERLIST]);
      this._layerList = new LayerList({ id: esri.WID_LAYERLIST_ID, view: this._mapView });
      this.addContent(this._layerList, esri.POSITION_TOP_LEFT, true, 'Layers Toggle');
    }
  }

  /**
   * Load a Feature widget for feature highlight and select, and add it to the MapView ui.
   * A single Feature widget will be created in the lifetime of this service for the map for reuse; if
   * additional feature widgets are needed, they can be added as custom widgets (e.g. screenshot widget).
   */
  async loadFeatureWidget(): Promise<void> {
    if (this.initialized() && !this._featureWidget) {
      const [Feature] = await this._esriModule.require([esriModules.MOD_WID_FEATURE]);
      this._featureWidget = Feature({ id: esri.WID_FEAT_HL_ID, spatialReference: this._mapView.spatialReference });
      this._featureWidget.graphic = null;
      this.addContent(this._featureWidget, esri.POSITION_BOTTOM_RIGHT);
    }
  }

  /**
   * Load the Search widget and add it to the MapView ui.
   * A single Search widget will be created in the lifetime of this service for the map for reuse.
   */
  async loadSearchWidget(position: string = esri.POSITION_TOP_RIGHT, expandable: boolean = false): Promise<void> {
    if (this.initialized() && !this._searchWidget) {
      const [Search] = await this._esriModule.require([esriModules.MOD_WID_SEARCH]);
      this._searchWidget = new Search({ id: esri.WID_SEARCH_ID, view: this._mapView });
      this.addContent(this._searchWidget, position, expandable);
    }
  }

  /**
   * Load the Sketch widget and its resepective layer, add the layer to the map and the widget to the MapView ui.
   * A single Sketch widget will be created in the lifetime of this service for the map for reuse.
   */
  async loadSketchWidget(
    position: string = esri.POSITION_TOP_LEFT,
    expandable: boolean = true,
    title: string = 'Sketch',
    tooltip: string = 'Sketch Tools',
    fullExtent: __esri.Extent = null
  ): Promise<void> {
    if (this.initialized() && (!this._searchWidget || !this._sketchLayer)) {
      const [GraphicsLayer, Sketch] = await this._esriModule.require([
        esriModules.MOD_LAY_GRAPHIC,
        esriModules.MOD_WID_SKETCH,
      ]);

      if (!this._sketchLayer) {
        this._sketchLayer = new GraphicsLayer({ title, fullExtent });
      }

      if (!this._sketchWidget) {
        this._sketchWidget = new Sketch({ id: esri.WID_SKETCH_ID, view: this._mapView, layer: this._sketchLayer });
      }

      this._map.add(this._sketchLayer);
      this.addContent(this._sketchWidget, position, expandable, tooltip);
    } else {
      // Probably could handle this more gracefully...

      if (!this._map.findLayerById(this._sketchLayer.id)) {
        this._map.add(this._sketchLayer);
      }

      if (!this._mapView.ui.find(`${this._sketchWidget.id}-expand`)) {
        this.addContent(this._sketchWidget, position, expandable, tooltip);
      }
    }
  }

  setLayerVisible(layerId: string, visible: boolean): void {
    if (this.initialized()) {
      const layer = this._map.findLayerById(layerId);
      if (layer) {
        layer.visible = visible;
        if (!visible) {
          if (
            (this._selection && this._selection.layerId === layer.id) ||
            (this._highlight && this._highlight.layerId === layer.id)
          ) {
            this._clearFeature();
          }
        }
      }
    }
  }

  /**
   * Load the GraphicsLayer specifically for Installed Traffic Signs.
   * @param layerId : the installed trffic signs layer id.
   * @param data : the installed traffic signs list.
   * @param visible : whether or not the layer is visible.
   * @param highlight : wherher or not the graphics should be highlightable.
   */
  async loadInstalledSignsGraphicsLayer(
    layerId: string,
    data: IInstalledTrafficSign[],
    visible: boolean = true
  ): Promise<__esri.GraphicsLayer> {
    if (this.initialized() && !this._loading[layerId]) {
      this._loading[layerId] = true;
      const existingLayer = this._map.findLayerById(layerId) as __esri.GraphicsLayer;
      const layer: __esri.GraphicsLayer = await this.updateInstalledSignsGraphicsLayer(data, existingLayer);
      layer.visible = visible;
      if (!existingLayer) {
        this._map.add(layer);
      }

      this._loading[layerId] = false;
      return layer;
    }
  }

  /**
   * Load the feature layer associated with the given url and assign it the given id. Set the popup tempalte
   * if provided, and set the initial visibility of the layer.
   * @param layerUrl : the feature layer url
   * @param layerId : the feature layer id
   * @param popupTemplate : the feature layer popup template to use
   * @param visible : the feature layer visibility to set initially
   */
  async loadFeatureLayer(
    layerUrl: string,
    layerId: string,
    popupTemplate: any = null,
    visible: boolean = true
  ): Promise<__esri.FeatureLayer> {
    if (this.initialized() && !this._loading[layerId]) {
      this._loading[layerId] = true;

      let featureLayer: __esri.FeatureLayer = this._map.findLayerById(layerId) as __esri.FeatureLayer;
      const exists = !!featureLayer;
      if (!exists) {
        featureLayer = await this.getFeatureLayer(layerUrl, layerId);
      }

      featureLayer.visible = visible;

      if (popupTemplate) {
        featureLayer.popupTemplate = popupTemplate;
      }

      if (!exists) {
        this._map.add(featureLayer);
      }

      this._loading[layerId] = false;
      return featureLayer;
    }
  }

  /**
   * Add an entry in the layerViewHighlightInfo to apply any feature selection/highlighting for the layer's LayerView.
   * @param layer : the LayerView's layer
   * @param objectId : the graphic attribute name that represents the graphic's unique identifier -- used to
   * select/highlight graphics.
   */
  addLayerViewHighlight(
    layer: __esri.GraphicsLayer | __esri.FeatureLayer,
    objectId: string
  ): Observable<__esri.FeatureLayerView | __esri.GraphicsLayerView> {
    if (!this.initialized() || !!this._layerViewHighlightInfo[layer.id]) {
      return of();
    }

    return from(this._mapView.whenLayerView(layer)).pipe(
      tap((layerView: __esri.FeatureLayerView | __esri.GraphicsLayerView) => {
        if (!objectId) {
          objectId = (layer as __esri.FeatureLayer).objectIdField;
        }

        this._layerViewHighlightInfo[layer.id] = { objectId, layerView };
      })
    );
  }

  async updateInstalledSignsGraphicsLayer(
    signs: IInstalledTrafficSign[],
    layer: __esri.GraphicsLayer = null,
    fullExtent: __esri.Extent = null
  ): Promise<__esri.GraphicsLayer> {
    const [Graphic, Point, GraphicsLayer] = await this._esriModule.require([
      esriModules.MOD_GRAPHIC,
      esriModules.MOD_GEO_POINT,
      esriModules.MOD_LAY_GRAPHIC,
    ]);

    const graphics: __esri.Graphic[] = [];

    if (signs) {
      const symbol = {
        type: esri.SYMBOL_TYPE_SIMPLE,
        color: 'red',
        size: '10px', // This would be nice to be user configurable
      };

      for (const sign of signs) {
        if (!sign) {
          continue; // skip invalid sign
        }

        const graphic = new Graphic({
          symbol,
          geometry: new Point({
            type: esri.GEOMETRY_TYPE_POINT,
            longitude: sign.location.longitude,
            latitude: sign.location.latitude,
          }),
          attributes: {
            id: sign.id,
            name: `No. ${sign.id} ${sign.trafficSignType.displayName}`,
            barcode: sign.trafficSign ? `${sign.trafficSign.barcode}` : 'None',
            owner: `${sign.owner.name}`,
            installDate: this._datetimeService.convertUtcToLocal(sign.installDateTime),
            assetLocation: UtilsService.displayData(sign.location),
            url: 'assets/images/no-image.png',
          },
          popupTemplate: installedSignPopupTemplate,
        });
        graphic.attributes[esri.INSTALLED_SIGNS_OID] = sign.id;
        graphics.push(graphic);

        // Would be nice to display sign icon on map intead of simple dot; however, the
        // aspect ratio is not preserved so we would either need to figure that out or
        // get sign icons specifcally for displaying on the map.
        // Uncomment the lines below to re-enable.
        if (sign.trafficSignType && sign.trafficSignType.imageUri && sign.trafficSignType.imageUri.length > 0) {
          const result = this._assetService.getAsset(sign.trafficSignType.imageUri);
          // graphic.symbol = {
          //   type: SYMBOL_TYPE_PICTURE
          // };
          if (typeof result === 'string') {
            // graphic.symbol.url = result;
            graphic.attributes.url = result;
          } else {
            const observable = result as Observable<string | ArrayBuffer>;
            // foes this unsubscribe correctly??
            //
            observable.pipe(take(1)).subscribe((image) => {
              // graphic.symbol.url = image;
              graphic.attributes.url = image;
            });
          }
        }
      }
    }

    // fixme
    if (layer) {
      layer.removeAll();
      layer.addMany(graphics);
      return layer;
    } else {
      return new GraphicsLayer({
        id: esri.INSTALLED_SIGNS_LAYER_ID,
        geometryType: esri.GEOMETRY_TYPE_POINT,
        title: 'Installed Traffic Signs',
        fullExtent,
        graphics,
      });
    }
  }

  async addContent(
    content: __esri.Widget | HTMLElement,
    position: string,
    expandable: boolean = false,
    expandTooltip: string = null,
    expandIconClass: string = ''
  ) {
    if (!this.initialized()) {
      return;
    }

    if (expandable) {
      const [Expand] = await this._esriModule.require([esriModules.MOD_WID_EXPAND]);
      const expandWidget = new Expand({
        id: `${content.id}-expand`,
        view: this._mapView,
        content,
        expandTooltip,
        expandIconClass,
      });
      this._mapView.ui.add(expandWidget, position);
      this._uiItems[expandWidget.id] = expandWidget;
    } else {
      this._mapView.ui.add(content, position);
      this._uiItems[content.id] = content;
    }
  }

  /**
   * Remove an HTMLElement from the map.
   * @param content : the element to remove from the map ui; can be the id of the
   * DOM element, the DOM element or esri Widget.
   */
  removeContent(content: string | HTMLElement | __esri.Widget): void {
    if (!this.initialized()) {
      return;
    }

    const contentId = typeof content !== 'string' ? content.id : content;
    const found = this._mapView.ui.find(contentId);

    if (found) {
      this._mapView.ui.remove(found);
    }

    if (content.hasOwnProperty('destroy')) {
      (content as __esri.Widget).destroy();
    }

    delete this._uiItems[contentId];
  }

  /**
   * Special handling to remove sketch widget, since we also need to remove the
   * sketch layer for it. TODO: the removeContent should handle this so we do not
   * have to call a one-off function for sketch.
   * @param id : sketch widget id
   */
  removeSketchWidget(id: string): void {
    this.removeContent(id);
    this._sketchLayer.removeAll();
  }

  /**
   * Load and return the esri FeatureLayer at the given url.
   * @param url : the feature layer's url.
   * @param id : the feature layer's id.
   */
  async getFeatureLayer(url: string, id: string = null): Promise<__esri.FeatureLayer> {
    const [FeatureLayer] = await this._esriModule.require([esriModules.MOD_LAY_FEATURE]);
    return new FeatureLayer({ url, id });
  }

  /**
   * Load and return a geometry point.
   * @param longitude : longitude coordinate for the point.
   * @param latitude : latitude coordinate for the ponit.
   */
  async getPoint(longitude: number, latitude: number): Promise<__esri.Point> {
    const [Point] = await this._esriModule.require([esriModules.MOD_GEO_POINT]);
    return new Point({ longitude, latitude });
  }

  /**
   * Recenter the map to the point defined by the given long/lat coordinates and open
   * a popup displaying info about the map point.
   * @param longitude: longitude coordinate of the point
   * @param latitude: latitude coordinate of the point
   * @param template: a template to pop up after recentering the map to the given point.
   */
  async goToPoint(longLat: [number, number], template: any = null) {
    if (!this.initialized()) {
      return;
    }

    // Go to the point defined by long/lat in the MapView
    this._mapView.goTo({ center: longLat });
    // Popup the template, if specified.
    if (template) {
      template.location = await this.getPoint(longLat[0], longLat[1]);
      this._mapView.popup.open(template);
    }
  }

  /**
   * Convert ScreenPoint to a map Point.
   * @param point : the screen point to convert to map point.
   */
  screenToMapPoint(point: __esri.ScreenPoint): __esri.Point {
    return this.initialized() ? this._mapView.toMap(point) : null;
  }

  /**
   * Return a screenshot of the map view.
   */
  takeScreenshot(): Promise<__esri.Screenshot> {
    return this.initialized() ? this._mapView.takeScreenshot() : null;
  }

  /**
   * Programatically highlight an installed traffic sign graphic.
   * @param graphicId : the installed traffic sign to highlight
   * @param layerId : the layer tha the graphic belongs to.
   */
  selectGraphic(graphicId: number, layerId: string, longLat: [number, number] = null, template: any = null): boolean {
    if (!this.initialized()) {
      return false;
    }

    if (this.isSelected(graphicId)) {
      return false; // already selected;
    }

    const layer = this._map.findLayerById(layerId) as __esri.GraphicsLayer;
    if (!layer) {
      return false;
    }

    const graphic = layer.graphics.find((g: __esri.Graphic) => g.attributes.id === graphicId);
    if (!graphic) {
      return false;
    }

    this._featureLayerSelect(graphic);
    if (longLat) {
      this.goToPoint(longLat, template);
    }

    return true;
  }

  /**
   * Run a hit test on the map view for the given mouse event and filter the results by only
   * returning results that the caller should care about (graphics and layers that belong to
   * our defined interfactive layers). This is mainly a convenience method.
   * @param event : the mouse event
   */
  filterMouseEvent(event: MouseEvent): Observable<__esri.HitTestResultResults | __esri.ScreenPoint> {
    return from(this._mapView.hitTest(event)).pipe(
      map((response: __esri.HitTestResult) => {
        const emitOne = response.results.find((result: __esri.HitTestResultResults) => {
          if (
            result.hasOwnProperty('graphic') &&
            result.graphic.hasOwnProperty('layer') &&
            esri.INTERACTIVE_LAYER_IDS.includes(result.graphic.layer.id)
          ) {
            return true;
          }
        });

        if (emitOne) {
          return emitOne;
        } else if (response.results && !!response.results.length) {
          return response.results[0];
        } else if (response.hasOwnProperty('screenPoint')) {
          const screenPointResponse = response as {
            screenPoint: __esri.ScreenPoint;
            results: __esri.HitTestResultResults[];
          };
          return screenPointResponse.screenPoint;
        } else {
          return null;
        }
      })
    );
  }

  /**
   * Run a hit test on the map view for the given mouse event -- this is similar to filterMouseEvent, except
   * that this is more designed for finding feature layer graphics, and used by the feature highlight/selection.
   * @param event : the mouse event
   */
  getGraphicHitTest(event: MouseEvent): Observable<__esri.Graphic> {
    if (!this.initialized() || !event) {
      return of(null);
    }

    return from(this._mapView.hitTest(event)).pipe(
      map((hitTestResult: any) => {
        const results = hitTestResult.results.filter((hitTestResultResults) => {
          // First check for the popup template in the hit result graphic
          if (hitTestResultResults.graphic.popupTemplate) {
            return hitTestResultResults.graphic.popupTemplate;
          }
          // Next, check if we have a FeatureLayer that contains a popup template.
          const fLayer = hitTestResultResults.graphic.layer as __esri.FeatureLayer;
          if (fLayer && fLayer.popupTemplate) {
            return fLayer.popupTemplate;
          }
        });

        return results && !!results.length && results[0] ? results[0].graphic : null;
      })
    );
  }

  searchClickHandler(mapPoint: any): void {
    if (!this._mapView || !this._searchWidget || !mapPoint) {
      return;
    }

    function showPopup(address, pt, view) {
      view.popup.open({
        title: +Math.round(pt.longitude * 100000) / 100000 + ',' + Math.round(pt.latitude * 100000) / 100000,
        content: address,
        location: pt,
      });
    }

    this._searchWidget.clear();
    this._mapView.popup.clear();

    if (this._searchWidget.activeSource) {
      const searchSrc = this._searchWidget.activeSource as __esri.LocatorSearchSource;
      const geocoder = searchSrc.locator;
      geocoder.locationToAddress(mapPoint).then(
        (response) => {
          const address = response.address;
          showPopup(address, mapPoint, this._mapView);
        },
        (err) => {
          showPopup('No address found.', mapPoint, this._mapView);
        }
      );
    }
  }

  /**
   * Convenience method to clear the current feature layer selection.
   */
  private _clearSelection(): void {
    if (this._selection) {
      if (this._selection.handle) {
        this._selection.handle.remove();
      }
      if (this._selection.layerId) {
        this._clearFeature();
      }
    }
    this._selection = { handle: null, objectId: null, layerId: null };
  }

  /**
   * Convenience method to clear the current feature layer highlight.
   */
  private _clearHighlight(): void {
    if (this._highlight) {
      if (this._highlight.handle) {
        this._highlight.handle.remove();
      }
      if (this._highlight.layerId) {
        this._clearFeature();
      }
    }
    this._highlight = { handle: null, objectId: null, layerId: null };
  }

  /**
   * Return true if the object with the given id is currently selected.
   * @param id : object id
   */
  private isSelected(id: any): boolean {
    return !!this._selection.objectId && this._selection.objectId === id;
  }

  /**
   * Return true if the object with the given id is currently highlighted.
   * @param id : object id
   */
  private isHighlighted(id: any): boolean {
    return !!this._highlight.objectId && this._highlight.objectId === id;
  }

  /**
   * Return true if the map view currently has a feature selection.
   */
  private hasSelection(): boolean {
    return !!this._selection && !!this._selection.handle && !!this._selection.objectId && !!this._selection.layerId;
  }

  /**
   * Convenience method to remove the current selection handle.
   */
  private removeSelectionHandle(): void {
    if (this._selection.handle) {
      this._selection.handle.remove();
    }
  }

  /**
   * Convenience method to remove the current highlight handle.
   */
  private removeHighlightHandle(): void {
    if (this._highlight.handle) {
      this._highlight.handle.remove();
    }
  }

  /**
   * Convenience method to clear the layer's current feature widget graphic.
   * @param layerId : the id of the layer that the feature belongs to
   */
  private _clearFeature(): void {
    this._featureWidget.graphic = null;
  }

  /**
   * Select the given graphic by calling its respective's LayerView's highlight method.
   * Selection will clear any highlighting. Selecting a graphic that is currently selected
   * will toggle the selection on and off.
   * A selected graphic will be highlighted in the map view's highlight color and will only
   * be unhighlighted once another graphic is clicked or no graphic is clicked.
   * @param graphic : the graphic to select
   */
  private _featureLayerSelect(graphic: __esri.Graphic): void {
    // Selection will clear any highlighting.
    this._clearHighlight();

    if (!!graphic) {
      const layerId = graphic.layer.id;
      const fh = this._layerViewHighlightInfo[layerId];

      if (fh) {
        const objectId = graphic.attributes[fh.objectId];

        if (!!objectId && !this.isSelected(objectId)) {
          // Remove the current selection handle.
          this.removeSelectionHandle();
          // Clear the feature layer widget, if selection is on a different layer than the last selection.
          if (!!this._selection.layerId && layerId !== this._selection.layerId) {
            this._clearFeature();
          }

          // Update the selection
          this._selection.objectId = objectId;
          this._selection.handle = fh.layerView.highlight(graphic);
          this._selection.layerId = layerId;
          this._featureWidget.graphic = graphic;
        } else {
          // Invalid graphic id, or toggle current selection.
          this._clearSelection();
        }
      }
    } else {
      // Clicked on nothing -- clear the currentfeature.
      this._clearSelection();
    }
  }

  /**
   * Highlight the given graphic by calling its respective's LayerView's highlight method.
   * A highlighted graphic will be colored in the map view's highlight color.
   * @param graphic : the graphic to highlight
   */
  private _featureLayerHighlight(graphic: __esri.Graphic): void {
    if (!!graphic) {
      const layerId = graphic.layer.id;
      const fh = this._layerViewHighlightInfo[layerId];

      if (fh) {
        const objectId = graphic.attributes[fh.objectId];

        if (!objectId) {
          this._clearHighlight();
        } else if (!this.isHighlighted(objectId)) {
          this.removeHighlightHandle();
          if (!!this._highlight.layerId && layerId !== this._highlight.layerId) {
            this._clearFeature();
          }

          this._highlight.objectId = objectId;
          this._highlight.handle = fh.layerView.highlight(graphic);
          this._highlight.layerId = layerId;
          this._featureWidget.graphic = graphic;
        }
      }
    } else {
      this._clearHighlight();
    }
  }

  /**
   * Set up MapView default properties, if not specified.
   * @param properties : The provided properties.
   * @param mapEl : The div element that is the map view's container.
   * @param map : The view's map.
   */
  private _prepareViewProps(
    properties: __esri.MapViewProperties,
    esriMap: __esri.Map,
    container: string | HTMLDivElement
  ): __esri.MapViewProperties {
    const newViewProps = Object.assign({}, properties);

    if (!newViewProps.container) {
      newViewProps.container = container;
    }

    if (!newViewProps.map) {
      newViewProps.map = esriMap;
    }

    if (!newViewProps.zoom) {
      newViewProps.zoom = esri.DEFAULT_ZOOM;
    }

    if (!newViewProps.popup) {
      newViewProps.popup = {
        dockEnabled: false,
        visibleElements: {
          featureNavigation: true,
        },
      };
    }

    return newViewProps;
  }

  /**
   * Set up the mouse click and move event handling for the map view. Use observables instead
   * of the esri event handlers (e.g. mapview.on(event, handler)). Since the events are on
   * the map view contianer, we need to setup these observables each time the map view container
   * is changed.
   */
  private _setupMouseEventHandling(): void {
    if (!!this.click$ || !!this.move$) {
      this._destroyEvents$.next();
    }

    // For click events, we can't quite use the Observable.fromEvent since that adds a listener
    // on the DOM element, opposed to the map view object -- which means when we click a widget,
    // that is "on top" of our feature layer, we would still have a click event registered, even
    // though we probably wanted the widget to consume the event. For now, use this work around
    // to still use an observable for the click event
    this.click$ = new Observable((observer: Subscriber<MouseEvent>) => {
      const handler = (e: MouseEvent) => observer.next(e);
      const handle = this._mapView.on('click', handler);
      return () => handle.remove();
    });
    // This is what we would like to use..
    // this.click$ = fromEvent(this._mapView.container, 'click') as Observable<MouseEvent>;
    this.click$
      .pipe(
        switchMap((event: MouseEvent) => this.getGraphicHitTest(event)),
        takeUntil(this._destroyEvents$)
      )
      .subscribe((graphic: __esri.Graphic) => this._featureLayerSelect(graphic));

    this.move$ = fromEvent(this._mapView.container, 'mousemove') as Observable<MouseEvent>;
    this.move$
      .pipe(
        debounceTime(100),
        switchMap((event: MouseEvent) => (this.hasSelection() ? of(null) : this.getGraphicHitTest(event))),
        takeUntil(this._destroyEvents$)
      )
      .subscribe((graphic: __esri.Graphic) => this._featureLayerHighlight(graphic));
  }
}
