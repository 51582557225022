import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnInit,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { EsriMapService } from 'src/app/core/services';
import { IMapInfo } from 'src/app/core/interfaces/definitions/esri-map-info.defintion';
import * as esriConsts from 'src/app/core/esri/constants/constants';

@Component({
  selector: 'app-esri-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('map') mapEl: ElementRef;
  /**
   * Input: webMapProperties
   * Properties for the esri web map.
   */
  @Input() webMapProperties: __esri.WebMapProperties;
  /**
   * Input: mapProperties
   * Properties for the esri map.
   */
  @Input() mapProperties: __esri.MapProperties = {
    basemap: esriConsts.DEFAULT_BASEMAP,
  };
  /**
   * Input: mapViewProperties
   * Properties for the view of the esri map.
   */
  @Input() mapViewProperties: __esri.MapViewProperties;
  /**
   * Output: mapInit
   * Event emitted when map component has been initialized.
   */
  @Output() mapInit = new EventEmitter();
  /**
   * Class Fields
   */
  private _destroy$ = new Subject<void>();
  isLoading: boolean;

  constructor(private _mapService: EsriMapService) {}

  ngOnInit(): void {
    // Set the map loading flag to true on init (if set in loadMap -- we'll get an exprssion changed after check error).
    // The loading flag will be resolved to false after loadMap is called and subscribes to the map view 'updating'
    // property to toggle the loading flag.
    this.isLoading = true;
  }

  ngAfterViewInit(): void {
    this.loadMap();
  }

  ngOnDestroy(): void {
    // Reset the map/view objects on destruction so that the map service objects can be used from a clean slate
    // the next time a map component is created.
    this._mapService.reset();
    //
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Determine to load a WebMap or a custom map given the specified properties.
   * When finished, event emitted with the map and view objects that were loaded.
   */
  loadMap(): void {
    // Set the initial loading flag -- this will then be turned off when we hook up the map view 'updating'
    // watch handler.
    let mapPromise: Promise<any>;

    if (this._mapService.initialized(false)) {
      // Map and view already created -- just set the new DOM element for the view to be able to be shown.
      this._mapService.setMapViewContainer(this.mapEl.nativeElement);

      // Set up a listener to intialize the map view properties only after the view is ready and visible again
      combineLatest([this._mapService.ready$, this._mapService.suspended$]).subscribe((result: [boolean, boolean]) => {
        if (result[0] && !result[1]) {
          // View is now ready and not suspended (so visible) -- set any properties now.
          this._mapService.setViewProperties(this.mapViewProperties);
        }
      });

      // Initialize the rest of the map as normal
      this._initMap();
    } else {
      // Create the map and wait for the promise to resolve to initialize event callbacks and widgets
      if (this.mapProperties) {
        mapPromise = this._mapService.loadMap(this.mapProperties, this.mapViewProperties, this.mapEl.nativeElement);
      } else if (this.webMapProperties) {
        mapPromise = this._mapService.loadWebMap(
          this.webMapProperties,
          this.mapViewProperties,
          this.mapEl.nativeElement
        );
      } else {
        console.error('Map properties were not provided');
        return;
      }

      mapPromise.then((mapInfo: IMapInfo) => {
        mapInfo.view.popup.autoOpenEnabled = false;
        this._initMap();
      });
    }
  }

  private _initMap(): void {
    // Watch the updating property on the view to display loading indicator
    this._mapService.loading$.pipe(takeUntil(this._destroy$)).subscribe((val: boolean) => (this.isLoading = val));

    // Default widgets to show
    // TODO: add these to the MapView's DefaultUi
    this._mapService.loadFeatureWidget();
    this._mapService.loadBasemapGalleryWidget();
    this._mapService.loadLayerListWidget();

    // Map initialization is finished.
    this.mapInit.emit();
    this.mapInit.complete();
  }
}
