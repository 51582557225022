import { Directive, OnDestroy, Input } from '@angular/core';
import { Observable, of, Subject, from } from 'rxjs';
import { EsriMapService, AuthenticationService } from 'src/app/core/services';
import { IAppUser } from 'src/app/core/interfaces/models';
import { popupTemplate as roadSegmentPopupTemplate } from 'src/app/core/esri/templates/road-segment-popup.template';
import { takeUntil, tap, switchMap } from 'rxjs/operators';
import * as esri from 'src/app/core/esri/constants/constants';

@Directive({
  selector: '[appRoadFeature]',
})
export class RoadFeatureDirective implements OnDestroy {
  @Input()
  set showRoads(val: boolean) {
    this._visible = val;
    this.setVisible();
  }

  /**
   * Input mapInit: observable let's us know when the map info is available.
   */
  @Input()
  set mapInit(init$: Observable<void>) {
    this._destroy$.next();

    init$
      .pipe(
        switchMap(() => (this._featureLayerUrl ? of(null) : this._authService.currentUser())),
        tap((user: IAppUser) => {
          if (user) {
            this._featureLayerUrl = user.tenant.roadServiceApiUrl;
          }
        }),
        switchMap(() =>
          from(
            this._mapService.loadFeatureLayer(
              this._featureLayerUrl,
              esri.ROAD_LAYER_ID,
              roadSegmentPopupTemplate,
              this._visible
            )
          )
        ),
        switchMap((featureLayer: __esri.FeatureLayer) => {
          if (!!featureLayer) {
            if (this._visible !== featureLayer.visible) {
              this.setVisible();
            }
            return this._mapService.addLayerViewHighlight(featureLayer, featureLayer.objectIdField);
          } else {
            return of(null);
          }
        }),
        takeUntil(this._destroy$)
      )
      .subscribe((layerView: __esri.FeatureLayerView) => {
        this._initialized = true;
      });
  }

  private _initialized: boolean;
  private _visible: boolean;
  private _featureLayerUrl: string;
  private _destroy$: Subject<void>;

  constructor(private _mapService: EsriMapService, private _authService: AuthenticationService) {
    this._initialized = false;
    this._visible = true;
    this._featureLayerUrl = null;
    this._destroy$ = new Subject();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  setVisible(): void {
    if (this._initialized) {
      this._mapService.setLayerVisible(esri.ROAD_LAYER_ID, this._visible);
    }
  }
}
