export const popupTemplate = {
  content: [
    {
      type: 'media',
      mediaInfos: [
        {
          title: '<b>{name}</b>',
          caption: '<b>Barcode:</b> {barcode}<br><b>Location:</b> {assetLocation}',
          type: 'image',
          value: {
            sourceURL: '{url}',
          },
        },
      ],
    },
    // {
    //   type: 'fields',
    //   fieldInfos: [
    //     {
    //       label: 'Name',
    //       fieldName: 'name',
    //     },
    //     {
    //       label: 'Barcode',
    //       fieldName: 'barcode',
    //     },
    //     // {
    //     //   label: 'Installation Date',
    //     //   fieldName: 'installDate',
    //     //   format: {
    //     //     dateFormat: 'short-date-short-time',
    //     //   },
    //     // },
    //     {
    //       label: 'Location',
    //       fieldName: 'assetLocation',
    //     },
    //   ],
    // },
  ],
};
