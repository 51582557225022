import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { IModule } from 'src/app/core/interfaces/definitions/module-route.definition';
import { takeUntil } from 'rxjs/operators';

/**
 * Component for the main dashboard view
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnDestroy {
  /** Class fields */
  public modules: IModule[];

  private destroy$: Subject<void>;

  /**
   * Constructor
   * @param _router (Router): the application router to navigate through the app
   */
  constructor(private _router: Router, _route: ActivatedRoute) {
    /**
     * Retrieve the available modules from the activated route.
     * If it's not the primary outlet that emitted an event then it means
     * this page wasn't changed so don't update the module.
     */
    this.modules = [];
    this.destroy$ = new Subject();
    _route.data.pipe(takeUntil(this.destroy$)).subscribe((data: { module: IModule }) => {
      if (_route.outlet === 'primary' && data.module) {
        this.modules = data.module.subRoutes;
      }
    });
  }

  /**
   * Callback on init
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Navigate to the given url.
   * @param url (string): the location to navigate to.
   */
  public navigate(route: IModule) {
    if (route && route.url) {
      this._router.navigate([route.url]);
    }
  }
}
