import { Injectable } from '@angular/core';
import { AppUserRepositoryService } from './repositories';
import { IToolbarAction } from '../interfaces/definitions/toolbar-action.definition';
import { map } from 'rxjs/operators';
import { IAppUser } from '../interfaces/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToolbarBuilderService {
  constructor(private _userRepo: AppUserRepositoryService) {}

  public build(actions: IToolbarAction[]): Observable<IToolbarAction[]> {
    return this._userRepo.getCurrentUser().pipe(
      map((user: IAppUser) => {
        const toolbarActions = [];
        const userRoles = user.userRoles.map((userRole) => userRole.role.name);
        actions.forEach((action) => {
          if (!action.perm || action.perm.find((role) => userRoles.includes(role))) {
            // User has permission for this toolbar action
            toolbarActions.push(action);
          }
        });
        return toolbarActions;
      })
    );
  }
}
