import { IQueryFilter } from './query-filter';
import { Q_OR, Q_AND } from 'src/app/core/constants/query.constants';

/**
 * Helper for binary expressions
 */
export class QueryFilterBinaryExpression implements IQueryFilter {
  public left: IQueryFilter;
  public right: IQueryFilter;
  public property: string;
  public op: string;
  public displayName?: string;

  constructor(left: IQueryFilter, right: IQueryFilter, op: string, displayName?: string) {
    this.left = left;
    this.right = right;
    this.displayName = displayName;
    this.op = op;

    if (left.property === right.property) {
      this.property = left.property;
    }
  }

  public toFilterStr(): string {
    return `{${this.left.toFilterStr()}${this.op}${this.right.toFilterStr()}}`;
  }

  public cmpQueryfilters(other: QueryFilterBinaryExpression): boolean {
    if (!(other && other.left && other.right)) {
      return false;
    }
    let res = this.left.cmpQueryfilters(other.left);
    res = res && this.right.cmpQueryfilters(other.right);
    return res;
  }

  public copy(): QueryFilterBinaryExpression {
    return new QueryFilterBinaryExpression(this.left.copy(), this.right.copy(), this.op, this.displayName);
  }
}

export class QueryFilterAnd extends QueryFilterBinaryExpression {
  constructor(left: IQueryFilter, right: IQueryFilter, displayName?: string) {
    super(left, right, Q_AND, displayName);
  }
}

export class QueryFilterOr extends QueryFilterBinaryExpression {
  constructor(left: IQueryFilter, right: IQueryFilter, displayName?: string) {
    super(left, right, Q_OR, displayName);
  }
}
