import { Component, OnInit } from '@angular/core';
import { IAppUser, IWorkOrderRequest, IWorkOrderMaintenance } from 'src/app/core/interfaces/models';
import { IQueryParam } from 'src/app/core/interfaces/query/query';
import { ITableCard } from 'src/app/core/interfaces/definitions/table-card.definition';
import { QueryFilterAnd } from 'src/app/core/interfaces/query/filters/query-filter-binary';
import { QueryFilterExpression } from 'src/app/core/interfaces/query/filters/query-filter';
import { SignsModule, SignsComponentType } from 'src/app/shared/enums/signs-module';
import { TableQueryData } from 'src/app/core/interfaces/definitions/table-query-data.definition';
import { QueryService } from 'src/app/core/services/query.service';
import { Q_OP_EQ, Q_OP_NEQ, Q_OP_CON } from 'src/app/core/constants/query.constants';
import { WOR_STATUS_NAME } from 'src/app/core/constants/model-properties/work-order-status.properties';
import { UtilsService } from 'src/app/core/services/utils.service';
import { columns as RequestColumns } from 'src/app/shared/components/query/columns/work-order-request-columns';
import { columns as MaintenanceColumns } from 'src/app/shared/components/query/columns/work-order-maintenance-columns';
import {
  WO_STATUS_APPROVED,
  WorkOrderUtilsService,
  WO_STATUS_ASSIGNED_FS,
} from 'src/app/core/services/work-order-utils.service';
import {
  AppUserRepositoryService,
  APP_ROLE_STAFF,
} from 'src/app/core/services/repositories/app-user-repository.service';
import { WorkOrderRepositoryService } from 'src/app/core/services/repositories/work-order-repository.service';
import * as actions from 'src/app/shared/components/layout/table-toolbar/actions.descriptor';
import * as worProps from 'src/app/core/constants/model-properties/work-order-request.properties';
import * as womProps from 'src/app/core/constants/model-properties/work-order-maintenance.properties';
import { ITableColumn } from 'src/app/core/interfaces/definitions';

@Component({
  selector: 'app-table-card-list',
  templateUrl: './table-card-list.component.html',
  styleUrls: ['./table-card-list.component.scss'],
})
export class TableCardListComponent implements OnInit {
  /**
   * Templated cards that may require additional input at runtime, e.g. user info to filter permissions by.
   * This list is used to generate the list of cards displayed by the component - this is slightly
   * hard coded at the moment to only allow the cards that are defined specifically here, in the future
   * we may want to define this via the user config, but for now this will suffice.
   */
  private _currentUser: IAppUser;
  private _cardTemplates: Array<[ITableCard, (user: IAppUser) => TableQueryData<any>]>;
  private _requestColumns: ITableColumn<IWorkOrderRequest>[];
  cards: ITableCard[];

  constructor(
    private _userRepo: AppUserRepositoryService,
    private _workOrderRepo: WorkOrderRepositoryService,
    private _queryService: QueryService,
    private _workOrderUtils: WorkOrderUtilsService,
    private _utils: UtilsService
  ) {
    this._requestColumns = RequestColumns;

    this.cards = [];
    this._cardTemplates = [
      [
        {
          title: 'Open Work Order Requests',
          queryData: null,
          pageSize: 10,
          perm: [APP_ROLE_STAFF],
          toolbarActions: [
            {
              id: actions.ACTION_WOR_START,
              type: 'data',
              perm: [APP_ROLE_STAFF],
              listener: (request: IWorkOrderRequest[]) => this._createOrUpdateMaintenance(request[0]),
              constraints: [
                (value: IWorkOrderRequest[]) => ({
                  success: value.length === 1,
                  failureMessage: `You cannot start more than one Work Order Request.`,
                }),
              ],
            },
          ],
          actions: [
            {
              name: 'View All',
              fn: () =>
                this._utils.navigateRelativeToModule([SignsModule.componentTypeRoute(SignsComponentType.request)]),
            },
          ],
        },
        (user: IAppUser) => this._getRequestQueryData(user),
      ],
      [
        {
          title: 'Ongoing Maintenance',
          queryData: null,
          pageSize: 10,
          perm: [APP_ROLE_STAFF],
          toolbarActions: [
            {
              id: actions.ACTION_WOM_UNPLANNED,
              type: 'data',
              perm: [APP_ROLE_STAFF],
              listener: () => this._createOrUpdateMaintenance(null),
            },
            {
              id: actions.ACTION_EDIT,
              type: 'data',
              perm: [APP_ROLE_STAFF],
              listener: (maintenance: IWorkOrderMaintenance[]) => this._createOrUpdateMaintenance(maintenance[0]),
              constraints: [
                (value: IWorkOrderMaintenance[]) => ({
                  success: value.length === 1,
                  failureMessage: `You cannot edit more than one Work Order Maintenance.`,
                }),
              ],
            },
          ],
          actions: [
            {
              name: 'View All',
              fn: () =>
                this._utils.navigateRelativeToModule([SignsModule.componentTypeRoute(SignsComponentType.maintenance)]),
            },
          ],
        },
        (user: IAppUser) => this._getMaintenanceQueryData(user),
      ],
    ];
  }

  ngOnInit(): void {
    this._userRepo.getCurrentUser().subscribe((user: IAppUser) => {
      this._currentUser = user;
      this.cards = this._getCards(this._currentUser);
    });
  }

  /**
   * Initialize the list of cards to display for the user
   * @param user : the user to get the cards for
   */
  private _getCards(user: IAppUser): ITableCard[] {
    const cards: ITableCard[] = [];
    const userRoles = user.userRoles.map((r) => r.role.name);

    this._cardTemplates.forEach((template: [ITableCard, (user: IAppUser) => TableQueryData<any>]) => {
      if (!template[0].perm || userRoles.find((r) => template[0].perm.includes(r)) !== undefined) {
        template[0].queryData = template[1](user);
        cards.push(template[0]);
      }
    });

    return cards;
  }

  private _createOrUpdateMaintenance(request: IWorkOrderRequest | IWorkOrderMaintenance): void {
    const extraParams = this._workOrderUtils.getMaintenanceCreateOrUpdateParams(request);
    this._utils.navigateRelativeToModule(
      [SignsModule.componentTypeRoute(SignsComponentType.maintenance), 'form'],
      extraParams
    );
  }

  /**
   * Custom helper function to get the query for Work Order Request table card.
   * @param user : user to filter query results by
   */
  private _getRequestQueryData(user: IAppUser): TableQueryData<IWorkOrderRequest> {
    const queryData: TableQueryData<IWorkOrderRequest> = {
      param: this._queryService.toQuery(
        null,
        new QueryFilterAnd(
          new QueryFilterExpression(worProps.WOR_STAFF_USER_ID, Q_OP_EQ, user.id),
          new QueryFilterExpression(worProps.WOR_STATUS, Q_OP_CON, WO_STATUS_ASSIGNED_FS)
        ),
        {
          qsPrimary: worProps.WOR_DUE_DT,
          qsSecondary: worProps.WOR_CREATED_DT,
          qsIsAsc: false,
        },
        [worProps.WOR_ID, worProps.WOR_DUE_DT, worProps.WOR_STATUS, worProps.WOR_TYPE, worProps.WOR_SUPERVISOR_USER_ID]
      ),
      fn: (queryParam: IQueryParam) => this._workOrderRepo.getRequests(queryParam),
    };

    const columns = this._requestColumns;
    columns.forEach((c) => (c.visible = queryData.param.fields.includes(c.id)));
    queryData.columns = columns;

    return queryData;
  }

  /**
   * Custom helper function to get the query for Work Order Maintenance table card.
   * @param user : user to filter query results by
   */
  private _getMaintenanceQueryData(user: IAppUser): TableQueryData<IWorkOrderMaintenance> {
    const staticColumns = [
      womProps.WOM_TYPE,
      womProps.WOM_REASON,
      womProps.WOM_WORK_PERFORMED,
      womProps.WOM_ASSIGNMENTS,
      womProps.WOM_DURATION,
      womProps.WOM_DURATION_UNIT,
      womProps.WOM_NOTES,
      womProps.WOM_INSTALLED_TS_ID,
      womProps.WOM_ISSUED_BY_USER_ID,
      womProps.WOM_STATUS,
    ];
    const allColumns = [
      womProps.WOM_ID,
      womProps.WOM_CREATED_DATE_TIME,
      womProps.WOM_STATUS,
      womProps.WOM_TYPE,
      ...staticColumns,
    ];
    const queryData: TableQueryData<IWorkOrderMaintenance> = {
      param: this._queryService.toQuery(
        null,
        new QueryFilterAnd(
          new QueryFilterExpression(womProps.WOM_ISSUED_BY_USER_ID, Q_OP_EQ, user.id),
          new QueryFilterExpression(`${womProps.WOM_STATUS}.${WOR_STATUS_NAME}`, Q_OP_NEQ, WO_STATUS_APPROVED)
        ),
        {
          qsPrimary: womProps.WOM_CREATED_DATE_TIME,
          qsSecondary: womProps.WOM_ID,
          qsIsAsc: false,
        },
        allColumns
      ),
      fn: (queryParam: IQueryParam) => this._workOrderRepo.getMaintenance(queryParam),
    };

    const columns = MaintenanceColumns;
    columns.forEach((c) => (c.visible = queryData.param.fields.includes(c.id)));
    queryData.columns = columns;

    return queryData;
  }
}
