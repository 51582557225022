import { Injectable } from '@angular/core';
import { RepositoryBaseService } from './repository-base.service';
import { INotificationDevice } from '../../interfaces/definitions';

const notificationUri = 'notificationHandler';

@Injectable({
  providedIn: 'root'
})
export class NotificationRegistrationService {

  constructor(private repo: RepositoryBaseService) { }

  public getDevice(installationId: string) {
    // return this.repo.getData(`${notificationUri}/${installationId}`, null, false);

    // DOUBLE check this
    return this.repo.getData(`${notificationUri}/${installationId}`);
  }

  public installDevice(deviceProperties: INotificationDevice) {
    return this.repo.create(`${notificationUri}`, deviceProperties);
  }

  public updateDevice(installationId: string, deviceProperties: object) {
    return this.repo.patch(`${notificationUri}/${installationId}`, deviceProperties);
  }

  public deleteDevice(installationId: string) {
    return this.repo.delete(`${notificationUri}/${installationId}`);
  }
}
