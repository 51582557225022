import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './../material/material.module';
import { MapComponent } from './components/esri/map/map.component';
import { NoSelectionDisableDirective } from './directives/no-selection-disable.directive';
import { DateRangeSelectorComponent } from './components/widgets/date-range-picker/date-range-picker.component';
import { GalleryComponent } from './components/widgets/gallery/gallery.component';
import { TableComponent } from './components/layout/table/table.component';
import { TableRowsComponent } from './components/layout/table-rows/table-rows.component';
import { TableToolbarComponent } from './components/layout/table-toolbar/table-toolbar.component';
import { StringFilterInputComponent } from './components/input/string-filter-input/string-filter-input.component';
import { DateFilterInputComponent } from './components/input/date-filter-input/date-filter-input.component';
import { GraphListComponent } from './components/widgets/graph-list/graph-list.component';
import { FilterToolbarComponent } from './components/layout/filter-toolbar/filter-toolbar.component';
import { ActionsListComponent } from './components/widgets/actions-list/actions-list.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { EditDialogComponent } from './components/dialogs/edit-dialog/edit-dialog.component';
import { PasswordVerifierDirective } from './directives/password-verifier.directive';
import { PasswordErrorDisplayComponent } from '../components/dialogs/user-account-dialogs/password-error-display/password-error-display.component';
import { ListSelectionDialogComponent } from './components/dialogs/list-selection-dialog/list-selection-dialog.component';
import { NoClickPropagationDirective } from './directives/no-click-propagation.directive';
import { EllipsisDirective } from './directives/ellipsis.directive';
import { TableCardComponent } from './components/layout/table-card/table-card.component';
import { TableCardListComponent } from './components/widgets/table-card-list/table-card-list.component';
import { BaseFormComponent } from './components/forms/base-form/base-form.component';
import { ChoicesInputControlComponent } from './components/forms/controls/choices-input-control/choices-input-control.component';
import { FileInputControlComponent } from './components/forms/controls/file-input-control/file-input-control.component';
import { OptionsDialogComponent } from './components/dialogs/options-dialog/options-dialog.component';
import { DetailsTableComponent } from './components/layout/details-panel/details-table/details-table.component';
import { DetailsHistoryComponent } from './components/layout/details-panel/details-history/details-history.component';
import { UserPipe } from './pipes/user.pipe';
import { ButtonOptionsFilterInputComponent } from './components/input/options-filter-input/button-options/button-options-filter-input.component';
import { ListOptionsFilterInputComponent } from './components/input/options-filter-input/list-options/list-options-filter-input.component';
import { MomentDatePipe } from './pipes/moment-date.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DisplayPipe } from './pipes/display.pipe';
import { GraphPipe } from './pipes/graph.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { QueryFilterPipe } from './pipes/query-filter.pipe';
import { RowHeightDirective } from './directives/row-height.directive';
import { VirtualScrollDirective } from './directives/virtual-scroll.directive';
import { SearchDirective } from './directives/esri/search/search.directive';
import { SketchDirective } from './directives/esri/sketch/sketch.directive';
import { CustomWidgetDirective } from './directives/esri/custom-widget/custom-widget.directive';
import { ScreenshotComponent } from './components/esri/widgets/screenshot/screenshot.component';
import { RoadFeatureDirective } from './directives/esri/road-feature.directive';
import { SignsFeatureDirective } from './directives/esri/signs-feature.directive';
import { UrlBuilderPipe } from './pipes/url-builder.pipe';
import { PullToRefreshDirective } from './directives/pull-to-refresh.directive';
import { RefreshWrapperComponent } from './components/widgets/refresh-wrapper/refresh-wrapper.component';
import { VerticalStepperFormComponent } from './components/forms/views/vertical-stepper-form/vertical-stepper-form.component';
import { IfEmptyPipe } from './pipes/if-empty.pipe';
import { ImagePipe } from './pipes/image.pipe';
import { HtmlPipe } from './pipes/html.pipe';
import { AuditTypePipe } from './pipes/audit-type.pipe';
import { GpsInputControlComponent } from './components/forms/controls/gps-input-control/gps-input-control.component';

@NgModule({
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, NgxChartsModule, ColorPickerModule],
  declarations: [
    // Directives
    NoClickPropagationDirective,
    EllipsisDirective,
    NoSelectionDisableDirective,
    PasswordVerifierDirective,
    RowHeightDirective,
    VirtualScrollDirective,
    CustomWidgetDirective,
    SearchDirective,
    SketchDirective,
    RoadFeatureDirective,
    SignsFeatureDirective,
    // Pipes
    UserPipe,
    MomentDatePipe,
    TruncatePipe,
    DisplayPipe,
    GraphPipe,
    FilterPipe,
    QueryFilterPipe,
    // Components
    MapComponent,
    ScreenshotComponent,
    DateRangeSelectorComponent,
    GalleryComponent,
    DetailsTableComponent,
    DetailsHistoryComponent,
    TableComponent,
    TableRowsComponent,
    TableToolbarComponent,
    StringFilterInputComponent,
    ButtonOptionsFilterInputComponent,
    ListOptionsFilterInputComponent,
    FilterToolbarComponent,
    DateFilterInputComponent,
    GraphListComponent,
    ActionsListComponent,
    PasswordErrorDisplayComponent,
    TableCardComponent,
    TableCardListComponent,
    BaseFormComponent,
    ChoicesInputControlComponent,
    FileInputControlComponent,
    // Dialog Components
    ConfirmDialogComponent,
    EditDialogComponent,
    ListSelectionDialogComponent,
    OptionsDialogComponent,
    UrlBuilderPipe,
    PullToRefreshDirective,
    RefreshWrapperComponent,
    VerticalStepperFormComponent,
    IfEmptyPipe,
    ImagePipe,
    HtmlPipe,
    AuditTypePipe,
    GpsInputControlComponent,
  ],
  exports: [
    // Modules
    MaterialModule,
    NgxChartsModule,
    ColorPickerModule,
    // Directives
    NoSelectionDisableDirective,
    PasswordVerifierDirective,
    NoClickPropagationDirective,
    EllipsisDirective,
    CustomWidgetDirective,
    RowHeightDirective,
    VirtualScrollDirective,
    SearchDirective,
    SketchDirective,
    RoadFeatureDirective,
    SignsFeatureDirective,
    // Pipes
    UserPipe,
    MomentDatePipe,
    TruncatePipe,
    DisplayPipe,
    GraphPipe,
    FilterPipe,
    QueryFilterPipe,
    UrlBuilderPipe,
    IfEmptyPipe,
    // Components
    MapComponent,
    ScreenshotComponent,
    DateRangeSelectorComponent,
    GalleryComponent,
    DetailsTableComponent,
    DetailsHistoryComponent,
    TableComponent,
    TableRowsComponent,
    TableToolbarComponent,
    StringFilterInputComponent,
    ButtonOptionsFilterInputComponent,
    ListOptionsFilterInputComponent,
    DateFilterInputComponent,
    GraphListComponent,
    FilterToolbarComponent,
    DateFilterInputComponent,
    ActionsListComponent,
    ConfirmDialogComponent,
    EditDialogComponent,
    ListSelectionDialogComponent,
    PasswordErrorDisplayComponent,
    TableCardComponent,
    TableCardListComponent,
    BaseFormComponent,
    OptionsDialogComponent,
    PullToRefreshDirective,
    RefreshWrapperComponent,
    VerticalStepperFormComponent,
  ],
  entryComponents: [
    ActionsListComponent,
    ConfirmDialogComponent,
    EditDialogComponent,
    ListSelectionDialogComponent,
    PasswordErrorDisplayComponent,
    OptionsDialogComponent,
  ],
})
export class SharedModule {}
