import { Component, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IAppUser, INotificationMessage } from '../../../core/interfaces/models';
import { AuthenticationService, AssetService } from './../../../core/services';
import { Observable, Subject } from 'rxjs';
import { StateService } from 'src/app/core/services/state.service';
import { IActionItem } from 'src/app/core/interfaces/definitions/action-item.definition';
import { IModule } from 'src/app/core/interfaces/definitions/module-route.definition';
import { map, takeUntil } from 'rxjs/operators';

/**
 * Component for the top navigation toolbar.
 */
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnDestroy {
  /**
   * Output toggleSidenav:
   * Event emitted when side bar navigation is toggled.
   */
  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleNotificationSidenav = new EventEmitter<void>();

  /** Class fields */
  isAuthenticated: boolean;
  hasTenantLogoImage: boolean;
  moreActions: Array<Array<IActionItem>>;
  tenantLogoImage: string;
  module: IModule;
  manageModule: IModule;
  unseenNotifications$: Observable<number>;
  isMobile: boolean;
  isTablet: boolean;

  private currentUser: IAppUser;
  private destroy$: Subject<void>;

  @HostListener('window:resize', ['$event'])
  resize(): void {
    this.isMobile = this._stateService.isMobile();
    this.isTablet = this._stateService.isTablet();
  }

  /**
   * Constructor
   * Parse the router NavigationEnd url to determine the module mode to set.
   */
  constructor(
    private _assetService: AssetService,
    private _stateService: StateService,
    private _router: Router,
    private _authService: AuthenticationService,
    _route: ActivatedRoute
  ) {
    this.tenantLogoImage = null;
    this.hasTenantLogoImage = false;
    this.isAuthenticated = false;
    this.currentUser = null;
    this.resize();
    /**
     * Subscribe to notification updates so we can update the badge that's displayed.
     */
    this.unseenNotifications$ = this._stateService.notifications$.pipe(
      map((res) => res.filter((x) => !x.hasRead).length)
    );

    this.moreActions = [
      [
        { id: 1, name: 'My Account', icon: 'person', exec: () => this.navigate(['settings', 'account']) },
        {
          id: 2,
          name: 'Notifications',
          icon: 'notifications',
          exec: () => this.toggleNotificationMenu(),
        },
        { id: 3, name: 'Settings', icon: 'settings', exec: () => this.navigate(['settings']) },
      ],
      [
        { id: 1, name: 'Logout', icon: 'exit_to_app', exec: () => this.logout() },
        { id: 2, name: 'Support', icon: 'help', exec: () => this.navigate(['help']) },
      ],
    ];

    this.destroy$ = new Subject();
    /**
     * Subscribe to module changes. Whenever a navigation is started, this observable will emit
     * and update the avaible routes to navigate.
     */
    _route.data.pipe(takeUntil(this.destroy$)).subscribe((data: { module: IModule }) => {
      this.module = data.module;
      this.manageModule = data.module?.subRoutes.find((route: IModule) => route.url === 'admin');
    });

    /**
     * Subscribe to any user changes. If we receive null, reset, else initialize the user's tenant logo and switch views
     */
    _authService.currentUserChanges$.pipe(takeUntil(this.destroy$)).subscribe((user: IAppUser) => {
      this.currentUser = user;
      this.isAuthenticated = !!user;
      if (this.currentUser) {
        this.getTenantLogoImage();
      }
    });

    /**
     * Subscribe to notification updates so we can update the badge that's displayed.
     */
    this.unseenNotifications$ = this._stateService.notifications$.pipe(
      map((res: INotificationMessage[]) => res.filter((x: INotificationMessage) => !x.hasRead).length)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private logout(): void {
    this.currentUser = null;
    this.isAuthenticated = false;
    this.hasTenantLogoImage = false;
    this.tenantLogoImage = null;
    this._authService.logout();
    this.navigate(['login']);
  }

  toggleSideNav(): void {
    this._stateService.toggleSidenav();
  }

  toggleNotificationMenu(): void {
    this._stateService.toggleNotificationMenu();
  }

  navigate(url: string | string[], relativeToModule?: boolean): void {
    if (url) {
      if (typeof url === 'string') {
        url = [url];
      }
      const navigation = { outlets: { primary: url } };
      if (relativeToModule && !!this.module.url) {
        navigation.outlets.primary.unshift(this.module.url);
      }
      this._router.navigate([navigation]);
    }
  }

  private getTenantLogoImage(): void {
    this._assetService.getAsset(this.currentUser.tenant.logoUrl).subscribe((image: string) => {
      if (image) {
        this.hasTenantLogoImage = true;
        this.tenantLogoImage = image;
      }
    });
  }
}
