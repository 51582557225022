import { Component } from '@angular/core';
import { Observable, throwError, Subject, of } from 'rxjs';
import { AppUserRepositoryService } from 'src/app/core/services/repositories';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { switchMap, take, finalize, map, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent {
  public isLoading: boolean;
  public message$: Observable<string>;

  constructor(private _router: Router, _userRepo: AppUserRepositoryService, activatedRoute: ActivatedRoute) {
    this.isLoading = false;
    this.message$ = activatedRoute.queryParamMap.pipe(
      take(1),
      switchMap((value: Params) => {
        const email = value.get('email');
        const token = value.get('token');
        if (!!email && !!token) {
          this.isLoading = true;
          return _userRepo.confirmEmail(email, token);
        }
        return throwError({});
      }),
      catchError(() => of('We were not able to confirm your email at this time.')),
      map((msg: string) => (!!msg ? msg : 'Your email has been confirmed!')),
      finalize(() => (this.isLoading = false))
    );
  }

  public login() {
    this._router.navigate(['/login']);
  }
}
