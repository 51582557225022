import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { AuthGuard, PermissionGuard } from './core/guards';
import { HelpComponent } from './modules/signs/help/help.component';
import { ErrorComponent } from './components/error/error.component';
import { ModuleGuard } from './core/guards/module.guard';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { ModuleRouteResolver } from './core/guards/module.resolver';
import { NavComponent } from './components/navigation/nav/nav.component';
import { NavBarComponent } from './components/navigation/nav-bar/nav-bar.component';
import { NotificationMenuComponent } from './components/navigation/notification-menu/notification-menu.component';

const appRoutes: Routes = [
  // Todo: The module doesn't need to be resolved unless we're authenticated, perhaps restructure the auxilliary routing to
  // take advantage of that fact? (i.e. switch paths based on if we're authenticated, the authenticated path resolves modules). Since
  // the nav bar is static across all pages, we would need to split the nav bar component to display authenticated vs not authenticated.
  {
    path: '',
    component: NavBarComponent,
    outlet: 'navbar',
    runGuardsAndResolvers: 'always',
    resolve: {
      module: ModuleRouteResolver,
    },
  },
  {
    path: '',
    component: NavComponent,
    outlet: 'navigation',
    runGuardsAndResolvers: 'always',
    resolve: {
      module: ModuleRouteResolver,
    },
  },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'resetpassword', component: ResetPasswordComponent },
  { path: 'confirmemail', component: ConfirmEmailComponent },
  { path: 'help', component: HelpComponent },
  { path: '403', component: ErrorComponent },
  { path: '404', component: ErrorComponent },
  { path: '500', component: ErrorComponent },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: NotificationMenuComponent,
        outlet: 'notification-menu',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        resolve: {
          module: ModuleRouteResolver,
        },
      },
      { path: 'settings', loadChildren: './modules/settings/settings.module#SettingsModule' },
      {
        path: 'signs',
        loadChildren: './modules/signs/signs.module#SignsModule',
        canActivate: [PermissionGuard, ModuleGuard],
      },
      { path: 'parking-meters', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'fire-hydrants', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'traffic-signals', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'street-lights', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'parking-lots', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      {
        path: 'cycling-infrastructures',
        component: ComingSoonComponent,
        canActivate: [PermissionGuard, ModuleGuard],
      },
      { path: 'sidewalks', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'curbs', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'trees', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'parks', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'trails', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'road-markings', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'road-surfaces', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'road-geometries', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'storm-sewers', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'bridges-culverts', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'catch-basins', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'ditches-outfalls', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
      { path: 'guide-rails-barriers', component: ComingSoonComponent, canActivate: [PermissionGuard, ModuleGuard] },
    ],
  },
  { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

export const routing = RouterModule.forRoot(appRoutes);
