import { Injectable } from '@angular/core';
import { FilterMap } from '../interfaces/query/filter-map';
import { IQueryFilter } from '../interfaces/query/filters/query-filter';

/**
 * Service that handles storing and retreiving values between routes
 * Use this when passing filters to perserve type between routes.
 */
@Injectable({
  providedIn: 'root'
})
export class FilterRouteMapService {

  private routeData: FilterMap;
  constructor() {
    this.routeData = {};
  }

  public addFilter(name: string, value: IQueryFilter) {
    this.routeData[name] = value;
  }

  public getRouteData() {
    return this.routeData;
  }

  public clear() {
    this.routeData = {};
  }
}
