import { Input, OnDestroy, Directive } from '@angular/core';
import { EsriMapService } from 'src/app/core/services/esri-map.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { POSITION_TOP_RIGHT, WID_SEARCH_ID } from 'src/app/core/esri/constants/constants';

@Directive({
  selector: '[appSearchWidget]',
})
export class SearchDirective implements OnDestroy {
  /**
   * Input dataSubject: observable let's us know when the map info is available.
   */
  @Input()
  set mapInit(init$: Observable<void>) {
    // Clean up any previous subscriptions
    this._destroy$.next();

    if (init$) {
      init$
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => this._mapService.loadSearchWidget(this.searchPosition, this.searchExpandable));
    }
  }
  /**
   * Input clickEvent: handle a click event on the widget.
   */
  @Input()
  set clickEvent(event$: Observable<__esri.Point>) {
    // Clean up any previous click event subscriptions.
    this._destroyClick$.next();

    event$
      .pipe(takeUntil(this._destroyClick$))
      .subscribe((point: __esri.Point) => this._mapService.searchClickHandler(point));
  }
  /**
   * Input position: the widget's positioning within the map
   */
  @Input() searchPosition: string = POSITION_TOP_RIGHT;
  /**
   * Input expandable: whether or not the widget is contained in an Expand widget.
   */
  @Input() searchExpandable = false;
  /**
   * Class Fields
   */
  private _destroy$ = new Subject();
  private _destroyClick$ = new Subject();

  constructor(private _mapService: EsriMapService) {}

  ngOnDestroy(): void {
    // FIXME: temporarily remove/destroy the sketch widget -- map service should handle adding/removing
    // without destroying the actual widget, so we can reuse it when needed
    this._mapService.removeContent(`${WID_SEARCH_ID}-expand`);

    this._destroy$.next();
    this._destroy$.complete();
    this._destroyClick$.next();
    this._destroyClick$.complete();
  }
}
