import { Pipe, PipeTransform } from '@angular/core';
import { IAudit } from 'src/app/core/interfaces/models';

@Pipe({
  name: 'auditType',
})
export class AuditTypePipe implements PipeTransform {
  transform(audit: IAudit): unknown {
    const type = audit ? audit.auditType : undefined;
    switch (type) {
      case '+':
        return 'Created';
      case '-':
        return 'Deleted';
      case '~':
        return 'Modified';
      default:
        return 'Unknown';
    }
  }
}
