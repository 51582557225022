import { Injectable } from '@angular/core';
import { HttpResponse, HttpRequest } from '@angular/common/http';
import { RepositoryBaseService } from './repositories';

// Default cached request life span is 1 day.
const MAX_AGE = 86400;

/**
 * Service to cache HTTP requests using Observables.
 */
@Injectable({
  providedIn: 'root'
})
export class RequestCacheService {

  private _cache = new Map();

  constructor(private _repo: RepositoryBaseService) {}

  /**
   * Return the cached request, or undefined if not found.
   * @param request (HttpRequest<any>): The HTTP request.
   */
  public get(request: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = request.urlWithParams;
    const cached = this._cache.get(url);

    if (!cached) {
      return undefined;
    }

    const isExpired = cached.lastRead < (Date.now() - MAX_AGE);
    return isExpired ? undefined : cached.response;
  }

  /**
   * Store the HTTP request along with the response in the cache.
   * @param request (HttpRequest<any>): The HTTP request
   * @param response (HttpResponse<any>): The request's response.
   */
  public put(request: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = request.url;
    const entry = { url, response, lastRead: Date.now() };
    this._cache.set(url, entry);

    const expired = Date.now() - MAX_AGE;
    this._cache.forEach(expiredEntry => {
      if (expiredEntry.lastRead < expired) {
        this._cache.delete(expiredEntry.url);
      }
    });
  }

  /**
   * Return true if the request is cachable.
   * @param request (HttpRequest<any>): The HTTP request.
   */
  public isCachable(request: HttpRequest<any>) {
    return request.method === 'GET' && this._repo.isUrlCachable(request.url);
  }
}
