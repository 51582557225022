import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IFilter } from 'src/app/core/interfaces/definitions/filter.definition';
import { IQueryFilter } from 'src/app/core/interfaces/query/filters/query-filter';
import { QueryFilterAnd } from 'src/app/core/interfaces/query/filters/query-filter-binary';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-filter-toolbar',
  templateUrl: './filter-toolbar.component.html',
  styleUrls: ['./filter-toolbar.component.scss'],
})
export class FilterToolbarComponent implements OnInit, OnDestroy {
  /**
   * Input filters
   */
  @Input() filters: IFilter[];
  /**
   * Output filterChanged
   */
  @Output() filterChanged = new EventEmitter<IQueryFilter>(true);
  /**
   * Class properties
   */
  private currentFilter: IQueryFilter;
  private _destroy$ = new Subject();

  constructor() {
    this.filters = [];
  }

  ngOnInit() {
    // when a filter changes, grab the latest filters from each control and rebuild the query
    combineLatest(this.filters.map((f: IFilter) => f.filter))
      .pipe(
        map((qfs: IQueryFilter[]) => {
          // Remove null query filters and combine each filter control into one filter.
          qfs = qfs.filter((qf: IQueryFilter) => qf);
          return qfs.length > 0
            ? qfs.reduce((curr: IQueryFilter, prev: IQueryFilter) => new QueryFilterAnd(curr, prev))
            : null;
        }),
        takeUntil(this._destroy$)
      )
      .subscribe((qf: IQueryFilter) => {
        this.currentFilter = qf;
        this.filterChanged.emit(this.currentFilter);
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public clearFilters() {
    this.filters.forEach((f: IFilter) => {
      if (f.filter.value) {
        f.filter.next(null);
      }
    });
  }
}
