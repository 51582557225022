import { Injectable, NgZone } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin, BehaviorSubject, iif, concat, EMPTY, ReplaySubject, merge } from 'rxjs';
import { concatMap, defaultIfEmpty, takeLast, switchMap } from 'rxjs/operators';
import { NotificationRegistrationService } from './repositories/notification-registration.service';
import { IAppUser } from '../interfaces/models';
import { InformationDialogComponent } from 'src/app/components/dialogs/information-dialog/information-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationHandlerService {
  private pnsHandle: string;
  private platformTag = 'fcm';
  private roleTags: string[] = [];
  private userTag: string;
  private installationId: string;
  private tokenChanged = new ReplaySubject<string>();

  installation: any;
  newMessage = new BehaviorSubject<any>(null);
  initializedEvent = new BehaviorSubject(false);

  private dialogOptions: MatDialogConfig = {
    panelClass: 'notification-panel',
    closeOnNavigation: false,
    hasBackdrop: false,
    position: {
      top: '1rem',
      right: '1rem',
    },
    minWidth: '300px',
    maxWidth: '500px',
  };

  /* use ngZone explicitly because certain callbacks happens from outside the scope of angular
      which causes dialogs to behave strangely . */
  constructor(
    private messaging: AngularFireMessaging,
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private notificationRegService: NotificationRegistrationService,
    private matDialog: MatDialog,
    private platform: Platform
  ) {}

  canRunServiceWorker(): boolean {
    return (
      'serviceWorker' in navigator &&
      (location.protocol.includes('https') || location.hostname.includes('localhost')) &&
      !(this.platform.WEBKIT || this.platform.IOS)
    );
  }

  /**
   * Sets up the callbacks provided by the firebase messaging library including token refreshes and message events.
   */
  initializeCallbacks(): void {
    const notificationSub = this.messaging.requestPermission
      .pipe(
        switchMap(() => {
          this.messaging.onMessage((msg: any) => {
            this.newMessage.next(msg);
            this.notifyUser(msg);
          });
          return this.messaging.tokenChanges;
        })
      )
      .subscribe(
        (token: string) => {
          this.pnsHandle = token;
          if (this.installation) {
            this.updateHandle();
          }
        },
        () => {
          // we've errored out, most likely because notifications were blocked by the user
          // in any case we can't fix the problem, so unsubscribe from the event listeners
          this.initializedEvent.next(true);
          if (notificationSub) {
            notificationSub.unsubscribe();
          }
        }
      );
  }

  /**
   * Initializes the properties of the current user (their installation id, device token).
   * Updates the installation with the current device token if it's changed.
   * Uses 'initializedEvent' to emit a done signal
   */
  initializeMessaging(user: IAppUser): void {
    if (!this.initializedEvent.value) {
      this.installationId = `${user.id}-${this.platformTag}`;
      this.userTag = `user:${user.id}`;
      this.roleTags = [];

      for (const role of user.userRoles) {
        this.roleTags.push(role.role.name.toLowerCase());
      }
      forkJoin([this.messaging.getToken, this.notificationRegService.getDevice(this.installationId)]).subscribe(
        ([token, installation]) => {
          // after the forkjoined event emits, do the final round of property updates
          if (installation) {
            this.installation = installation;
          }
          this.tokenChanged.next(token);
          this.initializedEvent.next(true);
        }
      );
    }
  }

  /**
   * Creates a notification installation for this user on this platform
   * Will update an existing installation with the current PNS handle if it has expired or changed otherwise
   * @param tagList The list of groups to receive notifications for
   */
  registerDevice(tagList: string[]): void {
    if (this.installationId) {
      if (tagList.indexOf(this.userTag) < 0) {
        tagList.push(this.userTag);
      }
      if (this.roleTags.length > 0) {
        tagList = tagList.concat(this.roleTags);
      }
      const installation = {
        installationId: this.installationId,
        platform: this.platformTag,
        token: '',
        tags: tagList,
      };

      iif(() => this.pnsHandle === null, this.messaging.getToken, EMPTY)
        .pipe(
          // if it's the empty observable that means we have a token
          defaultIfEmpty(this.pnsHandle),
          // only after the token event emits
          concatMap((token: string) => {
            installation.token = token;
            // concat to ensure order
            return concat(
              this.notificationRegService.installDevice(installation),
              this.notificationRegService.getDevice(this.installationId)
            );
          }),
          // take only the getDevice event
          takeLast(1)
        )
        .subscribe((installationResult: object) => {
          if (installationResult) {
            this.installation = installationResult;
            this.displayMessage('You are now subscribed for notifications.');
          }
        });
    } else {
      this.displayMessage(
        'Could not obtain a registration from the notification server, refresh the page to try again.'
      );
    }
  }

  /**
   * Deletes a user's installation (on Azure) and invalidates the current platform token
   */
  unregisterDevice(): void {
    if (this.installationId) {
      this.notificationRegService.deleteDevice(this.installationId).subscribe((_) => {
        this.invalidateHandle();
        this.displayMessage('You are now unsubscribed from notifications.');
      });
    }
  }

  /**
   * Delete the token on the firebase server, this device will no longer receive notifications.
   */
  invalidateHandle(): void {
    this.messaging.deleteToken(this.pnsHandle).subscribe(
      (deleted) => {
        if (deleted) {
          this.initializedEvent.next(false);
          this.pnsHandle = null;
        }
      },
      (err: any) => {
        // swallow the error, only happens if the token was not found on the firebase side
        this.initializedEvent.next(false);
        this.pnsHandle = null;
      }
    );
  }

  /**
   * Update the list of groups this user is subscribed to for this platform
   * @param tagList The list of groups to receive notifications for
   */
  updateTags(tagList: string[]): void {
    if (this.installationId) {
      if (tagList.indexOf(this.userTag) < 0) {
        tagList.push(this.userTag);
      }
      if (this.roleTags.length > 0) {
        tagList = tagList.concat(this.roleTags);
      }
      this.notificationRegService
        .updateDevice(this.installationId, [
          {
            path: '/tags',
            op: 'replace',
            value: tagList,
          },
        ])
        .subscribe((_) => {
          this.installation.tags = tagList;
          this.displayMessage('Preferences updated.');
        });
    }
  }

  /**
   * Update the list of groups this user is subscribed to for this platform
   * @param tagList The list of groups to receive notifications for
   */
  updateHandle(): void {
    if (this.installationId) {
      this.notificationRegService
        .updateDevice(this.installationId, [
          {
            path: '/pushChannel',
            op: 'replace',
            value: this.pnsHandle,
          },
        ])
        .subscribe();
    }
  }

  private displayMessage(message: string): void {
    // callbacks are made from outside of angular, so explicitly have to tell it we want to be ran inside angular
    this.ngZone.run(() => this.snackBar.open(message, 'Okay!', { verticalPosition: 'bottom', duration: 2000 }));
  }

  /**
   * Shows a card with the notification details when a message is received
   * @param payload The notification object received from firebase
   */
  private notifyUser(payload: any): void {
    // callbacks are made from outside of angular, so explicitly have to tell it we want to be ran inside angular
    this.ngZone.run(() => {
      // kinda have to just know the structure of the message
      if (!!payload && !!payload.data) {
        const data = payload.data;
        this.dialogOptions.data = {
          title: data.title,
          body: data.body,
        };
        this.matDialog.open(InformationDialogComponent, this.dialogOptions);
      }
    });
  }
}
