import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ifEmpty',
})
export class IfEmptyPipe implements PipeTransform {
  transform(value: string, defaultVal: string): unknown {
    return !!value ? value : defaultVal;
  }
}
