import { Component, Input } from '@angular/core';
import { IAudit } from 'src/app/core/interfaces/models';
import { HistoryView } from 'src/app/shared/history-view/history-view';
import { Observable, of } from 'rxjs';
import { switchMap, shareReplay, finalize, map } from 'rxjs/operators';

@Component({
  selector: 'app-details-history',
  templateUrl: './details-history.component.html',
  styleUrls: ['./details-history.component.scss'],
})
export class DetailsHistoryComponent {
  @Input()
  set historyView(view: HistoryView) {
    if (view) {
      this.isLoading = true;

      this.data = of(null).pipe(
        switchMap(() => view.getHistory()),
        map((records: IAudit[]) => {
          // Remove blank entries
          // This should be called regardless of whether or not objects were nested
          const changeRows = [];
          for (const row of records) {
            const auditDescription = view.processAuditRecord(row);
            if (auditDescription !== undefined) {
              changeRows.push(row);
              this.changeLog[row.id] = auditDescription;
            }
          }
          return changeRows;
        }),
        finalize(() => (this.isLoading = false)),
        shareReplay(1)
      );
    }
  }

  data: Observable<IAudit[]>;
  isLoading: boolean;
  changeLog: object;

  constructor() {
    this.changeLog = {};
    this.isLoading = false;
  }
}
