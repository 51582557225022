import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPhoto } from 'src/app/core/interfaces/definitions/photo.definition';

@Component({
  selector: 'app-picture-dialog',
  templateUrl: './picture-dialog.component.html',
  styleUrls: ['./picture-dialog.component.css'],
})
export class PictureDialogComponent implements OnInit {
  private currentIndex: number;
  private images: IPhoto[];
  currentImage: IPhoto;
  numImages: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.images = !!data.images ? data.images : [];
    this.currentIndex = data.startingIndex ?? 0;
    this.currentImage = this.images[this.currentIndex];
    this.numImages = this.images.length;
  }

  ngOnInit(): void {}

  @HostListener('window:keyup', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (this.numImages > 1) {
      switch (event.key) {
        case 'ArrowRight':
          this.next();
          break;
        case 'ArrowLeft':
          this.previous();
          break;
      }
      event.stopPropagation();
    }
  }

  previous(): void {
    this.currentIndex = this.currentIndex > 0 ? this.currentIndex - 1 : this.numImages - 1;
    this.currentImage = this.images[this.currentIndex];
  }

  next(): void {
    this.currentIndex = ++this.currentIndex < this.numImages ? this.currentIndex : 0;
    this.currentImage = this.images[this.currentIndex];
  }
}
