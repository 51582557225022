/*
 * The delay between API calls
 * If we receive more than one call within this time, drop the earlier ones and use the newest call
 *
 * Time in milliseconds
 */
export const API_DB_TIME = 200;
export const VALUE_CHANGE_DB_TIME = 1000;
export const POPUP_DISMISS_TIME = 3000;
export const SNACKBAR_DISMISS_TIME = 1000;

// Set to Int.Max so we never have to worry about it being not enough
export const PAGINATOR_ALL_ITEMS_COUNT = 2147483647;

/*
 * Signs module
 */

// admin pages
export const ADMIN_FT_LOCATION_PAGE = 'field-type-location';
export const ADMIN_INVENTORY_STATUS_PAGE = 'inventory-status';
export const ADMIN_OWNER_PAGE = 'owner';
export const ADMIN_OWNER_TYPE_PAGE = 'owner-type';
export const ADMIN_ROAD_PAGE = 'road';
export const ADMIN_ROAD_VERIFY_PAGE = 'road-verification';
export const ADMIN_TS_TYPE_PAGE = 'traffic-sign-type';
export const ADMIN_TS_CATEGORY_PAGE = 'traffic-sign-category';
export const ADMIN_TS_FACEMATERIAL_PAGE = 'traffic-sign-face-material';
export const ADMIN_TS_BASETYPE_PAGE = 'traffic-sign-base-type';
export const ADMIN_TS_MOUNTTYPE_PAGE = 'traffic-sign-mount-type';
export const ADMIN_TS_SUPPORTTYPE_PAGE = 'traffic-sign-support-type';
export const ADMIN_TS_STATUS_PAGE = 'traffic-sign-status';
export const ADMIN_TS_CONDITION_PAGE = 'traffic-sign-condition';
export const ADMIN_TS_REFLCOATING_PAGE = 'traffic-sign-reflective-coating';
export const ADMIN_TS_REFLRATING_PAGE = 'traffic-sign-reflective-rating';
export const ADMIN_WO_REASON_PAGE = 'work-order-reason';
export const ADMIN_WO_WORKPERFORMED_PAGE = 'work-order-work-performed';
