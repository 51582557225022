import { Injectable } from '@angular/core';
import { loadModules } from 'esri-loader';
import { environment } from './../../../environments/environment';
import { ARC_GIS_URL } from '../constants/app.constants';

@Injectable({
  providedIn: 'root',
})
export class EsriModuleProvider {
  constructor() {}

  require(moduleNames: string[]): Promise<any[]> {
    return loadModules(moduleNames, {
      url: `${ARC_GIS_URL}/${environment.arcGisVersion}`,
    });
  }
}
