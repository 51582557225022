import { Component, Input, OnDestroy } from '@angular/core';
import { IDetailsFieldDef } from 'src/app/core/interfaces/definitions';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-details-table',
  templateUrl: './details-table.component.html',
  styleUrls: ['./details-table.component.scss'],
})
export class DetailsTableComponent implements OnDestroy {
  /**
   * Set the field definitions for the daata
   */
  @Input() fields: IDetailsFieldDef[];

  /**
   * Set and subcribe to the observable (i.e. API call) that contains the data to load in the table.
   * If the value is EMPTY, then this will assume there is no associated request.
   */
  @Input()
  set data(value: Observable<any>) {
    this.isLoading = value !== EMPTY;
    this.detailsObj = null;
    if (value) {
      // subscribe and set detailsObj to be the emission
      this.dataSubscription = value.pipe(tap(() => (this.isLoading = false))).subscribe((x) => (this.detailsObj = x));
    }
  }

  isLoading: boolean;
  detailsObj: any;
  private dataSubscription: Subscription;

  constructor() {
    this.detailsObj = null;
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  edit(field: IDetailsFieldDef): void {
    // do nothing for now
  }
}
