import { Directive, ElementRef, HostListener, Input, Renderer2, AfterViewInit } from '@angular/core';
import { StateService } from 'src/app/core/services/state.service';

@Directive({
  selector: '[appRowHeight]',
})
export class RowHeightDirective implements AfterViewInit {
  @Input() rowHeightRatio: number;
  @Input() cols: number;
  @Input() rowSpan: number;
  @Input() colSpan: number;
  private _domElement: any;

  @HostListener('window:resize', ['$event.target'])
  onResize(): void {
    const heightUnit = this._stateService.isMobile() ? 'vw' : 'vh';
    const widthPct = (100 / this.cols) * this.colSpan;
    this._renderer.setStyle(this._domElement, 'width', `${widthPct}%`);

    const height = (100 / this.cols) * this.rowHeightRatio * this.rowSpan;
    this._renderer.setStyle(this._domElement, 'height', `${height}${heightUnit}`);

    // todo: see how mat-grid-list implements the padding and margin for it's tiles?
    // currently this has some filling issues when a tile is tall and skinny
    // for example, on the default graph layout change the first tile to 2 rows 1 column and add a graph
  }

  constructor(private _renderer: Renderer2, private _stateService: StateService, _elementRef: ElementRef) {
    this._domElement = _elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    this.onResize();
  }
}
