import { Component, Input, HostBinding } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PictureDialogComponent } from '../../../../components/dialogs/picture-dialog/picture-dialog.component';
import { IPhoto } from 'src/app/core/interfaces/definitions/photo.definition';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent {
  @Input()
  set images(value: IPhoto[] | IPhoto) {
    if (value) {
      if (value instanceof Array) {
        this.displayImages = value.filter((x) => !!x);
        this.single = this.displayImages.length === 1;
      } else {
        this.single = true;
        this.displayImages = [value];
      }
    }
  }

  /**
   * Center the image if there's only one (for some reason this doesn't work with multiple images)
   */
  @HostBinding('style.justify-content')
  get justify(): string {
    return this.single ? 'center' : 'flex-start';
  }

  public displayImages: IPhoto[] = [];

  private single: boolean;

  constructor(private dialog: MatDialog) {}

  public openFullsize(index: string): void {
    this.dialog.open(PictureDialogComponent, {
      data: { images: this.displayImages, startingIndex: index },
    });
  }
}
