/**
 * Installed Traffic Sign Type Model Properties (as defined by the API)
 */
export const INSTALLED_TS = 'InstalledTrafficSign';
export const INSTALLED_TS_ID = 'Id';
export const INSTALLED_TS_DATE_TIME = 'InstallDateTime';
export const INSTALLED_TS_TS = 'TrafficSign';
export const INSTALLED_TS_TS_ID = 'TrafficSignId';
export const INSTALLED_TS_TYPE = 'TrafficSignType';
export const INSTALLED_TS_TYPE_ID = 'TrafficSignTypeId';
export const INSTALLED_TS_LOCATION = 'Location';
export const INSTALLED_TS_LOCATION_ID = 'LocationId';
export const INSTALLED_TS_BASE_TYPE = 'BaseType';
export const INSTALLED_TS_BASE_TYPE_ID = 'BaseTypeId';
export const INSTALLED_TS_SUPPORT_TYPE = 'SupportType';
export const INSTALLED_TS_SUPPORT_TYPE_ID = 'SupportTypeId';
export const INSTALLED_TS_MOUNT_TYPE = 'MountType';
export const INSTALLED_TS_MOUNT_TYPE_ID = 'MountTypeId';
export const INSTALLED_TS_STATUS = 'Status';
export const INSTALLED_TS_STATUS_ID = 'StatusId';
export const INSTALLED_TS_CONDITION = 'Condition';
export const INSTALLED_TS_CONDITION_ID = 'ConditionId';
export const INSTALLED_TS_FG_REFL_RATING = 'FGReflRating';
export const INSTALLED_TS_FG_REFL_RATING_ID = 'FGReflRatingId';
export const INSTALLED_TS_BG_REFL_RATING = 'BGReflRating';
export const INSTALLED_TS_BG_REFL_RATING_ID = 'BGReflRatingId';
export const INSTALLED_TS_INVENTORY_STATUS = 'InventoryStatus';
export const INSTALLED_TS_INVENTORY_STATUS_ID = 'InventoryStatusId';
export const INSTALLED_TS_OWNER = 'Owner';
export const INSTALLED_TS_OWNER_ID = 'OwnerId';
export const INSTALLED_TS_CUSTODIAN = 'Custodian';
export const INSTALLED_TS_CUSTODIAN_ID = 'CustodianId';
export const INSTALLED_TS_FACING_DIR = 'FacingDir';
export const INSTALLED_TS_FACING_DIR_ID = 'FacingDirId';
export const INSTALLED_TS_HAS_NEW_SUPPORT = 'HasNewSupport';
export const INSTALLED_TS_IS_ILLUMINATED = 'IsIlluminated';
export const INSTALLED_TS_HEIGHT = 'Height';
export const INSTALLED_TS_HEIGHT_UNIT = 'HeightUnit';
export const INSTALLED_TS_HEIGHT_UNIT_ID = 'HeightUnitId';
export const INSTALLED_TS_NOTES = 'Notes';
