/**
 * WorkOrderMaintenance Model Properties (as defined by the API)
 */
export const WOM = 'WorkOrderMaintenance';
export const WOM_ID = 'Id';
export const WOM_STATUS = 'Status';
export const WOM_STATUS_ID = 'StatusId';
export const WOM_CREATED_DATE_TIME = 'CreatedDateTime';
export const WOM_ISSUED_BY_USER = 'IssuedByUser';
export const WOM_ISSUED_BY_USER_ID = 'IssuedByUserId';
export const WOM_APPROVED_BY_USER = 'ApprovedByUser';
export const WOM_APPROVED_BY_USER_ID = 'ApprovedByUserId';
export const WOM_TYPE = 'Type';
export const WOM_TYPE_ID = 'TypeId';
export const WOM_REASON = 'Reason';
export const WOM_REASON_ID = 'ReasonId';
export const WOM_WORK_PERFORMED = 'WorkPerformed';
export const WOM_WORK_PERFORMED_ID = 'WorkPerformedId';
export const WOM_INSTALLED_TS = 'InstalledTrafficSign';
export const WOM_INSTALLED_TS_ID = 'InstalledTrafficSignId';
export const WOM_WOR = 'WorkOrderRequest';
export const WOM_WOR_ID = 'WorkOrderRequestId';
export const WOM_NOTES = 'Notes';
export const WOM_DURATION = 'Duration';
export const WOM_DURATION_UNIT = 'DurationUnit';
export const WOM_DURATION_UNIT_ID = 'DurationUnitId';
export const WOM_PHOTOS = 'Photos';
export const WOM_ASSIGNMENTS = 'Assignments';
export const WOM_REWORKS = 'Reworks';
