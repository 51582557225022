import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ARC_GIS_URL } from '../constants/app.constants';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor() {}

  getApiUrl(): string {
    return environment.urlAddress;
  }

  getAssetCityUrl(): string {
    return environment.assetCityUrl;
  }

  getDateTimeLocale(): string {
    return environment.dateTimeLocale;
  }

  getEsriStyleSheetUrl(theme: string = 'light'): string {
    return `${ARC_GIS_URL}/${environment.arcGisVersion}/esri/themes/${theme}/main.css`;
  }
}
