import { Component, OnInit, ViewChild, TemplateRef, EmbeddedViewRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, switchMap, catchError, take } from 'rxjs/operators';
import { AuthenticationService } from '../../core/services';
import { AppUserRepositoryService } from '../../core/services/repositories';
import { ITenant, IAppUser } from '../../core/interfaces/models';
import { EMPTY, Subject } from 'rxjs';
import { UtilsService } from 'src/app/core/services/utils.service';
import { IErrorDef } from 'src/app/core/interfaces/definitions/error.definition';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SNACKBAR_DISMISS_TIME } from 'src/app/core/constants/constants';

/**
 * Component for logging into the application
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmEmailPopup') popupTemplate: TemplateRef<any>;

  loginIconPath = 'assets/images/logos/asset-city-login-icon.png';

  /** Class Fields */
  clientForm: FormGroup;
  userLoginForm: FormGroup;
  emailLoginForm: FormGroup;
  isLoading: boolean;
  message: string;
  tenants: ITenant[];
  forgotPasswordView: boolean;
  emailLoginView: boolean;
  schema: string;
  popupRef: MatSnackBarRef<EmbeddedViewRef<any>>;
  private _returnUrl = 'dashboard';

  /**
   * Constructor
   */
  constructor(
    utils: UtilsService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _authService: AuthenticationService,
    private _appUserRepo: AppUserRepositoryService,
    private _router: Router,
    private _matSnackBar: MatSnackBar
  ) {
    this.message = '';
    this.isLoading = false;
    this.emailLoginView = false;

    this.forgotPasswordView = false;
    // Get return URL from route params or default to '/'. Remove any query params.
    this._route.queryParamMap.pipe(take(1)).subscribe((params: Params) => {
      if (params.has('returnUrl')) {
        const url: string = params.get('returnUrl');
        this._returnUrl = url.substring(1);
      }
      this.message = params.get('error');
    });
  }

  /**
   * Callback on init.
   */
  ngOnInit(): void {
    this.buildLoginPage();
    this.setFormLoading(true);
  }

  ngAfterViewInit(): void {
    this._authService
      .currentUser()
      .pipe(
        switchMap((user: IAppUser) => {
          if (!user) {
            return this._appUserRepo.getTenants();
          } else {
            this.returnToApp(user);
            return EMPTY;
          }
        }),
        finalize(() => this.setFormLoading(false))
      )
      .subscribe((tenants: ITenant[]) => {
        this.tenants = tenants;
      });
  }

  private setFormLoading(loading: boolean): void {
    this.isLoading = loading;
    if (this.isLoading) {
      this.clientForm.disable();
      this.userLoginForm.disable();
      this.emailLoginForm.disable();
    } else {
      this.clientForm.enable();
      this.userLoginForm.enable();
      this.emailLoginForm.enable();
    }
  }

  private buildLoginPage(): void {
    this.clientForm = this._formBuilder.group({
      tenantCtrl: ['', Validators.required],
    });

    this.userLoginForm = this._formBuilder.group({
      usernameCtrl: ['', Validators.required],
      passwordCtrl: ['', Validators.required],
    });

    this.emailLoginForm = this._formBuilder.group({
      passwordCtrl: ['', Validators.required],
      emailCtrl: ['', Validators.required],
    });
  }

  private returnToApp(user: IAppUser): Promise<boolean> {
    if (!!user && !user.emailConfirmed) {
      this.popupRef = this._matSnackBar.openFromTemplate(this.popupTemplate, {
        duration: SNACKBAR_DISMISS_TIME * 10,
      });
      this.popupRef
        .onAction()
        .pipe(switchMap(() => this._appUserRepo.sendConfirmationEmail()))
        .subscribe();
    }
    return this._router.navigate([this._returnUrl]);
  }

  toggleForgotPassword(): void {
    this.forgotPasswordView = !this.forgotPasswordView;
    this.message = '';
  }

  toggleLoginStyle(useEmailView: boolean = true): void {
    this.emailLoginView = useEmailView;
  }

  submitForgotPassword(email: string): void {
    this.setFormLoading(true);
    this._appUserRepo
      .forgotPassword(email)
      .pipe(finalize(() => this.setFormLoading(false)))
      .subscribe(
        () => {
          this.forgotPasswordView = false;
          this.message = 'Please check your email for a link to reset your password.';
        },
        (err: IErrorDef) => {
          this.message = err.message;
        }
      );
  }

  /**
   * Authenticate the user, on success navigate to the return url.
   */
  onSubmit(): void {
    // First ensure that the form is valid.
    if (this.isLoading || (this.userLoginForm.invalid && this.emailLoginForm.invalid)) {
      return;
    }

    this.setFormLoading(true);
    const tenantSchema = this.clientForm.get('tenantCtrl')?.value.schema;
    const username = this.userLoginForm.get('usernameCtrl')?.value;
    const email = this.emailLoginForm.get('emailCtrl')?.value;

    const password = this.emailLoginView
      ? this.emailLoginForm.get('passwordCtrl')?.value
      : this.userLoginForm.get('passwordCtrl')?.value;

    (this.emailLoginView
      ? this._authService.loginEmail(email, password)
      : this._authService.login(tenantSchema, username, password)
    )
      .pipe(
        catchError((err: IErrorDef) => {
          this.message = err.message;
          return EMPTY;
        }),
        switchMap((res: IAppUser) => this.returnToApp(res)),
        finalize(() => this.setFormLoading(false))
      )
      .subscribe();
  }
}
