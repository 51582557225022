import { Injectable } from '@angular/core';
import { IAsset } from '../../interfaces/models';
import { RepositoryBaseService } from './repository-base.service';
import { Observable } from 'rxjs';

// API endpoint constants
export const ASSET_URI = 'asset';
export const FILE_UPLOAD_CONTAINER = 'fileupload';

@Injectable({
  providedIn: 'root',
})
export class AssetRepositoryService {
  constructor(private repo: RepositoryBaseService) {}

  getUploadAssetUrl(): string {
    return `${this.repo.getEvnUrl()}/${ASSET_URI}`;
  }

  downloadAsset(asset: IAsset): Observable<any> {
    return this.repo.getData(`${ASSET_URI}/download/blob?imageFilepath=${asset.inputFilepath}`, null, true, false, {
      responseType: 'blob',
    });
  }

  downloadAssetByContainerAndName(container: string, name: string): Observable<Blob> {
    if (!container || !name) {
      return null;
    }
    return this.repo.getData(`${ASSET_URI}/download/${container}/${name}`, null, true, false, { responseType: 'blob' });
  }

  downloadAssetByUrl(url: string): Observable<Blob> {
    if (!url) {
      return null;
    }

    const last: number = url.lastIndexOf('/');
    const secondLast: number = url.substring(0, last).lastIndexOf('/');
    if (last < 0 || secondLast < 0) {
      return null;
    } else {
      const name: string = url.substring(last + 1);
      const container: string = url.substring(secondLast + 1, last);
      return this.downloadAssetByContainerAndName(container, name);
    }
  }

  /**
   * Upload assets to server from FormData object.
   * @param formData (FormData): List of asset files.
   */
  uploadAssetFromFormData(formData: FormData): Observable<any> {
    return this.repo.create(`${ASSET_URI}/upload`, formData, null);
  }

  /**
   * Upload asset to server from base64 encoded data url.
   * @param data (string): base64 encoded string representing the asset.
   */
  uploadAssetFromStringData(dataUrl: string, filename: string): Observable<any> {
    const asset: IAsset = { data: dataUrl };
    return this.repo.create(`${ASSET_URI}/upload/${FILE_UPLOAD_CONTAINER}/${filename}`, asset, null);
  }
}
