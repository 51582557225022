import { Component } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AppUserRepositoryService } from 'src/app/core/services/repositories';
import { IPasswordData } from 'src/app/core/interfaces';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  public message: string;
  public isLoading: boolean;
  public submitted: boolean;
  public hide: boolean;

  private userId: string;
  private token: string;

  constructor(private _userRepo: AppUserRepositoryService, private _router: Router, activatedRoute: ActivatedRoute) {
    this.hide = false;
    activatedRoute.queryParamMap.pipe(take(1)).subscribe((value: Params) => {
      this.userId = value.get('id');
      this.token = value.get('token');
      if (!this.userId || !this.token) {
        this.message = 'We are unable to reset your password at this time.';
        this.hide = true;
      }
    });
  }

  public resetPassword(newPassword: IPasswordData) {
    if (this.userId && this.token) {
      newPassword.token = this.token;
      this.isLoading = true;
      this._userRepo.resetPassword(this.userId, newPassword).subscribe(() => {
        this.isLoading = false;
        this.submitted = true;
        this.message = 'Your password has successfully been reset.';
      });
    }
  }

  public login() {
    this._router.navigate(['/login']);
  }
}
