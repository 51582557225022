import { Component, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  Router,
  Event as RouterEvent,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  Scroll,
} from '@angular/router';
import { EnvironmentService } from './core/services/environment.service';
import { Title } from '@angular/platform-browser';
import { APP_TITLE } from './core/constants/app.constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { StateService } from './core/services/state.service';

/**
 * Main component for the application
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * View Component: sidenav
   */
  sideNavToggle: Observable<boolean>;
  notificationSidenavToggle: Observable<boolean>;
  /** Class fields */
  componentLoaded: boolean;
  isNavigating: BehaviorSubject<boolean>;
  isMobile: boolean;
  isTablet: boolean;

  @HostListener('window:resize', ['$event'])
  resize(): void {
    this.isMobile = this._stateService.isMobile();
    this.isTablet = this._stateService.isTablet();
  }

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _environment: EnvironmentService,
    private _stateService: StateService,
    private _titleService: Title,
    _router: Router
  ) {
    this.resize();
    this._titleService.setTitle(APP_TITLE);
    this._loadStyle(_environment.getEsriStyleSheetUrl());
    this.componentLoaded = false;
    this.isNavigating = new BehaviorSubject<boolean>(true);

    // it's okay to never unsubscribe from this, if the AppComponent gets ngOnDestroy'ed the web page is being closed
    // todo: this generates a ChangedAfterChecked error only when you visit the root path on first load
    _router.events.subscribe((event: RouterEvent) =>
      this.isNavigating.next(
        !(
          event instanceof NavigationEnd ||
          event instanceof NavigationError ||
          event instanceof NavigationCancel ||
          event instanceof Scroll
        )
      )
    );
    this.sideNavToggle = this._stateService.sideNavToggled$;
    this.notificationSidenavToggle = this._stateService.notificationMenuToggled$;
  }

  toggleSideNav(): void {
    this._stateService.toggleSidenav();
  }

  toggleNotficationMenu(): void {
    this._stateService.toggleNotificationMenu();
  }

  closeNotficationMenu(): void {
    // clicking away from the sidenav to dismiss it doesn't toggle the open flag
    // so we have to manually toggle it
    if (this._stateService.notificationMenuToggled$.value) {
      this._stateService.notificationMenuToggled$.next(false);
    }
  }

  /**
   * Callback when a component has been added to the navigation content.
   * @param event
   */
  componentAdded(event): void {
    if (event) {
      this.componentLoaded = true;
    }
  }

  navigateToAssetCity(): void {
    window.location.href = this._environment.getAssetCityUrl();
  }

  /**
   * Dynamically append html element that links a style sheet by url.
   * @param styleUrl : the url to the style sheet
   */
  private _loadStyle(styleUrl: string): void {
    const head = this._document.getElementsByTagName('head')[0];
    const themeLink = this._document.getElementById('esri-theme') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = styleUrl;
    } else {
      const style = this._document.createElement('link');
      style.id = 'esri-theme';
      style.rel = 'stylesheet';
      style.href = `${styleUrl}`;
      head.appendChild(style);
    }
  }
}
