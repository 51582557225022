import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IEntity, IWorkOrderRequest, IWorkOrderReason, IAppUser } from '../../../core/interfaces/models';
import { ValidateDropdownList } from './../../../shared/validators';
import { DateTimeService } from 'src/app/core/services/datetime.service';
import * as moment from 'moment';

export interface IWorkOrderRequestCreateData {
  request: IWorkOrderRequest;
  reasons: IWorkOrderReason[];
  supervisors: IAppUser[];
}

@Component({
  selector: 'app-work-order-request-edit-dialog',
  templateUrl: './work-order-request-edit-dialog.component.html',
  styleUrls: ['./work-order-request-edit-dialog.component.css'],
})
export class WorkOrderRequestEditDialogComponent implements OnInit {
  public form: FormGroup;
  public request: IWorkOrderRequest;
  public reasons: IWorkOrderReason[] = [];
  public supervisorUsers: IAppUser[] = [];
  public minDate: moment.Moment;

  constructor(
    private _datetimeService: DateTimeService,
    public dialogRef: MatDialogRef<IWorkOrderRequestCreateData>,
    @Inject(MAT_DIALOG_DATA) public data: IWorkOrderRequestCreateData
  ) {
    this.request = data.request;
    this.reasons = data.reasons;
    this.supervisorUsers = data.supervisors;
    this.minDate = this.request.dueDateTime
      ? this._datetimeService.toMoment(this.request.dueDateTime)
      : this._datetimeService.toMoment('0');
  }

  ngOnInit() {
    this.form = new FormGroup({
      descCtrl: new FormControl(this.request.description, [Validators.maxLength(256)]),
      notesCtrl: new FormControl(this.request.notes, [Validators.maxLength(512)]),
      installDateCtrl: new FormControl(this.request.dueDateTime),
      assignedToCtrl: new FormControl(this.request.supervisorUser, [
        Validators.required,
        ValidateDropdownList(this.supervisorUsers),
      ]),
      reasonCtrl: new FormControl(this.request.reason, [Validators.required, ValidateDropdownList(this.reasons)]),
      reasonDescCtrl: new FormControl(this.request.reasonDescription, [Validators.maxLength(64)]),
      reasonDateCtrl: new FormControl(this.request.reasonDateTime),
    });
  }

  public onSubmit() {
    if (this.form.valid) {
      this.request.description = this.form.get('descCtrl').value;
      this.request.notes = this.form.get('notesCtrl').value;
      this.request.dueDateTime = this.form.get('installDateCtrl').value;
      this.request.reasonDateTime = this.form.get('reasonDateCtrl').value;
      this.request.reasonDescription = this.form.get('reasonDescCtrl').value;
      this.request.reasonId = this.form.get('reasonCtrl').value.id;
      this.request.reason = this.form.get('reasonCtrl').value;
      this.request.supervisorUserId = this.form.get('assignedToCtrl').value.id;
      this.request.supervisorUser = this.form.get('assignedToCtrl').value;

      this.dialogRef.close(this.request);
    }
  }

  public onNoClick(): void {
    this.onCancel();
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public displayEntity(entity?: IEntity): string | undefined {
    return entity ? entity.name : undefined;
  }

  public displayUser(user: IAppUser): string | undefined {
    return user ? user.displayName : undefined;
  }
}
