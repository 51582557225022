import { Injectable } from '@angular/core';
import { ITableColumn } from '../interfaces/definitions';
import { DownloadService } from './download.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class CsvExporterService {
  constructor(private _fileDownload: DownloadService) {}

  /**
   * Convert a data souce table into a CSV file and download it.
   * @param columns The column definitions for the table this data came from
   * @param rows The objects to be exported
   */
  downloadAsCsv(
    columns: ITableColumn<any>[],
    rows: any[],
    filename: string = 'export-' + Date.now().toString() + '.csv'
  ): void {
    if (!!columns.length || !!rows.length) {
      return;
    }

    // use the column display function to convert the object
    let tableFormatArr = rows.map((row: string[]) =>
      columns.map((col: ITableColumn<any>) => '"' + UtilsService.displayData(row, col.property) + '"')
    );

    // filter the image fields from the header and rows
    const columnHeaders = columns
      .map((col: ITableColumn<any>) => col.name)
      .filter((col: string) => col && col.toLowerCase() !== 'image');

    tableFormatArr = tableFormatArr.map((row: string[]) =>
      row.filter((val: string) => !val.toLowerCase().includes('image') && !val.toLowerCase().includes('loading'))
    );

    // add the header row to the beginning
    tableFormatArr.unshift(columnHeaders);

    // join the row entries by comma and the entire rows by newline
    const csvFile = tableFormatArr.map((row: string[]) => row.join(',')).join('\n');

    const blob = new Blob([csvFile], { type: 'text/csv' });
    this._fileDownload.downloadLocalBlob(blob, filename);
  }
}
