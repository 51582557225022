import { Component, Output, EventEmitter, Input } from '@angular/core';
import { IActionItem } from 'src/app/core/interfaces/definitions/action-item.definition';

/**
 * Displays a list of actions in a popup menu.
 * The component that is a child of this will be used as the menu trigger button.
 *
 * Position Y and X determine where the menu will popup relative to the trigger compnent.
 */
@Component({
  selector: 'app-actions-list',
  templateUrl: `./actions-list.component.html`,
  styleUrls: ['./actions-list.component.css'],
})
export class ActionsListComponent {
  @Input() actionsList: Array<Array<IActionItem>>;
  @Input() header: string;
  @Input() positionY: 'above' | 'below' = 'below';
  @Input() positionX: 'before' | 'after' = 'before';
  @Output() actionSelected = new EventEmitter<IActionItem>();

  constructor() {}

  public onSubmit(action: any) {
    this.actionSelected.emit(action);
  }
}
