import { IModule } from 'src/app/core/interfaces/definitions/module-route.definition';
import { APP_ROLE_STAFF, APP_ROLES_ADMIN, APP_ROLES_ELEVATED } from 'src/app/core/services/repositories';

// TODO extend this to define full hierarchy or modules and routes
export const signsRoutes: IModule = {
  name: 'Signs',
  url: 'signs',
  icon: 'assets/images/icons/signs-W.svg',
  subRoutes: [
    {
      url: 'work-order-request',
      name: 'Work Order Requests',
      icon: 'assets/images/icons/work-order-requests.svg',
      subRoutes: [
        {
          url: 'create',
          name: 'Create',
          matIcon: 'add',
          perm: APP_ROLES_ADMIN,
        },
      ],
    },
    {
      url: 'work-order-maintenance',
      name: 'Work Order Maintenance',
      icon: 'assets/images/icons/work-order-maintenance.svg',
      subRoutes: [
        {
          url: 'form',
          name: 'Create',
          matIcon: 'add',
          perm: [APP_ROLE_STAFF],
        },
      ],
    },
    {
      url: 'installed-traffic-sign',
      name: 'Installed Traffic Signs',
      icon: 'assets/images/icons/installed-traffic-sign.svg',
    },
    {
      url: 'admin',
      name: 'Manage',
      matIcon: 'lock',
      perm: APP_ROLES_ADMIN,
    },
  ],
};
