import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { INotificationMessage } from '../../../core/interfaces/models';
import { fromEvent, merge, interval, EMPTY } from 'rxjs';
import { POPUP_DISMISS_TIME } from '../../../core/constants/constants';
import { startWith, mapTo, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss'],
})
export class InformationDialogComponent implements OnInit {
  private panelElement = document.getElementsByClassName('notification-panel');
  private pause = fromEvent(this.panelElement, 'mouseover').pipe(mapTo(false));
  private resume = fromEvent(this.panelElement, 'mouseout').pipe(mapTo(true));
  private timer = interval(POPUP_DISMISS_TIME);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: INotificationMessage,
    private dialogRef: MatDialogRef<InformationDialogComponent>
  ) {}

  ngOnInit(): void {
    // dismiss notification after a specified amount of time, pause the timer if the user hovers over it.
    const timerSubscription = merge(this.pause, this.resume)
      .pipe(
        // start off with the timer running
        startWith(true),
        // if the user is not hovering over the card activate the timer and vice versa
        switchMap((active) => (active ? this.timer : EMPTY))
      )
      .subscribe(() => {
        this.dismissCard();
        timerSubscription.unsubscribe();
      });
  }

  dismissCard(): void {
    this.dialogRef.close();
  }
}
