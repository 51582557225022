import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';

// Datetime formatting constants, uses locale formatting
export const defaultDatetimeFormat = 'lll';
export const shortDateFormat = 'L';
export const mediumDateFormat = 'll';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  constructor() {
    moment.tz.setDefault(moment.tz.guess());
  }

  isDate(value: any): boolean {
    return typeof value !== 'number' && moment(value).isValid();
  }

  /**
   * Convert a datetime from the browser's local timezone (determined by moment.tz.guess()) into utc
   * @param datetime The datestring or momentjs object to convert.
   */
  convertLocalToUtc(datetime: string | moment.Moment): moment.Moment {
    return datetime ? moment(datetime).utc() : undefined;
  }

  /**
   * Convert a datetime from utc into the browser's local timezone (determined by moment.tz.guess())
   * @param datetime The datestring or momentjs object to convert.
   */
  convertUtcToLocal(datetime: string | moment.Moment): moment.Moment {
    return datetime ? moment.utc(datetime).local() : undefined;
  }

  /**
   * Format a momentjs object into a friendly string determined by the format string.
   * @param datetime The momentjs object to convert.
   * @param format The formating options for displaying the date.
   * @see https://momentjs.com/docs/#/displaying/format/
   */
  formatDatetime(datetime: moment.Moment, format: string = defaultDatetimeFormat): string {
    return datetime ? datetime.format(format) : '';
  }

  /**
   * From the provided datetime, get the start of the day and return the formatted string
   * @param datetime The datestring or momentjs object to convert.
   * @param format The formating options for displaying the date.
   */
  startOfDay(datetime: string | moment.Moment, format: string = defaultDatetimeFormat): string {
    if (datetime) {
      return moment.utc(datetime).startOf('day').format(format);
    } else {
      return null;
    }
  }

  /**
   * From the provided datetime, get the end of the day and return the formatted string
   * @param datetime The datestring or momentjs object to convert.
   * @param format The formating options for displaying the date.
   */
  endOfDay(datetime: string | moment.Moment, format: string = defaultDatetimeFormat): string {
    if (datetime) {
      return moment.utc(datetime).endOf('day').format(format);
    } else {
      return null;
    }
  }

  /**
   * From the provided datetime string, convert it into a momentjs object.
   * @param datetime The datestring or momentjs object to convert.
   * @param isLocal If true, the provided date is in local time else it's in utc time
   */
  toMoment(datetime: string | moment.Moment, isLocal?: boolean): moment.Moment {
    return isLocal ? moment(datetime) : moment.utc(datetime);
  }

  /**
   * Get today's date as momentjs object
   * @param useLocal If true, the returned date is in local time else it's in utc time
   */
  today(useLocal?: boolean): moment.Moment {
    return useLocal ? moment() : moment.utc();
  }

  /**
   * Convenience method to get the previous month.
   */
  lastMonth(): moment.Moment {
    return this.today().add(-1, 'month');
  }

  dateRangeToStringList(startDate: moment.Moment, endDate: moment.Moment): string[] {
    if (startDate && endDate) {
      startDate = this.toMoment(startDate);
      endDate = this.toMoment(endDate);

      if (startDate.isSame(endDate, 'day')) {
        const dateStr = 'On ' + this.startOfDay(startDate, 'L');
        return [dateStr];
      } else {
        const start = 'From ' + this.startOfDay(startDate, 'L');
        const end = ' To ' + this.endOfDay(endDate, 'L');
        return [start, end];
      }
    } else if (startDate) {
      const start = 'Start ' + this.startOfDay(startDate, 'L');
      return [start];
    } else if (endDate) {
      const end = 'End ' + this.endOfDay(endDate, 'L');
      return [end];
    }
  }
}
