import { BaseFormBuilder } from 'src/app/shared/components/forms/builder/base-form-builder';
import { IFormBuilderDescriptor } from 'src/app/shared/components/forms/builder/form-builder.definition';
import { Validators } from '@angular/forms';
import { UtilsService } from 'src/app/core/services/utils.service';
import { AssetService } from 'src/app/core/services';
import * as fbConst from 'src/app/shared/components/forms/builder/form-builder.constants';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class ReviewMaintenanceFormBuilder extends BaseFormBuilder {
  /**
   * Descriptor used to build the Work Order Request create form.
   */
  private _buildDescriptor: IFormBuilderDescriptor[] = [
    {
      /** First Form Group */
      name: 'Review Maintenance',
      layout: [
        [
          {
            id: 'approveActionCtrl',
            type: fbConst.CTRL_TYPE_BOOL,
            placeholder: 'Approve?',
            updateProperties: [['approveAction', null]],
          },
          {
            id: 'rejectNoteCtrl',
            type: fbConst.CTRL_TYPE_TEXT,
            placeholder: 'Rejection Note',
            validators: [Validators.required, Validators.maxLength(256)],

            updateProperties: [['rejectNote', null]],
          },
        ],
      ],
    },
  ];

  /**
   * Constructor
   *
   * @param _choicesService : service that provides the data for form drop down lists.
   * @param _utils : service that provides utility functions.
   * @param _datetimeService : service that provides datetime functions.
   */
  constructor(_utils: UtilsService, _assetService: AssetService) {
    super(_utils, _assetService);
    this.descriptor = this._buildDescriptor;
  }

  build(): void {
    super.build();
    this.valueChanges('approveActionCtrl').subscribe((res: boolean) => {
      this.setEnabled('rejectNoteCtrl', !res);
    });
  }
}
