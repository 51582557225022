import { IFilter } from 'src/app/core/interfaces/definitions/filter.definition';
import { BehaviorSubject, of } from 'rxjs';
import { FilterChoiceService } from 'src/app/core/services/filter-choices.service';
import { IQueryFilter, QueryFilterExpression } from 'src/app/core/interfaces/query/filters/query-filter';
import { QueryFilterNot } from 'src/app/core/interfaces/query/filters/query-filter-not';
import { Q_OP_CON, Q_OP_IN } from 'src/app/core/constants/query.constants';

export const notApprovedFilter: IQueryFilter = new QueryFilterNot(
  new QueryFilterExpression('Status', Q_OP_CON, 'Approved'),
  'Incomplete'
);
export const inProgressFilter: IQueryFilter = new QueryFilterExpression(
  'Status',
  Q_OP_IN,
  'Maintenance In Progress, Maintenance Rework',
  'In Progress'
);
export const submittedFilter: IQueryFilter = new QueryFilterExpression('Status', Q_OP_CON, 'Submitted', 'Submitted');
export const approvedFilter: IQueryFilter = new QueryFilterExpression('Status', Q_OP_CON, 'Approved', 'Approved');

export class WorkOrderRequestFilters extends Array<IFilter> {
  /**
   * The Filter Definitions for Work Order Requests
   */
  public filters: IFilter[] = [
    {
      display: 'Requests',
      propertyName: 'Status',
      type: 'toggle',
      multi: true,
      choices: of([notApprovedFilter, inProgressFilter, submittedFilter, approvedFilter]),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'No.',
      propertyName: 'Id',
      type: 'text',
      multi: false,
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Created Date',
      propertyName: 'CreatedDateTime',
      type: 'datetime',
      multi: false,
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Due Date',
      propertyName: 'DueDateTime',
      type: 'datetime',
      multi: false,
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Road',
      propertyName: 'Road',
      type: 'text',
      multi: false,
      filter: new BehaviorSubject(null),
    },
    {
      display: 'From',
      propertyName: 'RoadBack',
      type: 'text',
      multi: false,
      filter: new BehaviorSubject(null),
    },
    {
      display: 'To',
      propertyName: 'RoadAhead',
      type: 'text',
      multi: false,
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Supervisor',
      propertyName: 'SupervisorUser.DisplayName',
      type: 'dropdown',
      multi: true,
      choices: this._baseFilters.getSupervisorFilters('SupervisorUser.DisplayName'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Field Staff',
      propertyName: 'StaffUser.DisplayName',
      type: 'dropdown',
      multi: true,
      choices: this._baseFilters.getUserFilters('StaffUser.DisplayName'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Prepared By',
      propertyName: 'IssuedByUser.DisplayName',
      type: 'dropdown',
      multi: true,
      choices: this._baseFilters.getUserFilters('IssuedByUser.DisplayName'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Type',
      propertyName: 'Type.Name',
      type: 'dropdown',
      multi: true,
      choices: this._baseFilters.getRequestWorkTypeFilters('Type.Name'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Reason',
      propertyName: 'Reason.Name',
      type: 'dropdown',
      multi: false,
      choices: this._baseFilters.getRequestWorkReasonFilters('Reason.Name'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Traffic Sign',
      propertyName: 'TrafficSignType.Name',
      type: 'dropdown',
      multi: false,
      choices: this._baseFilters.getSignTypeFilters('TrafficSignType.Name'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Description',
      propertyName: 'Description',
      type: 'text',
      multi: false,
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Reason Description',
      propertyName: 'ReasonDescription',
      type: 'text',
      multi: false,
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Notes',
      propertyName: 'Notes',
      type: 'text',
      multi: false,
      filter: new BehaviorSubject(null),
    },
  ];

  constructor(private _baseFilters: FilterChoiceService) {
    super();
    this.push(...this.filters);
  }
}
