/**
 * Module types enum
 */
export enum ModuleType {
  invalid = -1,
  dashboard = 0,
  settings = 1,
  signs = 2,
  sentinel = 3,
}

/**
 * Constants for module names
 */
const dashboardModule = 'Dashboard';
const signsModule = 'Signs';
const settingsModule = 'Settings';
// Sub Module of signs
const adminModule = 'Admin';

/**
 * Class containing information and utility methods for handling navigating through modules.
 */
export class Module {
  static getAdminRoute(): string {
    return adminModule.toLocaleLowerCase();
  }

  // Static method to get a display string for the module type.
  static moduleTypeToString(module: ModuleType): string {
    switch (module) {
      case ModuleType.dashboard:
        return dashboardModule;
      case ModuleType.settings:
        return settingsModule;
      case ModuleType.signs:
        return signsModule;
      default:
        return '';
    }
  }

  // Static method to get the route for the module type.
  static moduleTypeRoute(module: ModuleType): string {
    switch (module) {
      case ModuleType.dashboard:
        return dashboardModule.toLocaleLowerCase();
      case ModuleType.settings:
        return settingsModule.toLocaleLowerCase();
      case ModuleType.signs:
        return signsModule.toLocaleLowerCase();
      default:
        return '';
    }
  }

  static moduleTypeFromString(name: string): ModuleType {
    switch (name) {
      case dashboardModule.toLocaleLowerCase():
        return ModuleType.dashboard;
      case settingsModule.toLocaleLowerCase():
        return ModuleType.settings;
      case signsModule.toLocaleLowerCase():
        return ModuleType.signs;
      default:
        return ModuleType.invalid;
    }
  }
}
