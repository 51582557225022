import { Component, Input } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { BaseFormControl } from '../../base-form-control';

@Component({
  selector: 'app-gps-input-control',
  templateUrl: './gps-input-control.component.html',
  styleUrls: ['./gps-input-control.component.scss'],
})
export class GpsInputControlComponent {
  @Input()
  control: BaseFormControl;

  coordinates: Observable<[number, number]>;

  constructor() {
    this.coordinates = new Observable((sub: Subscriber<[number, number]>) => {
      navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          sub.next([position.coords.latitude, position.coords.longitude]);
          sub.complete();
        },
        (err) => sub.error(err)
      );
    });
  }

  setCoordinates(): void {
    this.coordinates.subscribe((res: [number, number]) => this.control.form.setValue(res));
  }
}
