import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { INotificationMessage } from '../interfaces/models';
import { Platform } from '@angular/cdk/platform';

/**
 * Provides the current state of the application across different routes
 * and paths.
 */
@Injectable({
  providedIn: 'root',
})
export class StateService {
  sideNavToggled$ = new BehaviorSubject<boolean>(false);
  notificationMenuToggled$ = new BehaviorSubject<boolean>(false);
  notifications$ = new BehaviorSubject<INotificationMessage[]>([]);

  constructor(private platform: Platform) {}

  /**
   * Toggle the Side Navigation Menu to open or close.
   */
  toggleSidenav(): void {
    this.sideNavToggled$.next(!this.sideNavToggled$.value);
  }

  /**
   * Toggle the Notification Menu to open or close.
   */
  toggleNotificationMenu(): void {
    this.notificationMenuToggled$.next(!this.notificationMenuToggled$.value);
  }

  /**
   * Updates the cached notification history and updates any listeners to the new value.
   * @param msg The current notification history to emit to subcribers.
   */
  refreshNotifications(msg: INotificationMessage[]): void {
    this.notifications$.next(msg);
  }

  /**
   * Returns true if the current device is a mobile device that is not a tablt.
   */
  isMobile(): boolean {
    return window.innerWidth < 768;
  }

  /**
   * Returns true if the current device is an tablet.
   */
  isTablet(): boolean {
    return window.innerWidth >= 768 && window.innerWidth < 1024;
  }
}
