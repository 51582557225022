import { Component, OnInit, Input } from '@angular/core';
import { BaseFormControl } from '../../base-form-control';
import { ASSET_NAME, IAssetFileData } from 'src/app/core/services/asset.service';

@Component({
  selector: 'app-file-input-control',
  templateUrl: './file-input-control.component.html',
  styleUrls: ['./file-input-control.component.scss'],
})
export class FileInputControlComponent implements OnInit {
  @Input() fileInputName: string;
  @Input()
  set control(value: BaseFormControl) {
    this.ctrl = value;
  }
  public ctrl: BaseFormControl;
  public label: string;
  public notePlaceholder: string;

  constructor() {
    // This will be passed to the api upload call, which expects a certain name. Should always
    // be 'asset', unless the api input parameters change.
    this.fileInputName = ASSET_NAME;
    this.label = 'Choose File ...';
    this.notePlaceholder = 'Add Note ...';
  }

  ngOnInit() {}

  public addFiles(files: any): void {
    const fileList: FileList = files.target.files;
    if (!!fileList && fileList.length > 0) {
      if (!this.ctrl.form.value || !this.ctrl.multiple) {
        this.ctrl.form.setValue([]);
      }
      const numFiles = this.ctrl.multiple ? fileList.length : 1;
      for (let i = 0; i < numFiles; i++) {
        const data = fileList[i];
        const filename = data.name;
        this.ctrl.form.value.push({
          data,
          filename,
          dataType: 'file',
          fileInputName: this.fileInputName,
          imgData: null,
        });
      }
    }
    this.ctrl.form.updateValueAndValidity();
  }

  public setNote(event: any, fileData: IAssetFileData, leaveFocus: boolean = false): void {
    if (fileData) {
      fileData.note = event.target.value;
      if (leaveFocus) {
        event.target.blur();
      }
    }
  }

  public removeFileData(fileData: IAssetFileData): void {
    const index = this.ctrl.form.value.findIndex(
      (item: IAssetFileData) =>
        item.data === fileData.data &&
        item.fileInputName === item.fileInputName &&
        item.dataType === fileData.dataType &&
        item.filename === fileData.filename
    );
    if (index >= 0) {
      this.ctrl.form.value.splice(index, 1);
    }
    this.ctrl.form.updateValueAndValidity();
  }
}
