import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-password-error-display',
  templateUrl: './password-error-display.component.html',
  styleUrls: ['./password-error-display.component.css']
})
export class PasswordErrorDisplayComponent implements OnInit {
  @Input() form: FormGroup;

  public passwordCtrl: AbstractControl;

  constructor() {}

  ngOnInit() {
    this.passwordCtrl = this.form.get('passwordCtrl');
  }
}
