import { Injectable } from '@angular/core';
import { RepositoryBaseService } from './repository-base.service';
import { InvalidRoadFix } from '../../../core/interfaces/models';
import { IQueryParam, DEFAULT_QUERY_PARAM } from '../../interfaces/query/query';

// API endpoint constants
export const ROAD_URI = 'road';
export const ROAD_VERIFY_URI = 'roadverification';

@Injectable({
  providedIn: 'root',
})
export class RoadRepositoryService {
  constructor(private repo: RepositoryBaseService) {}

  public getRoads() {
    return this.repo.getData(ROAD_URI, null, true /*cachable*/);
  }

  public getArcGISRoadSegmentsByRoad() {
    return this.repo.getData(`${ROAD_URI}/segmentsbyroad`, null, true /*cachable*/);
  }

  public getArcGISRoadSegments() {
    return this.repo.getData(`${ROAD_URI}/segment`, null, true /*cachable*/);
  }

  public getInvalidRoadReports(query: IQueryParam = null) {
    // Won't return an IQueryResult without a query string
    return this.repo.getData(`${ROAD_VERIFY_URI}`, query);
  }

  public fixInvalidRoadReports(id: number, data: InvalidRoadFix) {
    return this.repo.create(`${ROAD_VERIFY_URI}/fixinvalid/${id}`, data);
  }
}
