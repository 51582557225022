import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { QueryService } from './query.service';
import { QueryFilterExpression } from '../interfaces/query/filters/query-filter';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { Q_OP_NEQ, Q_OP_EQ } from '../constants/query.constants';
import { WOR_TYPE_NAME } from '../constants/model-properties/work-order-type.properties';
import { WOR_TYPE } from '../constants/model-properties/work-order-request.properties';
import { IAppUser, IWorkOrderReason, IAppRole } from '../interfaces/models';
import {
  WorkOrderRepositoryService,
  TrafficSignRepositoryService,
  AppUserRepositoryService,
  SharedRepositoryService,
  RoadRepositoryService,
  APP_ROLE_SUPERVISOR,
  APP_ROLE_STAFF,
  UNIT_TYPE_TIME,
  UNIT_TYPE_LEN,
  APP_ROLE_RETIRED,
} from './repositories';

@Injectable({
  providedIn: 'root',
})
export class BaseChoiceService {
  /** Observables for choices list of filters */
  private direction$: Observable<any> = null;
  private installedSigns$: Observable<any> = null;
  private reasons$: Observable<any> = null;
  private types$: Observable<any> = null;
  private signTypes$: Observable<any> = null;
  private userRoles$: Observable<any> = null;
  private users$: Observable<any> = null;
  private supervisors$: Observable<any> = null;
  private staff$: Observable<any> = null;
  private roads$: Observable<any> = null;
  private workPerformed$: Observable<any> = null;
  private workStatus$: Observable<any> = null;
  private baseType$: Observable<any> = null;
  private mountType$: Observable<any> = null;
  private supportType$: Observable<any> = null;
  private signStatus$: Observable<any> = null;
  private condition$: Observable<any> = null;
  private category$: Observable<any> = null;
  private faceMaterial$: Observable<any> = null;
  private refCoating$: Observable<any> = null;
  private refRating$: Observable<any> = null;
  private owner$: Observable<any> = null;
  private ownerTypes$: Observable<any> = null;
  private inventoryStatus$: Observable<any> = null;
  private locationType$: Observable<any> = null;
  private lengthUnit$: Observable<any> = null;
  private timeUnit$: Observable<any> = null;
  /** Refresh observable to update observable choices */
  private refresh$ = new Subject<void>();

  constructor(
    private _queryService: QueryService,
    private _workOrderRepo: WorkOrderRepositoryService,
    private _trafficSignRepo: TrafficSignRepositoryService,
    private _userRepo: AppUserRepositoryService,
    private _sharedRepo: SharedRepositoryService,
    private _roadRepo: RoadRepositoryService
  ) {}

  public updateChoices() {
    this.refresh$.next();
    this.direction$ = null;
    this.installedSigns$ = null;
    this.reasons$ = null;
    this.types$ = null;
    this.signTypes$ = null;
    this.userRoles$ = null;
    this.users$ = null;
    this.supervisors$ = null;
    this.staff$ = null;
    this.roads$ = null;
    this.workPerformed$ = null;
    this.workStatus$ = null;
    this.baseType$ = null;
    this.mountType$ = null;
    this.supportType$ = null;
    this.signStatus$ = null;
    this.condition$ = null;
    this.category$ = null;
    this.faceMaterial$ = null;
    this.refCoating$ = null;
    this.refRating$ = null;
    this.owner$ = null;
    this.ownerTypes$ = null;
    this.inventoryStatus$ = null;
    this.locationType$ = null;
    this.lengthUnit$ = null;
    this.timeUnit$ = null;
  }

  public getDirectionChoices(): Observable<any> {
    if (!this.direction$) {
      this.direction$ = this._sharedRepo.getDirections().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.direction$;
  }

  public getInstalledSignsChoices(fields: string[] = null): Observable<any> {
    if (!this.installedSigns$) {
      const query = this._queryService.toQuery(null, null, null, fields);
      this.installedSigns$ = this._trafficSignRepo
        .getInstalledSigns(query)
        .pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.installedSigns$;
  }

  public getReasonChoices() {
    if (!this.reasons$) {
      const reasonQuery = this._queryService.toQuery(null, null, null, ['*']);
      this.reasons$ = this._workOrderRepo.getReasons(reasonQuery, true).pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.reasons$;
  }

  public getTypeChoices() {
    if (!this.types$) {
      const typeQuery = this._queryService.toQuery(null, null, null, null);
      this.types$ = this._workOrderRepo.getTypes(typeQuery, true).pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.types$;
  }

  public getSignChoices(fields: string[] = null) {
    if (!this.signTypes$) {
      const query = this._queryService.toQuery(null, null, null, fields);
      this.signTypes$ = this._trafficSignRepo.getSignTypes(query).pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.signTypes$;
  }

  public getUsersChoices() {
    if (!this.users$) {
      this.users$ = this._userRepo.getAppUsers().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.users$;
  }

  public getSupervisorUsersChoices() {
    if (!this.supervisors$) {
      this.supervisors$ = this.getUsersChoices().pipe(
        map((res: IAppUser[]) =>
          res.filter(
            (user) =>
              user.userRoles.filter(
                (userRole) =>
                  userRole.role.name.toLocaleLowerCase() === APP_ROLE_SUPERVISOR.toLocaleLowerCase() &&
                  userRole.role.name.toLocaleLowerCase() !== APP_ROLE_RETIRED.toLocaleLowerCase()
              ).length > 0
          )
        )
      );
    }
    return this.supervisors$;
  }

  public getStaffUsersChoices() {
    if (!this.staff$) {
      this.staff$ = this.getUsersChoices().pipe(
        map((res: IAppUser[]) =>
          res.filter(
            (user) =>
              user.userRoles.filter(
                (userRole) =>
                  userRole.role.name.toLocaleLowerCase() === APP_ROLE_STAFF.toLocaleLowerCase() &&
                  userRole.role.name.toLocaleLowerCase() !== APP_ROLE_RETIRED.toLocaleLowerCase()
              ).length > 0
          )
        )
      );
    }
    return this.staff$;
  }

  public getUserRoleChoices(): Observable<any> {
    if (!this.userRoles$) {
      this.userRoles$ = this._userRepo.getAppRoles().pipe(
        takeUntil(this.refresh$),
        shareReplay(1),
        map((res: IAppRole[]) =>
          res.filter((role) => role.name.toLocaleLowerCase() !== APP_ROLE_RETIRED.toLocaleLowerCase())
        )
      );
    }
    return this.userRoles$;
  }

  public getRoadsChoices() {
    if (!this.roads$) {
      this.roads$ = this._roadRepo.getRoads().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.roads$;
  }

  public getWorkStatusChoices() {
    if (!this.workStatus$) {
      this.workStatus$ = this._workOrderRepo.getStatuses().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.workStatus$;
  }

  public getWorkPerformedChoices() {
    if (!this.workPerformed$) {
      this.workPerformed$ = this._workOrderRepo.getWorkPerformed().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.workPerformed$;
  }

  public getLengthUnitChoices() {
    if (!this.lengthUnit$) {
      const filter = new QueryFilterExpression('UnitType', Q_OP_EQ, UNIT_TYPE_LEN);
      const query = this._queryService.toQuery(null, filter, null, null);
      this.lengthUnit$ = this._sharedRepo.getBaseUnits(query).pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.lengthUnit$;
  }

  public getTimeUnitChoices() {
    if (!this.timeUnit$) {
      const filter = new QueryFilterExpression('UnitType', Q_OP_EQ, UNIT_TYPE_TIME);
      const query = this._queryService.toQuery(null, filter, null, null);
      this.timeUnit$ = this._sharedRepo.getBaseUnits(query).pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.timeUnit$;
  }

  public getBaseTypeChoices(): Observable<any> {
    if (!this.baseType$) {
      this.baseType$ = this._trafficSignRepo.getBaseTypes().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.baseType$;
  }

  public getMountTypeChoices(): Observable<any> {
    if (!this.mountType$) {
      this.mountType$ = this._trafficSignRepo.getMountTypes().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.mountType$;
  }

  public getSupportTypeChoices(): Observable<any> {
    if (!this.supportType$) {
      this.supportType$ = this._trafficSignRepo.getSupportTypes().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.supportType$;
  }

  public getSignStatusChoices(): Observable<any> {
    if (!this.signStatus$) {
      this.signStatus$ = this._trafficSignRepo.getStatuses().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.signStatus$;
  }

  public getConditionChoices(): Observable<any> {
    if (!this.condition$) {
      this.condition$ = this._trafficSignRepo.getConditions().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.condition$;
  }

  public getCategoryChoices(): Observable<any> {
    if (!this.category$) {
      this.category$ = this._trafficSignRepo.getCategories().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.category$;
  }

  public getFaceMaterialChoices(): Observable<any> {
    if (!this.faceMaterial$) {
      this.faceMaterial$ = this._trafficSignRepo.getFaceMaterials().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.faceMaterial$;
  }

  public getReflectiveCoatingChoices(): Observable<any> {
    if (!this.refCoating$) {
      this.refCoating$ = this._trafficSignRepo.getReflectiveCoatings().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.refCoating$;
  }

  public getReflectiveRatingChoices(): Observable<any> {
    if (!this.refRating$) {
      this.refRating$ = this._trafficSignRepo.getRatings().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.refRating$;
  }

  public getOwnerTypeChoices(): Observable<any> {
    if (!this.ownerTypes$) {
      this.ownerTypes$ = this._sharedRepo.getOwnerTypes().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.ownerTypes$;
  }

  public getOwnerChoices(): Observable<any> {
    if (!this.owner$) {
      this.owner$ = this._sharedRepo.getOwners().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.owner$;
  }

  public getInventoryStatusChoices(): Observable<any> {
    if (!this.inventoryStatus$) {
      this.inventoryStatus$ = this._sharedRepo.getInventoryStatuses().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.inventoryStatus$;
  }

  public getLocationTypeChoices(): Observable<any> {
    if (!this.locationType$) {
      this.locationType$ = this._sharedRepo.getFieldTypeLocations().pipe(takeUntil(this.refresh$), shareReplay(1));
    }
    return this.locationType$;
  }
}
