import { Injectable, ErrorHandler } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { IErrorDef, isErrorDef } from '../interfaces/definitions/error.definition';

@Injectable({
  providedIn: 'root',
})
export class BadRequestErrorHandler extends ErrorHandler {
  constructor(private _dialog: MatDialog) {
    super();
  }

  /**
   * Handle any error that is not caught by another error handler.
   * If it's an IErrorDef, display it as a popup
   * Else, use the default behaviour (console.error)
   */
  handleError(error: any): void {
    if (isErrorDef(error)) {
      this._handleErrorWithModal(error);
    }
    super.handleError(error);
  }

  /**
   * Handle an error with a popup dialog.
   * @param error (HttpErrorResponse): the error response to handle with a popup.
   */
  private _handleErrorWithModal(error: IErrorDef): void {
    this._dialog.open(ConfirmDialogComponent, {
      data: {
        title: `${error.title} (${error.code})`,
        message: error.message,
      },
      minWidth: '500px',
    });
  }
}
