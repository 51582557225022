/**
 * WorkOrderRequest Model Properties (as defined by the API)
 */
export const WOR = 'WorkOrderRequest';
export const WOR_ID = 'Id';
export const WOR_STATUS = 'Status';
export const WOR_CREATED_DT = 'CreatedDateTime';
export const WOR_DUE_DT = 'DueDateTime';
export const WOR_ISSUED_BY_USER = 'IssuedByUser';
export const WOR_SUPERVISOR_USER = 'SupervisorUser';
export const WOR_SUPERVISOR_USER_ID = 'SupervisorUserId';
export const WOR_STAFF_USER = 'StaffUser';
export const WOR_STAFF_USER_ID = 'StaffUserId';
export const WOR_ROAD = 'Road';
export const WOR_ROAD_BACK = 'RoadBack';
export const WOR_ROAD_AHEAD = 'RoadAhead';
export const WOR_DESC = 'Description';
export const WOR_TYPE = 'Type';
export const WOR_REASON = 'Reason';
export const WOR_REASON_DESC = 'ReasonDescription';
export const WOR_REASON_DATE_TIME = 'ReasonDateTime';
export const WOR_TRAFFIC_SIGN_TYPE = 'TrafficSignType';
export const WOR_TRAFFIC_SIGN_TYPE_ID = 'TrafficSignTypeId';
export const WOR_ROAD_SEG_ID = 'RoadSegmentId';
export const WOR_ROAD_SIDE_DIR = 'RoadSideDir';
export const WOR_NOTES = 'Notes';
export const WOR_SIGN_CROSS_STREET_NAME = 'SignCrossStreetName';
export const WOR_MAINTENANCE = 'Maintenance';
export const WOR_INSTALLED_TRAFFIC_SIGN_ID = 'InstalledTrafficSignId';
export const WOR_DIAGRAM_IMAGE_URI = 'DiagramImageUri';
