import { AbstractControl } from '@angular/forms';

const MIN_PASSWORD_LEN = 8;

export class PasswordValidation {

  private static _hasDigit(str: string) {
    return /\d/.test(str);
  }

  static CheckPassword(AC: AbstractControl) {
    const valid = {};
    Object.assign(valid, PasswordValidation.CheckLength(AC));
    Object.assign(valid, PasswordValidation.CheckUpperCase(AC));
    Object.assign(valid, PasswordValidation.CheckDigit(AC));
    return valid;
  }

  /**
   * Check if the password inputs match, set the confirm password control
   * errors if there is no match.
   * password form control.
   * @param AC AbstractControl: The form control.
   */
  static MatchPassword(AC: AbstractControl) {
    const password: string = AC.get('passwordCtrl').value;
    const confirmPassword: string = AC.get('confirmPasswordCtrl').value;
    if (password && confirmPassword && password !== confirmPassword) {
      return { MatchPassword: true };
    } else {
      return null;
    }
  }

  static CheckLength(control: AbstractControl) {
    if (control && control.value.length < MIN_PASSWORD_LEN) {
      return { PasswordLength: true };
    } else {
      return null;
    }
  }

  static CheckUpperCase(control: AbstractControl) {
    if (control) {
      const value = control.value;
      if (value.toLocaleLowerCase() === control.value ||
        value.toLocaleUpperCase() === control.value) {
        return { PasswordCase: true };
      }
    }
    return null;
  }

  static CheckDigit(control: AbstractControl) {
    if (control && !this._hasDigit(control.value)) {
      return { PasswordDigit: true };
    } else {
      return null;
    }
  }
}
