import { Directive, ElementRef, Renderer2, HostListener, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appEllipsis]',
})
export class EllipsisDirective implements AfterViewInit {
  private _domElement: any;

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {
    this._domElement = this._elementRef.nativeElement;
    const ellipsisStyle = {
      'text-overflow': 'ellipsis',
      overflow: 'hidden',
      'white-space': 'nowrap',
    };

    Object.keys(ellipsisStyle).forEach((element) => {
      this._renderer.setStyle(this._domElement, `${element}`, ellipsisStyle[element]);
    });
  }

  ngAfterViewInit(): void {
    // Trick to update the offsetWidth/scrollWidth values on first time rendering.
    this._renderer.setProperty(this._domElement, 'scrollTop', 1);
    this.setToolTip();
  }

  @HostListener('window:resize', ['$event.target'])
  setToolTip(): void {
    this._domElement.offsetWidth < this._domElement.scrollWidth
      ? this._renderer.setAttribute(this._domElement, 'title', this._domElement.textContent)
      : this._renderer.removeAttribute(this._domElement, 'title');
  }
}
