import { Component } from '@angular/core';

/**
 * Why does this exist? Well change detection in Angular is kind of finicky.
 * The PullToRefresh directive dynamically creates this component to indicate how far you've pulled down to trigger
 * the refresh. Dynamically created components are not hooked into the change detector so changing the progress value
 * doesn't update the spinner when changed. You might think you can call change detection manually but input bindings
 * aren't checked by change detection unless they're statically specified in the template. Which leads us to this
 * wrapper component, you can change it's value from the dynamic instance and then manually call change detection.
 * Since the input binding are statically specified it will trigger the change in the spinner.
 */
@Component({
  selector: 'app-refresh-wrapper',
  template: ` <mat-progress-spinner [diameter]="30" [value]="value" color="accent"></mat-progress-spinner> `,
  styles: [],
})
export class RefreshWrapperComponent {
  private _value: number;
  get value(): number {
    return this._value;
  }

  set value(val: number) {
    this._value = val;
  }

  constructor() {
    this.value = 0;
  }
}
