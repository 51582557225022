import { Injectable } from '@angular/core';
import { IModule } from '../interfaces/definitions/module-route.definition';
import { Router, NavigationExtras } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private router: Router) {}

  /**
   * Convenience method to display data to th end user.
   * @param data : The data to display.
   */
  static displayData(data: any, prop: string = null): string {
    if (typeof data === 'string') {
      // The data is already a string, so display it as is.
      return data;
    } else if (data instanceof Array) {
      const values = data.map((val: any) => UtilsService.displayData(val));
      return values.join(', ');
    } else if (data instanceof Object) {
      if (prop !== null && data.hasOwnProperty(prop)) {
        const value = data[prop];
        return value instanceof Object ? UtilsService.displayData(value) : value;
      } else if (data.hasOwnProperty('displayName')) {
        // DisplayName field wins over everything, if the object has one.
        return data.displayName;
      } else if (data.hasOwnProperty('symbol')) {
        // Symbol field comes in second (e.g. for base units).
        return data.symbol;
      } else if (data.hasOwnProperty('name')) {
        // Name field comes in third.
        return data.name;
      } else if (
        data.hasOwnProperty('id') &&
        data.hasOwnProperty('trafficSignType') &&
        data.hasOwnProperty('trafficSign') &&
        data.trafficSign
      ) {
        // Handling for Installed Traffic Signs
        return `${data.trafficSignType.displayName}\nBarcode: ${data.trafficSign.barcode}`;
      } else if (data.hasOwnProperty('barcode')) {
        return data.barcode;
      } else if (data.hasOwnProperty('id') && data.hasOwnProperty('trafficSignType')) {
        // Handling for Installed Traffic Signs
        return `No. ${data.id} ${data.trafficSignType.displayName}`;
      } else if (data.hasOwnProperty('road') && data.hasOwnProperty('roadBack') && data.hasOwnProperty('roadAhead')) {
        return `${data.road} from ${data.roadBack} to ${data.roadAhead}`;
      } else if (data.hasOwnProperty('id')) {
        // Display some unique identifier for the object
        return data.id;
      }
    } else {
      return undefined;
    }
  }

  /**
   * Convenience method to check if 'value' has a non-empty value.
   * @param value : The value to check if empty.
   */
  isEmpty(value: any): boolean {
    if (value === null || value === undefined) {
      return true;
    } else if (value instanceof Array) {
      if (value === []) {
        return true;
      } else {
        for (const val of value) {
          if (!this.isEmpty(val)) {
            return false;
          }
        }
        return true;
      }
    } else if (value instanceof Object) {
      return Object.keys(value).length === 0;
    } else if (typeof value === 'string') {
      return value === '';
    } else {
      return false;
    }
  }

  /**
   * Unsanitize the query param value and return the expected typed value.
   * @param value : the query param raw string value.
   * @param type : the actual type of the query param value.
   */
  unsanitizeQueryParam(value: string, type: string): any {
    if (value) {
      switch (type) {
        case 'int':
          const n = Number(value);
          return isNaN(n) ? null : n;
        case 'boolean':
          const valLower = value.toLocaleLowerCase();
          return valLower === 'true' || valLower === 't' || valLower === 'yes' || valLower === 'y';
        case 'string':
        default:
          return value;
      }
    } else {
      return value;
    }
  }

  /**
   * Navigate to a module url from within the module
   * @param route : the path to route to
   */
  navigateRelativeToModule(route: string | string[] | IModule, extraParams: NavigationExtras = {}): void {
    if (typeof route === 'string') {
      route = [route];
    } else if (!(route instanceof Array)) {
      route = [route.url];
    }

    const paths = this.router.url.split('/');
    if (paths && paths.length > 1) {
      route.unshift(paths[1]);
    }

    this.router.navigate(route, extraParams);
  }

  /**
   * Utility method to extract a nested value from and object.
   * @param obj : the object that contains the nested value.
   * @param field : fully qualified field to nested value; e.g. 'location.offsetUnit.id'
   */
  getNestedValue(obj: any, property: string): any {
    let val = obj;

    if (obj && property) {
      const chainedProps = property.split('.');
      chainedProps.forEach((prop: string) => (val = val[prop]));
    }

    return val;
  }
}
