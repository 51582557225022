import { Injectable } from '@angular/core';
import { RepositoryBaseService } from './repository-base.service';
import { IQueryParam } from '../../interfaces/query/query';
import { IMaintenanceRework } from '../../interfaces/models/maintenance-rework.model';
import { QueryService } from '../query.service';
import { IWorkOrderMaintenanceCreate } from '../../interfaces/models/work-order-maintenance-create.model';
import { IWorkOrderType } from '../../interfaces/models/work-order-type.model';
import { IWorkOrderStatus } from '../../interfaces/models/work-order-status.model';
import { IWorkOrderReason } from '../../interfaces/models/work-order-reason.model';
import { IWorkOrderRequestCreate, IWorkOrderRequest } from '../../interfaces/models/work-order-request.model';
import { IWorkOrderWorkPerformed } from '../../interfaces/models/work-order-work-performed.model';
import { IWorkOrderMaintenanceUpdate } from '../../interfaces/models/work-order-maintenance-update.model';

/*
 * API endpoint constants
 */
export const WORK_ORDER_REQUEST_URI = 'trafficsignworkorderrequest';
export const WORK_ORDER_MAINTENANCE_URI = 'trafficsignworkordermaintenance';
export const WORK_ORDER_MAINTENANCE_REWORK_URI = 'trafficsignworkordermaintenancerework';
export const WORK_ORDER_TYPE_URI = 'workordertype';
export const WORK_ORDER_STATUS_URI = 'workorderstatus';
export const WORK_ORDER_REASON_URI = 'workorderreason';
export const WORK_ORDER_WORK_PERFORMED_URI = 'workorderworkperformed';

/**
 * Repository service for Work Order related API endpoints.
 */
@Injectable({
  providedIn: 'root',
})
export class WorkOrderRepositoryService {
  /**
   * Constructor
   * @param repo (RepositoryBaseService): base service for making http requests to the API
   */
  constructor(private repo: RepositoryBaseService, private _queryService: QueryService) {}

  private _getHistoryUri(uri: string) {
    return `${uri}/history`;
  }

  /**
   * Basic GET, POST, PUT, DELETE methods for Work Order related objects
   */
  public getRequests(query: IQueryParam = null, returnOnlyItems: boolean = false) {
    return this.repo.getData(WORK_ORDER_REQUEST_URI, query, false, returnOnlyItems);
  }

  public getRequest(id: number, query: IQueryParam = null) {
    const url = `${WORK_ORDER_REQUEST_URI}/${id}`;
    return this.repo.getData(url, query);
  }

  public getRequestHistoryById(id: number) {
    return this.repo.getData(this._getHistoryUri(`${WORK_ORDER_REQUEST_URI}/${id}`));
  }

  public getMaintenance(query: IQueryParam = null, returnOnlyItems: boolean = false) {
    return this.repo.getData(WORK_ORDER_MAINTENANCE_URI, query, false, returnOnlyItems);
  }

  public getMaintenanceById(id: number, query: IQueryParam = null) {
    const url = `${WORK_ORDER_MAINTENANCE_URI}/${id}`;
    return this.repo.getData(url, query);
  }

  public getMaintenanceHistoryById(id: number) {
    return this.repo.getData(this._getHistoryUri(`${WORK_ORDER_MAINTENANCE_URI}/${id}`));
  }

  public getMaintenanceHistoryForInstalledSign(installedSignId: number) {
    return this.repo.getData(
      this._getHistoryUri(`${WORK_ORDER_MAINTENANCE_URI}/installedtrafficsign/${installedSignId}`)
    );
  }

  public createMaintenance(maintenance: IWorkOrderMaintenanceCreate) {
    return this.repo.create(`${WORK_ORDER_MAINTENANCE_URI}/full`, maintenance);
  }

  public updateMaintenance(id: number, maintenance: IWorkOrderMaintenanceUpdate) {
    return this.repo.update(`${WORK_ORDER_MAINTENANCE_URI}/${id}/full`, maintenance);
  }

  public getTypes(query: IQueryParam = null, returnOnlyItems: boolean = false) {
    return this.repo.getData(WORK_ORDER_TYPE_URI, query, false, returnOnlyItems);
  }

  public getStatuses(query: IQueryParam = null, returnOnlyItems: boolean = false) {
    return this.repo.getData(WORK_ORDER_STATUS_URI, query, false, returnOnlyItems);
  }

  public getReasons(query: IQueryParam = null, returnOnlyItems: boolean = false) {
    return this.repo.getData(WORK_ORDER_REASON_URI, query, false, returnOnlyItems);
  }

  public getWorkPerformed(query: IQueryParam = null, returnOnlyItems: boolean = false) {
    return this.repo.getData(WORK_ORDER_WORK_PERFORMED_URI, query, false, returnOnlyItems);
  }

  public createType(type: IWorkOrderType) {
    return this.repo.create(WORK_ORDER_TYPE_URI, type);
  }

  public createStatus(status: IWorkOrderStatus) {
    return this.repo.create(WORK_ORDER_STATUS_URI, status);
  }

  public createReason(reason: IWorkOrderReason) {
    return this.repo.create(WORK_ORDER_REASON_URI, reason);
  }

  public createRequest(request: IWorkOrderRequestCreate) {
    return this.repo.create(WORK_ORDER_REQUEST_URI, request);
  }

  public createWorkPerformed(request: IWorkOrderWorkPerformed) {
    return this.repo.create(WORK_ORDER_WORK_PERFORMED_URI, request);
  }

  public createMaintenanceRework(maintenanceId: number, note: string, supervisorUserId: string) {
    const rework: IMaintenanceRework = {
      isResolved: false,
      note,
      maintenanceId,
      supervisorUserId,
    };
    return this.repo.create(WORK_ORDER_MAINTENANCE_REWORK_URI, rework);
  }

  public updateWorkOrderRequest(request: IWorkOrderRequest) {
    return this.repo.update(`${WORK_ORDER_REQUEST_URI}/${request.id}`, request);
  }

  public patchReplaceRequest(id: number, field: string, value: any) {
    return this.repo.patchReplace(`${WORK_ORDER_REQUEST_URI}/${id}`, field, value);
  }

  public patchReplaceMaintenanceField(id: number, field: string, value: any) {
    return this.repo.patchReplace(`${WORK_ORDER_MAINTENANCE_URI}/${id}`, field, value);
  }

  public patchReplaceMaintenanceFields(id: number, values: [string, any][]) {
    return this.repo.patchReplaceMany(`${WORK_ORDER_MAINTENANCE_URI}/${id}`, values);
  }

  public deleteRequest(id: number) {
    return this.repo.delete(`${WORK_ORDER_REQUEST_URI}/${id}`);
  }

  public deleteType(id: number) {
    return this.repo.delete(`${WORK_ORDER_TYPE_URI}/${id}`);
  }

  public deleteStatus(id: number) {
    return this.repo.delete(`${WORK_ORDER_STATUS_URI}/${id}`);
  }

  public deleteReason(id: number) {
    return this.repo.delete(`${WORK_ORDER_REASON_URI}/${id}`);
  }

  public deleteWorkPerformed(id: number) {
    return this.repo.delete(`${WORK_ORDER_WORK_PERFORMED_URI}/${id}`);
  }
}
