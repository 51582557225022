import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { moveItemInArray, CdkDropListGroup, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { CdkDropListInternal } from '@angular/cdk/drag-drop/directives/drop-list';
import { GraphTile } from 'src/app/core/interfaces/definitions/graph.definition';
import { GraphTileComponent } from '../../../../components/graph-tile/graph-tile.component';
import { StateService } from 'src/app/core/services/state.service';

/**
 * This component is the container for graph tiles of any type.
 * Handles the drag/drop functionality and handling the
 * layout of the graph list.
 *
 * Requires the HTML template reference of the graph tile and a list of GraphTile that
 * contain the graph definitions. This will pass the index and graph instance to the template
 * context that corresponds to the NgFor context.
 * Example Usage:
 * ```html
 * <app-graph-list [graphs]="some-graph-list" [templateRef]="graphTemplateRef"></app-graph-list>
 * <ng-template #graphTemplateRef let-graph let-index="index">
 *   <!-- tile definition here --->
 * </ng-template>
 * ```
 */
@Component({
  selector: 'app-graph-list',
  templateUrl: './graph-list.component.html',
  styleUrls: ['./graph-list.component.scss'],
})
export class GraphListComponent implements AfterViewInit {
  constructor(private _stateService: StateService) {
    this.target = null;
    this.source = null;
    this.resize();
  }
  /**
   * ViewChild: listGroup
   * Group of two CdkDropList objects to provide work around to dragging and dropping
   * items in a grid layout.
   */
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  /**
   * ViewChild: placeholder
   * Hidden list that helps to provide a work around to drag-and-drop for a grid layout.
   */
  @ViewChild(CdkDropList) placeholder: CdkDropList;
  @Input() graphTileTemplateRef: TemplateRef<GraphTileComponent>;
  @Input() graphs: GraphTile[];
  @Input() columns = 3;
  @Input() rowHeightRatio = 1.5;
  @Output() addGraph = new EventEmitter<void>();
  @Output() saveGraphs = new EventEmitter<void>();
  @Output() changeColour = new EventEmitter<void>();
  target: CdkDropList;
  targetIndex: number;
  source: CdkDropListInternal;
  sourceIndex: number;
  isMobile: boolean;

  @HostListener('window:resize', ['$event'])
  resize(): void {
    this.isMobile = this._stateService.isMobile();
  }

  /**
   * cdkDropListEnterPredicate:  Function that is used to determine whether an item is allowed to be moved into a drop container.
   */
  enter = (drag: CdkDrag, drop: CdkDropList) => {
    // Only alow dropping in the displayed list (not the hidden list helper).
    if (drop === this.placeholder) {
      return true;
    }

    // Get the placeholder and drop DOM elements
    const phElement = this.placeholder.element.nativeElement;
    const dropElement = drop.element.nativeElement;

    // Get the drag and drop indexes
    const dragIndex = indexOf(dropElement.parentNode.children, drag.dropContainer.element.nativeElement);
    const dropIndex = indexOf(dropElement.parentNode.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      const sourceElement = this.source.element.nativeElement;
      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentNode.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentNode.insertBefore(phElement, dragIndex < dropIndex ? dropElement.nextSibling : dropElement);

    // fixme
    this.source.start();
    this.placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);

    return false;
  };

  ngAfterViewInit(): void {
    // Hide the place holder list.
    const phElement = this.placeholder.element.nativeElement;
    phElement.style.display = 'none';
    phElement.parentNode.removeChild(phElement);
  }

  /**
   * Event handler for tile dropped in container.
   */
  drop() {
    if (!this.target) {
      return;
    }

    const phElement = this.placeholder.element.nativeElement;
    const parent = phElement.parentNode;
    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    this.target = null;
    this.source = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.graphs, this.sourceIndex, this.targetIndex);
    }
  }
}

/**
 * Helper function to return the index of the node within the collection of objects.
 * @param collection : collection of DOM elements
 * @param node : the DOM element to find the index of within the collection.
 */
function indexOf(collection: HTMLCollection, node: HTMLElement) {
  return Array.prototype.indexOf.call(collection, node);
}
