import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EsriMapService } from 'src/app/core/services';
import { POSITION_TOP_LEFT } from 'src/app/core/esri/constants/constants';

@Directive({
  selector: '[appCustomWidget]',
})
export class CustomWidgetDirective implements OnDestroy {
  /**
   * Input dataSubject: observable let's us know when the map info is available.
   */
  @Input()
  set mapSubject(init$: Observable<void>) {
    // Clean up any previous subscriptions
    this._destroy$.next();

    init$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._mapService.addContent(
        this._domElement,
        this.position,
        this.expandable,
        this.expandTooltip,
        this.expandIconClass
      );
    });
  }
  /**
   * Input position: the widget's positioning within the map
   */
  @Input() position: string = POSITION_TOP_LEFT;
  /**
   * Input expandable: whether or not the widget is contained in an Expand widget.
   */
  @Input() expandable = false;
  /**
   * Input expandTooltip: tooltip for the expand widget.
   */
  @Input() expandTooltip: string;
  /**
   * Input expandIconClass: ArcGIS icon class.
   */
  @Input() expandIconClass: string;
  /**
   * Class properties
   */
  private _domElement: HTMLElement;
  private _destroy$: Subject<void>;

  constructor(elementRef: ElementRef, private _mapService: EsriMapService) {
    this._domElement = elementRef.nativeElement;
    this._destroy$ = new Subject();
  }

  ngOnDestroy(): void {
    // Remove custom widgets on destroy
    this._mapService.removeContent(this._domElement);
    //
    this._destroy$.next();
    this._destroy$.complete();
  }
}
