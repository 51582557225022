import { FormControl } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { startWith, map, tap } from 'rxjs/operators';
import { IFormControl } from './builder/form-builder.definition';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ValidateDropdownList } from 'src/app/shared/validators/dropdown.validator';

/**
 * Generic class that wraps a FormControl intance and adds more complex functionality
 * and customization to the control.
 */
export class BaseFormControl {
  // The main FormControl intance
  form: FormControl;
  // See form-builder.definition.ts for more info on these control fields
  id: string;
  type: string;
  multiple: boolean;
  placeholder: string;
  tooltip: string;
  min: any;
  updateProperties: Array<[string, string]>;
  fileNote: boolean;
  hint: string;
  displayProperty: string;
  // The list of all possible input values. This avoids having to retrieve the original
  // list after filtering each time.
  allChoices: any[];
  // Observable that when subscribed to returns the choices list filtered according to user input.
  // NOTE: applicable only to type 'choices', and list of values __should__ be a subset of 'choices'
  // and will change depending on form input.
  filterChoices: Observable<any[]>;
  // Dynamic value that is passed to filterByChoicesFn in order to achiev a more custom filter
  // per control. NOTE: applicable only to type 'choices'
  filterChoicesBy: any;
  // Flag indicating if the control is visible or not.
  hidden: boolean;
  // This is currently only for the app-choices-input-control, since we need to pass a display
  // function to the mat-autocomplete displayWith input (otherwise we shoudl get rid of this).
  display = UtilsService.displayData;

  constructor(descriptor: IFormControl, private _utils: UtilsService) {
    this.id = descriptor.id;
    this.type = descriptor.type;
    this.multiple = descriptor.multiple;
    this.placeholder = descriptor.placeholder;
    this.tooltip = descriptor.tooltip;
    this.min = descriptor.min;
    this.updateProperties = descriptor.updateProperties;
    this.fileNote = !!descriptor.fileNote;
    this.hidden = false;
    this.hint = descriptor.hint;
    this.displayProperty = descriptor.displayProperty ? descriptor.displayProperty : null;

    const defaultVal = descriptor.multiple ? [] : '';
    this.form = new FormControl(defaultVal, descriptor.validators);
    this.setChoices(descriptor.choices, descriptor.filterChoicesFn);
  }

  /**
   * Initialize the FormControl's input values list and set up validation and filtering.
   * @param choices : the values list
   * @param filterChoicesFn : the filter function applied to the values list
   */
  setChoices(choices: Observable<any>, filterChoicesFn: (filterBy: any, curVal: any) => boolean): void {
    this.allChoices = null;
    if (!this._utils.isEmpty(choices)) {
      // Set the filtered chocies to the observable that yields a new value whenever the form control value
      // changes, which will be the set of choices filtered by the current form control value.
      this.filterChoices = combineLatest([this.form.valueChanges.pipe(startWith('')), choices]).pipe(
        tap((results: [string, any[]]) => {
          // Initializes the choices and validators the first time we get back a list of choices.
          if (!this.allChoices) {
            this.allChoices = results[1];
            const validators = [ValidateDropdownList(results[1], false)];
            if (this.form.validator) {
              validators.push(this.form.validator);
            }
            this.form.setValidators(validators);
          }
        }),
        map((results: [string, any[]]) => this._filterControlChoices(filterChoicesFn, results[0]))
      );
    } else {
      // Placeholder for now
      this.filterChoices = of([]);
    }
  }

  /**
   * Return the filtered set of choices based on the form control descriptor.
   * @param descriptor : the descriptor that defines how the form control behaves
   * @param filterValue : the value to filter the form control choices list by
   * @param choices : the choices list for the form control
   */
  private _filterControlChoices(filterChoicesFn: (filterBy: any, curVal: any) => boolean, filterValue: any): any[] {
    let filteredChoices = this.allChoices;

    if (filteredChoices) {
      if (typeof filterValue === 'string') {
        const val = filterValue.toLocaleLowerCase();
        filteredChoices = filteredChoices.filter((opt: any) => this.display(opt).toLocaleLowerCase().includes(val));
      }

      if (filterChoicesFn) {
        filteredChoices = filteredChoices.filter((opt: any) => filterChoicesFn(this.filterChoicesBy, opt));
      }
    }

    return filteredChoices;
  }
}
