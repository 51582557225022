import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IErrorDef } from '../../core/interfaces/definitions/error.definition';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

/** Error Constants */
export const ERRORS: { [s: number]: IErrorDef } = {
  200: {
    code: '200',
    title: 'Page coming soon',
    message: 'The requested page is not available yet. Check back soon or contact Trans-Plan.',
  },
  403: {
    code: '403',
    title: 'Forbidden Access',
    message: 'You do not have access rights to this page.',
  },
  404: {
    code: '404',
    title: 'Not Found',
    message: 'The requested page was not found.',
  },
  500: {
    code: '500',
    title: 'Internal Server Error',
    message: 'Something went wrong ...',
  },
  0: {
    title: 'Unknown Error',
    message: 'Something went wrong ...',
  },
};

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnDestroy {
  error: IErrorDef;
  private errorQuerySubscription: Subscription;

  constructor(_router: Router, _route: ActivatedRoute) {
    const url = _router.routerState.snapshot.url;
    const qInd = url.indexOf('?');
    const code = +url.substring(1, qInd > 0 ? qInd : undefined);

    this.error = !!code ? ERRORS[code] : ERRORS[0];

    this.errorQuerySubscription = _route.queryParamMap.subscribe((params) => {
      if (params.has('errorMessage')) {
        this.error.message = params.get('errorMessage');
      }

      if (params.has('errorTitle')) {
        this.error.title = params.get('errorTitle');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.errorQuerySubscription) {
      this.errorQuerySubscription.unsubscribe();
    }
  }
}
