import { Injectable } from '@angular/core';
import { RepositoryBaseService } from './repository-base.service';
import { IAppUser, IAppRole } from '../../interfaces/models';
import { IPasswordData } from './../../interfaces/account';
import { IQueryParam } from '../../interfaces/query/query';
import { IAppUserAuthentication } from '../../interfaces/models';
import { IAppUserCreateData } from 'src/app/components/dialogs/user-account-dialogs/user-create-dialog/user-create-dialog.component';
// API endpoint constants
export const APP_USER_URI = 'appuser';
export const APP_ROLE_URI = 'approle';
export const APP_USER_ROLE_URI = 'appuserrole';
export const TENANT_URI = 'tenant';
// User specific constants
export const APP_ROLE_SUPERUSER = 'Superuser';
export const APP_ROLE_SUPERVISOR = 'Supervisor';
export const APP_ROLE_TECH = 'Technician';
export const APP_ROLE_STAFF = 'Field Staff';
export const APP_ROLE_RETIRED = 'Retired';
export const APP_ROLES_ELEVATED = [APP_ROLE_SUPERUSER, APP_ROLE_SUPERVISOR];
export const APP_ROLES_ADMIN = [APP_ROLE_SUPERUSER, APP_ROLE_SUPERVISOR, APP_ROLE_TECH];
export const APP_ROLES = [APP_ROLE_SUPERUSER, APP_ROLE_SUPERVISOR, APP_ROLE_TECH, APP_ROLE_STAFF];

@Injectable({
  providedIn: 'root',
})
export class AppUserRepositoryService {
  constructor(private repo: RepositoryBaseService) {}

  /**
   * Do not call this directly!!
   * Use the authentication service instead.
   * This is to allow for caching and handling of unauthorized requests automatically without having to maintain that state yourself.
   */
  public getCurrentUser() {
    return this.repo.getData(`${APP_USER_URI}/currentuser`);
  }

  public getAppUsers(query: IQueryParam = null, returnItemsOnly = false) {
    if (query) {
      return this.repo.getData(`${APP_USER_URI}/query`, query, false, returnItemsOnly);
    } else {
      return this.repo.getData(APP_USER_URI);
    }
  }

  public getAppRoles() {
    return this.repo.getData(APP_ROLE_URI);
  }

  public getAppUserRoles(id: string) {
    return this.repo.getData(`${APP_USER_URI}/${id}/${APP_USER_ROLE_URI}`);
  }

  public getTenants(query: IQueryParam = null) {
    return this.repo.getData(TENANT_URI, query);
  }

  public authenticate(authentication: IAppUserAuthentication) {
    return this.repo.create(`${APP_USER_URI}/authenticate`, authentication, 'application/json', 'json', false);
  }

  public registerAppUser(user: IAppUserCreateData) {
    return this.repo.create(`${APP_USER_URI}/register`, user);
  }

  public updateAppUser(user: IAppUser) {
    return this.repo.update(`${APP_USER_URI}/${user.id}`, user);
  }

  public deleteAppUser(id: string) {
    return this.repo.delete(`${APP_USER_URI}/${id}`);
  }

  public setAppUserRole(userId: string, role: IAppRole) {
    return this.repo.create(`${APP_USER_URI}/${userId}/setappuserrole`, role);
  }

  public retireAppUser(userId: string) {
    return this.repo.create(`${APP_USER_URI}/${userId}/retire`, {});
  }

  public changeAppUserPassword(userId: string, data: IPasswordData) {
    return this.repo.create(`${APP_USER_URI}/changepassword/${userId}`, data);
  }

  public forgotPassword(email: string) {
    return this.repo.getData(`${APP_USER_URI}/forgotpassword/${email}`);
  }

  public resetPassword(userId: string, data: IPasswordData) {
    return this.repo.create(`${APP_USER_URI}/resetpassword/${userId}`, data);
  }

  public confirmEmail(userId: string, token: string) {
    return this.repo.getData(`${APP_USER_URI}/confirmemail/${userId}?token=${token}`);
  }

  public sendConfirmationEmail() {
    return this.repo.getData(`${APP_USER_URI}/resendemail`);
  }
}
