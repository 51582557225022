import { Component, Input, EventEmitter, Output, Renderer2, HostListener } from '@angular/core';
import { StateService } from 'src/app/core/services/state.service';
import { IFormGroup } from '../builder/form-builder.definition';

export interface ISubmitAction {
  name: string;
  value: any;
  color: 'primary' | 'accent' | 'warn';
}

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss'],
})
export class BaseFormComponent {
  @Input() form: IFormGroup;
  isMobile: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isMobile = this._stateService.isMobile();
  }

  constructor(private renderer: Renderer2, private _stateService: StateService) {}
}
