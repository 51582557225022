import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { POSITION_BOTTOM_LEFT } from 'src/app/core/esri/constants/constants';
import { EsriMapService } from 'src/app/core/services';

@Component({
  selector: 'app-esri-screenshot',
  templateUrl: './screenshot.component.html',
  styleUrls: ['./screenshot.component.scss'],
})
export class ScreenshotComponent {
  /**
   * Input mapInit: observable let's us know when the map info is available.
   */
  @Input() mapInit: Observable<void>;
  /**
   * Input position: the widget's positioning within the map
   */
  @Input() position: string = POSITION_BOTTOM_LEFT;
  /**
   * Input disabled: flag to disable the screenshot button.
   */
  @Input() disabled = false;
  /**
   * Output screenshotEvent: screenshot data.
   */
  @Output() screenshotEvent = new EventEmitter<__esri.Screenshot>();

  constructor(private _mapService: EsriMapService) {}

  public takeScreenshot() {
    this._mapService.takeScreenshot().then((screenshot: __esri.Screenshot) => {
      if (screenshot) {
        this.screenshotEvent.emit(screenshot);
      }
    });
  }
}
