import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IActionItem } from 'src/app/core/interfaces/definitions/action-item.definition';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { Observable, of } from 'rxjs';
import { getAction } from './actions.descriptor';
import { IToolbarAction } from 'src/app/core/interfaces/definitions/toolbar-action.definition';
import { ToolbarBuilderService } from 'src/app/core/services/toolbarBuilder.service';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss'],
})
export class TableToolbarComponent<T> implements OnInit {
  // Current table row selection
  @Input() data: T[];
  @Input() canSaveColumns: boolean;
  @Input() sidePanelToggle: boolean;
  @Input()
  set actions(value: IToolbarAction[]) {
    this.actionItems = [];
    this.viewItems = [];
    this.moreActions = [];

    if (value) {
      this._toolbarBuilder.build(value).subscribe((actions: IToolbarAction[]) => {
        const dataActions: IActionItem[] = [];
        const viewActions: IActionItem[] = [];
        const extraActions: IActionItem[] = [];
        for (const action of actions) {
          const item: IActionItem = getAction(action.id);
          item.exec = action.listener;
          item.constraints = action.constraints;
          switch (action.type) {
            case 'data':
              dataActions.push(item);
              break;
            case 'view':
              viewActions.push(item);
              break;
            case 'extra':
              extraActions.push(item);
              break;
            default:
              break;
          }
        }
        if (this.isMobile) {
          this.moreActions.push(dataActions, viewActions, extraActions);
        } else {
          this.actionItems = dataActions;
          this.viewItems = viewActions;
          if (extraActions.length > 0) {
            this.moreActions.push(extraActions);
          }
        }
      });
    }
  }
  @Output() refreshRequest = new EventEmitter<void>();
  @Output() editColumnsRequest = new EventEmitter<void>();
  @Output() saveColumnsRequest = new EventEmitter<void>();
  @Output() sidePanelToggleChange = new EventEmitter<boolean>();
  // Two way binding attribute setup to toggle the filter sidenav
  @Input() filterPanelToggle: boolean;
  @Output() filterPanelToggleChange = new EventEmitter<boolean>();

  /**
   * Class Fields
   */
  hasSidePanel: boolean;
  hasFilterPanel: boolean;
  isMobile: boolean;
  // todo create a map with key as type
  actionItems: IActionItem[];
  viewItems: IActionItem[];
  moreActions: Array<Array<IActionItem>>;

  @HostListener('window:resize', ['$event'])
  resize(): void {
    this.isMobile = this._stateService.isMobile();
  }

  constructor(
    private _toolbarBuilder: ToolbarBuilderService,
    private _dialog: MatDialog,
    private _stateService: StateService
  ) {
    this.data = [];
    this.resize();
  }

  ngOnInit(): void {
    // View actions
    this.actionItems = [];
    this.viewItems = [];
    this.moreActions = [];
    //
    this.hasSidePanel = this.sidePanelToggle !== null && this.sidePanelToggle !== undefined;
    this.hasFilterPanel = this.filterPanelToggle !== null && this.filterPanelToggle !== undefined;
  }

  hasData(): boolean {
    return this.data && this.data.length > 0;
  }

  getDataAt(i: number): T {
    return this.hasData() ? this.data[i] : null;
  }

  toggleFilterSidenav(): void {
    this.filterPanelToggle = !this.filterPanelToggle;
    this.filterPanelToggleChange.emit(this.filterPanelToggle);
  }

  toggleSidePanel(): void {
    this.sidePanelToggle = !this.sidePanelToggle;
    this.sidePanelToggleChange.emit(this.sidePanelToggle);
  }

  execActionItem(action: IActionItem): void {
    if (!action.requireData || this.data !== null) {
      let observable: Observable<boolean>;
      let constraintFailed = false;
      if (action.constraints) {
        for (const constraintFunc of action.constraints) {
          const constraintCheck = constraintFunc(this.data);
          if (!constraintCheck.success) {
            constraintFailed = true;
            observable = of(false);
            this._dialog.open(ConfirmDialogComponent, {
              data: { message: constraintCheck.failureMessage, accept: 'OK', hideCancel: true },
            });
            break;
          }
        }
      }

      if (!constraintFailed) {
        if (action.confirmExec) {
          const dialogRef = this._dialog.open(ConfirmDialogComponent, {
            data: { message: action.confirmExecMsg, accept: 'YES', reject: 'NO' },
          });
          observable = dialogRef.afterClosed();
        } else {
          observable = of(true);
        }
      }

      observable.subscribe((execute: boolean) => {
        if (execute) {
          action.exec(this.data);
        }
      });
    } else {
      this._dialog.open(ConfirmDialogComponent, {
        data: { message: `Item must be selected`, hideCancel: true },
      });
    }
  }
}
