import { IQueryFilter } from '../../interfaces/query/filters/query-filter';
import { IFilter } from '../../interfaces/definitions/filter.definition';
import { IQueryParam } from '../../interfaces/query/query';
import { Observable, of, forkJoin, EMPTY } from 'rxjs';
import { QueryService } from '../query.service';
import { withLatestFrom, map, switchMap } from 'rxjs/operators';
import { QueryFilterAnd } from '../../interfaces/query/filters/query-filter-binary';
import { IQuerySort } from '../../interfaces/query/query-sort';
import { IResult, ISeriesResult, GraphTile } from '../../interfaces/definitions/graph.definition';
import { DateTimeService, mediumDateFormat } from '../datetime.service';
import { IQueryResult } from '../../interfaces/models';

export class GraphBuilderService {
  constructor(private _queryService: QueryService, private _datetimeService: DateTimeService) {}

  /**
   * From the graph choices, build a query for each one and transform it into an IResult or ISeriesResult,
   * then return the list of those options as an observable.
   * @param graph The properties of the graph to use.
   * @param dateFilter The date period to filter over.
   */
  getGraphTileResult(
    graph: GraphTile,
    dateFilter: IFilter,
    userFilter: IFilter
  ): Observable<(IResult | ISeriesResult)[]> {
    this.initialize(graph);

    let filters: Observable<IQueryFilter[]>;
    if (graph.filter.choices instanceof Observable) {
      filters = graph.filter.choices;
    } else {
      filters = of(graph.filter.choices);
    }

    return filters.pipe(
      withLatestFrom(dateFilter.filter, userFilter.filter),
      switchMap(([queryFilters, dateQueryFilter, userQueryFilter]) => {
        // build a query for each choice
        const queryParams: IQueryParam[] = [];
        for (const qf of queryFilters) {
          // split the property name on the . beacause we don't want to specify primitive fields.
          const includeName = graph.filter.propertyName.split('.')[0];

          // if we have a date filter and/or user filter, and it with the current filter
          let extraFilters = null;
          if (dateQueryFilter && userQueryFilter) {
            extraFilters = new QueryFilterAnd(dateQueryFilter, userQueryFilter);
          } else if (dateQueryFilter) {
            extraFilters = dateQueryFilter;
          } else if (userQueryFilter) {
            extraFilters = userQueryFilter;
          }

          const filter = extraFilters ? new QueryFilterAnd(qf, extraFilters, qf.displayName) : qf;
          const sort: IQuerySort = { qsPrimary: dateFilter.propertyName, qsIsAsc: true };
          const q = this._queryService.toQuery(null, filter, sort, [includeName, dateFilter.propertyName]);
          queryParams.push(q);
        }

        // transform each query into it's final graph result.
        return forkJoin(queryParams.map((query) => this._transformFunc(query, graph, dateFilter)));
      }),
      map((results: (IResult | ISeriesResult)[]) => results.filter((result: IResult | ISeriesResult) => result))
    );
  }

  private _transformFunc(
    query: IQueryParam,
    graph: GraphTile,
    dateFilter: IFilter
  ): Observable<IResult | ISeriesResult> {
    return this.isSeriesGraph(graph.type)
      ? this.transformQueryResultToISeriesResult(query, graph, dateFilter)
      : this.transformQueryResultToIResult(query, graph);
  }

  private isSeriesGraph(type: string): boolean {
    return type === 'line' || type === 'area' || type === 'stacked-area';
  }

  /**
   * Find the filter object that corresponds with graph filter.
   * Used to validate the filters if the repo changes or on initialization.
   * @param graph The properties of the graph to use.
   * @param filters The list of filter types available to graph over.
   */
  setGraphFilter(graph: GraphTile, filters: IFilter[]): void {
    if (!graph.filter && graph.filterName) {
      graph.filter = filters.find((x) => x.display === graph.filterName);
    }
    if (!graph.filter || filters.filter((x) => x.propertyName === graph.filter.propertyName).length === 0) {
      graph.filter = filters[0];
    }
  }

  /**
   * Transform an api query into a graph. This builds results for graphs that only need a
   * name and proportion (i.e. how many items the query returns.)
   * Builds a single choice (i.e. section of graph).
   * @param query The QueryParam to send to the API.
   * @param graph The properties of the graph that dictate what repo to use.
   * @param dateFilter The date period to filter over.
   */
  private transformQueryResultToIResult(query: IQueryParam, graph: GraphTile): Observable<IResult> {
    return this.detectRepo(query, graph).pipe(
      map((res: any[]) => {
        if (res && res.length > 0) {
          const result: IResult = {
            name: query.filters.displayName,
            value: res.length,
          };
          return result;
        }
      })
    );
  }

  /**
   * Transform an api query into a graph over time. This will bucket items into their corresponding date
   * and also create date buckets even if there are no entries that correspond to that date.
   * Builds a single choice (i.e. line).
   * @param query The QueryParam to send to the API.
   * @param graph The properties of the graph that dictate what repo to use.
   * @param dateFilter The date period to filter over.
   * @summary Currently used for building line graphs but could be used for other graph types in the future.
   */
  private transformQueryResultToISeriesResult(
    query: IQueryParam,
    graph: GraphTile,
    dateFilter: IFilter
  ): Observable<ISeriesResult> {
    return this.detectRepo(query, graph).pipe(
      map((res: any[]) => {
        if (!res) {
          return null;
        }
        const datePropName = `${dateFilter.propertyName[0].toLowerCase()}${dateFilter.propertyName.slice(1)}`;
        const dates = res.map((x) => this._datetimeService.convertUtcToLocal(x[datePropName]));
        const result: ISeriesResult = {
          name: query.filters.displayName,
          series: [],
        };

        if (dates.length > 0) {
          /**
           * Pick a start and end date, then for each day in between find all the items that
           * match that date. Iterate by day to find days that have no items that match.
           * Use date copies to avoid assigning the start and end date to the same reference
           */
          const currDate = dates[0].clone();
          graph.xScaleMax = dates[dates.length - 1].clone();
          while (currDate.isSameOrBefore(graph.xScaleMax)) {
            const len = dates.filter((x) => x.isSame(currDate, 'days')).length;
            result.series.push({
              name: this._datetimeService.formatDatetime(currDate, mediumDateFormat),
              value: len,
            });
            currDate.add(1, 'days');
          }
        }
        return result;
      })
    );
  }

  /**
   * Abstract methods, setup module specific constants
   * i.e. for signs, the available repos are requests, maintenance and installed-sign
   * but for another module these will be different
   */

  initialize(graph: GraphTile): void {}
  getDateFilterProperty(repo: string): string {
    return null;
  }
  getUserFilterProperty(repo: string): string {
    return null;
  }
  getFilterChoices(graph: GraphTile): IFilter[] {
    return [];
  }
  protected detectRepo(queryParam: IQueryParam, graph: GraphTile): Observable<any[] | IQueryResult> {
    return EMPTY;
  }
}
