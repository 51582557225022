import { Component, OnInit, EventEmitter, Output, HostListener } from '@angular/core';
import { INotificationMessage } from '../../../core/interfaces/models';
import { NotificationHandlerService } from '../../../core/services/notification-handler.service';
import { forkJoin, Observable } from 'rxjs';
import { switchMap, tap, finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/repositories/notification.service';
import { StateService } from 'src/app/core/services/state.service';

type NotificationWithTruncation = INotificationMessage & { shouldTruncate: boolean };

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss'],
})
export class NotificationMenuComponent implements OnInit {
  @Output() unseenCount = new EventEmitter<number>();
  showOnlyUnread: boolean;
  expanded: boolean;
  hasHistory: boolean;
  isLoading: boolean;
  isMobile: boolean;
  notificationHistory: NotificationWithTruncation[];
  readonly truncationLength = 256;
  private unreadMessageHistory: NotificationWithTruncation[];
  private fullMessageHistory: NotificationWithTruncation[];

  @HostListener('window:resize', ['$event'])
  resize(): void {
    this.isMobile = this.stateService.isMobile();
  }

  constructor(
    private notificationService: NotificationService,
    private handlerService: NotificationHandlerService,
    private stateService: StateService
  ) {
    this.fullMessageHistory = [];
    this.unreadMessageHistory = [];
    this.showOnlyUnread = true;
    this.isLoading = false;
    this.resize();
  }

  ngOnInit(): void {
    this.reloadMessages(this.handlerService.newMessage);
  }

  toggleNotifications(): void {
    this.stateService.toggleNotificationMenu();
  }

  toggleUnseen(): void {
    this.showOnlyUnread = !this.showOnlyUnread;
    this._setNotificationHistory();
  }

  toggleExpanded(notification: NotificationWithTruncation): void {
    notification.expanded = !notification.expanded;
  }

  markAllAsRead(): void {
    const changeReadObservables = [];
    for (const message of this.notificationHistory) {
      if (!message.hasRead) {
        changeReadObservables.push(this.notificationService.changeRead(message.id, true));
      }
    }
    this.reloadMessages(forkJoin(changeReadObservables));
  }

  /**
   * Marks a notifiction as seen (used in the html file)
   */
  toggleReadNotification(event: MouseEvent, index: number): void {
    event.stopPropagation();
    const message = this.notificationHistory[index];
    this.reloadMessages(this.notificationService.changeRead(message.id, !message.hasRead));
  }

  shouldTruncate(message: string): boolean {
    return message.length > this.truncationLength;
  }

  private _setNotificationHistory(): void {
    this.notificationHistory = this.showOnlyUnread ? this.unreadMessageHistory : this.fullMessageHistory;
    this.hasHistory = this.notificationHistory.length > 0;
  }

  private populateHistoryList(history: INotificationMessage[]): void {
    history.sort((a, b) => (b.createdAt.valueOf() > a.createdAt.valueOf() ? 1 : -1));

    this.fullMessageHistory = [];
    this.unreadMessageHistory = [];

    history.forEach((message: INotificationMessage) => {
      const withTruncation: NotificationWithTruncation = {
        ...message,
        shouldTruncate: this.shouldTruncate(message.body),
      };

      this.fullMessageHistory.push(withTruncation);
      if (!withTruncation.hasRead) {
        this.unreadMessageHistory.push(withTruncation);
      }
    });

    this._setNotificationHistory();
    this.unseenCount.emit(this.unreadMessageHistory.length);
  }

  private reloadMessages(obs: Observable<any>): void {
    // Since we switchMap to an http observable the observable automatically gets unsubscribed
    obs
      .pipe(
        tap(() => (this.isLoading = true)),
        switchMap(() =>
          this.notificationService.getNotifications().pipe(
            tap((res) => this.stateService.refreshNotifications(res)),
            finalize(() => (this.isLoading = false))
          )
        )
      )
      .subscribe((res) => this.populateHistoryList(res));
  }
}
