import { Component, Inject, HostListener, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const defaultColour = '#D84315';

@Component({
  selector: 'app-colour-picker-dialog',
  templateUrl: './colour-picker-dialog.component.html',
  styleUrls: ['./colour-picker-dialog.component.scss'],
})
export class ColourPickerDialogComponent implements OnDestroy {
  colours: string[];
  defaultColours: string[];
  colorPickerToggle: boolean[];
  private _destroy$ = new Subject();

  @HostListener('click', ['$event.target'])
  onClick(event: HTMLElement): void {
    if (!event.closest('.colour-picker-button') && this.colorPickerToggle.some((x: boolean) => x)) {
      this.colorPickerToggle = this.colours.map(() => false);
    } else if (!event.closest('mat-dialog-container')) {
      this.dialogRef.close();
    }
  }

  constructor(
    private dialogRef: MatDialogRef<ColourPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { current: string[]; default: string[] }
  ) {
    this.colours = data.current.slice();
    this.defaultColours = data.default.slice();
    this.colorPickerToggle = this.colours.map(() => false);

    dialogRef.disableClose = true;
    dialogRef
      .backdropClick()
      .pipe(takeUntil(this._destroy$))
      .subscribe((mouseClick: MouseEvent) => this.onClick(mouseClick.target as HTMLElement));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  open(index: number): void {
    this.colorPickerToggle = this.colours.map(() => false);
    this.colorPickerToggle[index] = true;
  }

  getPickerToggle(index: number): boolean {
    return this.colorPickerToggle[index];
  }

  update(colour: string, index: number): void {
    this.colours[index] = colour;
  }

  reset(): void {
    this.colours = this.defaultColours.slice();
  }

  add(): void {
    this.colours.push(defaultColour.toString());
    this.colorPickerToggle.push(false);
  }

  remove(index: number): void {
    this.colours.splice(index, 1);
    this.colorPickerToggle.splice(index, 1);
  }

  save(): void {
    this.dialogRef.close(this.colours);
  }

  trackColour(index: number, item: string): number {
    return index;
  }
}
