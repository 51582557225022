import { Injectable } from '@angular/core';
import { RepositoryBaseService } from '.';
import { Observable } from 'rxjs';
import { GraphTile } from '../../interfaces/definitions/graph.definition';
import { map } from 'rxjs/operators';

export const CONFIG_URI = 'configuration';
export const CONFIG_USER_COLUMNS_URI = `${CONFIG_URI}/columns/user`;
export const CONFIG_USER_GRAPH_URI = `${CONFIG_URI}/graph/user`;
export const CONFIG_TENANT_GRAPH_URI = `${CONFIG_URI}/graph/tenant`;

export interface IConfigColumnLayout {
  model: string;
  fields: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ConfigRepositoryService {
  constructor(private repo: RepositoryBaseService) {}

  public getUserColumnsConfig() {
    return this.repo.getData(CONFIG_USER_COLUMNS_URI);
  }

  public updateUserColumnsConfig(columnsType: string, columns: string[]) {
    const columnLayout: IConfigColumnLayout = {
      model: columnsType,
      fields: columns,
    };
    return this.repo.update(CONFIG_USER_COLUMNS_URI, columnLayout);
  }

  public getGraphLists() {
    return this.repo.getData(CONFIG_USER_GRAPH_URI);
  }

  public getTenantDefaultGraphConfig(): Observable<{ colourScheme: string[]; layout: GraphTile[] }> {
    return this.repo.getData(CONFIG_TENANT_GRAPH_URI).pipe(
      map((res) => {
        // replace null colour schemes with the global default
        for (const graph of res.layout) {
          if (!graph.colourScheme) {
            graph.colourScheme = res.colourScheme;
          }
        }
        return res;
      })
    );
  }

  public saveUserGraph(graphs: GraphTile[]) {
    const graphStr = JSON.stringify(
      graphs.map((g) => {
        // remove complex objects from post
        return {
          type: g.type,
          cols: g.cols,
          rows: g.rows,
          filterName: g.filter.display,
          repo: g.repo,
          colourScheme: g.colourScheme,
        };
      })
    );
    return this.repo.update(CONFIG_USER_GRAPH_URI, graphStr);
  }

  public saveUserColourScheme(graphs: GraphTile[]) {
    const graphStr = JSON.stringify(
      graphs.map((g) => {
        // remove complex objects from post
        return {
          type: g.type,
          cols: g.cols,
          rows: g.rows,
          filterName: g.filter.display,
          repo: g.repo,
          colourScheme: g.colourScheme,
        };
      })
    );
    return this.repo.update(CONFIG_USER_GRAPH_URI, graphStr);
  }

  public saveTenantDefault(graphs: GraphTile[], colourScheme: string[]) {
    const graphStr = JSON.stringify({
      layout: graphs.map((g) => {
        // remove complex objects from post
        return {
          type: g.type,
          cols: g.cols,
          rows: g.rows,
          filterName: g.filter.display,
          repo: g.repo,
          colourScheme: g.colourScheme,
        };
      }),
      colourScheme,
    });
    return this.repo.update(CONFIG_TENANT_GRAPH_URI, graphStr);
  }

  public getMaintenanceFormDefaults() {
    // Should rename the api endpoint, now that the web uses these defaults also, or create
    // a new endpoint specific for the web.
    return this.repo.getData(`${CONFIG_URI}/ios`);
  }
}
