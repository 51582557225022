import { IEnvironment } from 'src/app/core/interfaces/definitions/environment.definition';

export const defaultEnvironment: IEnvironment = {
  production: false,
  urlAddress: 'https://localhost:5001',
  assetCityUrl: 'https://assetcity.ca',
  arcGisVersion: '4.14',
  firebaseOptions: {
    appId: '1:808855789119:web:1c655b4f2b419d06eee28a',
    apiKey: 'AIzaSyAjeE8kKtNOO2vt--0zIQ1Gbsnu4OAYv3U',
    projectId: 'asset-city',
    messagingSenderId: '808855789119',
  },
  dateTimeLocale: 'en-US',
  appCenterSignsAppSecret: 'e9415166-803e-4e37-a957-74351c9333bd',
};
