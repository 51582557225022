import { Input, OnDestroy, Directive } from '@angular/core';
import { EsriMapService } from 'src/app/core/services/esri-map.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { POSITION_TOP_LEFT, WID_SKETCH_ID } from 'src/app/core/esri/constants/constants';

@Directive({
  selector: '[appSketchWidget]',
})
export class SketchDirective implements OnDestroy {
  /**
   * Input dataSubject: observable let's us know when the map info is available.
   */
  @Input()
  set mapInit(init$: Observable<void>) {
    // Clean up any previous subscriptions
    this._destroy$.next();

    init$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this._mapService.loadSketchWidget(this.sketchPosition, this.sketchExpandable));
  }
  /**
   * Input position: the widget's positioning within the map
   */
  @Input() sketchPosition: string = POSITION_TOP_LEFT;
  /**
   * Input expandable: whether or not the widget is contained in an Expand widget.
   */
  @Input() sketchExpandable = true;
  /**
   * Class Fields
   */
  private _destroy$ = new Subject();

  constructor(private _mapService: EsriMapService) {}

  ngOnDestroy(): void {
    // FIXME: temporarily remove/destroy the sketch widget -- map service should handle adding/removing
    // without destroying the actual widget, so we can reuse it when needed
    this._mapService.removeSketchWidget(`${WID_SKETCH_ID}-expand`);

    this._destroy$.next();
    this._destroy$.complete();
  }
}
