import { Inject, Component } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { GraphTile } from 'src/app/core/interfaces/definitions/graph.definition';
import { IFilter } from 'src/app/core/interfaces/definitions/filter.definition';
import { GraphBuilderService } from 'src/app/core/services/graph/graph-builder.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColourPickerDialogComponent } from 'src/app/components/dialogs/colour-picker-dialog/colour-picker-dialog.component';

/**
 * Component that allows editing of the GraphTiles, see [[GraphTileComponent]]
 * for more details on the inheritance pattern (it's the same idea).
 */
@Component({
  selector: 'app-graph-designer',
  templateUrl: './graph-designer.component.html',
  styleUrls: ['./graph-designer.component.scss'],
})
export class GraphDesignerComponent {
  maxColSize: number;
  graph: GraphTile;
  graphFilters: IFilter[];
  private _graphBuilderService: GraphBuilderService;
  private defaultColours: string[];

  constructor(
    private _matDialog: MatDialog,
    private _matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      graph: GraphTile;
      defaultColourScheme: string[];
      service: GraphBuilderService;
      maxColSize: number;
    }
  ) {
    this._graphBuilderService = data.service;
    this.defaultColours = data.defaultColourScheme.slice();

    // copy it so it doesn't update the graph display while editing
    this.graph = {
      cols: data.graph.cols,
      rows: data.graph.rows,
      type: data.graph.type,
      repo: data.graph.repo,
      filter: data.graph.filter,
      filterName: data.graph.filterName,
      redirectUrl: data.graph.redirectUrl,
      colourScheme: data.graph.colourScheme,
    };
    this.maxColSize = data.maxColSize;
    this._setFilters();
  }

  graphFilterCompare(first: IFilter, second: IFilter): boolean {
    return first && second && first.display === second.display && first.propertyName === second.propertyName;
  }

  updateSize(dimension: 'row' | 'col', model: NgModel): void {
    if (typeof model.value === 'number') {
      if (dimension === 'row') {
        this.graph.rows = 1 <= model.value ? model.value : 1;
        model.control.setValue(this.graph.rows, { emitEvent: false });
      } else {
        this.graph.cols = 1 <= model.value && model.value <= this.maxColSize ? model.value : this.maxColSize;
        model.control.setValue(this.graph.cols, { emitEvent: false });
      }
    }
  }

  updateFilters(): void {
    this._setFilters();
    this._graphBuilderService.setGraphFilter(this.graph, this.graphFilters);
  }

  openColourList(): void {
    const dialogRef = this._matDialog.open(ColourPickerDialogComponent, {
      data: {
        current: this.graph.colourScheme,
        default: this.defaultColours,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.graph.colourScheme = res;
      }
    });
  }

  apply(): void {
    this._matDialogRef.close(this.graph);
  }

  private _setFilters(): void {
    this.graphFilters = this._graphBuilderService.getFilterChoices(this.graph);
  }
}
