import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppModuleTenantAssignmentsService } from '../services/app-module-tenant-assignments.service';
import { IAppModule } from '../interfaces/models';

@Injectable({
  providedIn: 'root',
})
export class ModuleGuard implements CanActivate {
  constructor(private _router: Router, private _appModuleService: AppModuleTenantAssignmentsService) {}

  /*
   * Validate that the tenant has permission to proceed to the given module route. Return an
   * observable, which the routing will wait for to resolve true or false.
   */
  canActivate(_router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const moduleRoute = _router.routeConfig.path;
    return this._appModuleService.tenantAppModules.pipe(
      switchMap((moduleAssignments: IAppModule[]) => {
        for (const moduleAssignment of moduleAssignments) {
          if (moduleAssignment.webRoute === moduleRoute) {
            return of(true);
          }
        }

        // Permission not found, display forbidden access error page.
        return this._router.navigate([{ outlets: { primary: '403' } }]);
      })
    );
  }
}
