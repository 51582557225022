export const popupTemplate = {
  content: [
    {
      type: 'fields',
      fieldInfos: [
        // {
        //   label: 'Road Segment ID',
        //   fieldName: 'SNS_ID',
        // },
        {
          label: 'Road',
          fieldName: 'streetName',
        },
        {
          label: 'From',
          fieldName: 'streetBack',
        },
        {
          label: 'To',
          fieldName: 'streetAhead',
        },
      ],
    },
  ],
};
