import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-options-dialog',
  styleUrls: ['./options-dialog.component.scss'],
  templateUrl: './options-dialog.component.html',
})
export class OptionsDialogComponent implements OnInit {
  title: string;
  message: string;
  options: { name: string; value: any; color: 'primary' | 'accent' | 'warn' }[];

  constructor(public dialogRef: MatDialogRef<OptionsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.title = data.title ?? '';
      this.message = data.message;
      this.options = data.options;
    }
  }

  ngOnInit(): void {}

  onSubmit(value: any): void {
    this.close(value);
  }

  onNoClick(): void {
    this.onCancel();
  }

  onCancel(): void {
    this.close(null);
  }

  close(result: any): void {
    this.dialogRef.close(result);
  }
}
