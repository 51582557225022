import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IAppModule, IAppModuleTenantAssignment } from '../interfaces/models';
import { SharedRepositoryService } from './repositories';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

/**
 * Service to cache the current user's tenant's available app modules.
 * Cleared by the AuthenticationService on logout.
 */
@Injectable({
  providedIn: 'root',
})
export class AppModuleTenantAssignmentsService {
  get tenantAppModules(): Observable<IAppModule[]> {
    if (!this._tenantAppModules) {
      this._tenantAppModules = this._repo
        .getAppModuleTenantAssignmentsByTenant({
          fields: ['AppModule'],
          sort: {},
          filters: null,
          pagination: {},
        })
        .pipe(
          map((res: IAppModuleTenantAssignment[]) => res.map((x) => x.appModule)),
          shareReplay(1),
          takeUntil(this.refresh)
        );
    }
    return this._tenantAppModules;
  }

  private _tenantAppModules: Observable<IAppModule[]>;
  private refresh = new Subject<void>();

  constructor(private _repo: SharedRepositoryService) {}

  public logout() {
    this.refresh.next();
    this._tenantAppModules = null;
  }
}
