export interface IErrorDef {
  code?: string;
  title: string;
  message: string;
  url?: string;
}

export function isErrorDef(obj: any): obj is IErrorDef {
  return obj.code !== undefined && obj.title !== undefined && obj.message !== undefined && obj.url !== undefined;
}
