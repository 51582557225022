import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';
import { IQueryParam } from '../../interfaces/query/query';
import { IQueryResult, isQueryResult } from '../../interfaces/models/query-result.model';
import { QueryService } from '../query.service';
import { IPatch } from '../../interfaces/patch';

/** Prefix that comes after the scheme and host of the URI, but before the path. */
export const API_ENDPOINT_PREFIX = 'api';

/**
 * Repository service to handle API calls.
 */
@Injectable({
  providedIn: 'root',
})
export class RepositoryBaseService {
  private _cachableUrls: string[] = [];

  /**
   * Constructor
   * @param _http (HttpClient): the http client object
   * @param _envUrl (EnvironmentService): service to get the url to the api based on current environment
   * @param _queryService (QueryService): service to handle converting query string param to query param object.
   */
  constructor(private _http: HttpClient, private _envUrl: EnvironmentService, private _queryService: QueryService) {}

  private _setUrlCachable(url: string): void {
    if (!this.isUrlCachable(url)) {
      this._cachableUrls.push(url);
    }
  }

  public isUrlCachable(url: string): boolean {
    return this._cachableUrls.includes(url);
  }

  /**
   * Return the start of the api url (ie. host name), based on the current environtment.
   * @param includePathPrefix (boolean): include the API_ENDPOINT_PREFIX or not (default = true)
   */
  public getEvnUrl(includePathPrefix: boolean = true) {
    const url = this._envUrl.getApiUrl();
    if (includePathPrefix) {
      return `${url}/${API_ENDPOINT_PREFIX}`;
    } else {
      return url;
    }
  }

  /**
   * Return the fully qualified api route.
   * @param route (string): the part of the api route starting from the controller
   * @param envAddress (string): the part of the api route containing the host
   */
  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}/${route}`;
  }

  /**
   * Return the http request options object.
   * @param contentType (string): the request content type (default = json)
   * @param responseType (string): the response type (default = json)
   */
  private getOptions(contentType: string, responseType: string) {
    const options: any = {};

    if (contentType) {
      if (contentType) {
        options.headers = new HttpHeaders({ 'Content-Type': contentType });
      }
    }

    if (responseType) {
      options.responseType = responseType;
    }

    return options;
  }

  /**
   * Construct an http GET request and handle any http errors with the Error Handler service
   * @param route (string): the route for the GET request
   */
  public getData(
    route: string,
    query: IQueryParam = null,
    cachable: boolean = false,
    returnItemsOnly: boolean = false,
    httpEvents?: object
  ): Observable<any> {
    let requestUrl: string = this.createCompleteRoute(route, this.getEvnUrl());
    if (cachable) {
      this._setUrlCachable(requestUrl);
    }

    if (query) {
      const queryUrl = this._queryService.toUrlStr(query);
      requestUrl = `${requestUrl}${queryUrl}`;
    }

    return this._http.get(requestUrl, httpEvents).pipe(
      map((res) => {
        if ((query === null || returnItemsOnly) && isQueryResult(res)) {
          // Return items only from query result
          const queryResult = res as IQueryResult;
          return queryResult.items;
        } else {
          return res;
        }
      })
    );
  }

  /**
   * Construct an http POST request and handle any http errors with the Error Handler service
   * @param route (string): the route for the POST request
   * @param body (any): the body to pass with the request
   * @param contentType (string): the request content type (default = json)
   * @param responseType (string): the response type (default = json)
   * @param verbose (boolean): verbose error, if any.
   */
  public create(
    route: string,
    body: any,
    contentType: string = 'application/json',
    responseType: string = 'json',
    verbose: boolean = true
  ) {
    return this._http.post(
      this.createCompleteRoute(route, this.getEvnUrl()),
      body,
      this.getOptions(contentType, responseType)
    );
  }

  /**
   * Construct an http PUT request and handle any http errors with the Error Handler service
   * @param route (string): the route for the http request
   * @param body (any): the body to pass with the request
   * @param contentType (string): the request content type (default = json)
   * @param responseType (string): the response type (default = json)
   */
  public update(route: string, body, contentType: string = 'application/json', responseType: string = 'json') {
    return this._http.put(
      this.createCompleteRoute(route, this.getEvnUrl()),
      body,
      this.getOptions(contentType, responseType)
    );
  }

  /**
   * Construct a patch value for the body to pass to construct an http PATCH request.
   * @param route (string): the route for the http request
   * @param path (string): the path for patch object
   * @param value (any): the value for the patch object
   */
  public patchReplace(route: string, path: string, value: any) {
    return this.patchReplaceMany(route, [[path, value]]);
  }

  /**
   * Construct a patch value for the body to pass to construct an http PATCH request.
   * @param route (string): the route for the http request
   * @param values (tuple<string,any>): the path/value pairs for the patch list
   */
  public patchReplaceMany(route: string, values: [string, any][]) {
    const patchValues: IPatch[] = [];
    for (const val of values) {
      patchValues.push({
        op: 'replace',
        path: `/${val[0]}`,
        value: val[1],
      });
    }

    return this.patch(route, patchValues);
  }

  /**
   * Construct an http PATCH request and handle any http errors with the Error Handler service
   * @param route (string): the route for the http request
   * @param body (any): the body for the http request
   * @param contentType (string): the request content type (default = json)
   * @param responseType (string): the response type (default = json)
   */
  public patch(route: string, body, contentType: string = 'application/json', responseType: string = 'json') {
    return this._http.patch(
      this.createCompleteRoute(route, this.getEvnUrl()),
      body,
      this.getOptions(contentType, responseType)
    );
  }

  /**
   * Construct an http DELETE request and handle any http errors with the Error Handler service
   * @param route (string): the route for the http request
   */
  public delete(route: string) {
    return this._http.delete(this.createCompleteRoute(route, this.getEvnUrl()));
  }
}
