import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RequestCacheService } from './../services';

/**
 * Interceptor to add the authorization header to the JWT token to each Http Request.
 */
@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private _cache: RequestCacheService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Continue if not cachable.
    if (!this._cache.isCachable(request)) {
      return next.handle(request);
    }

    const cachedResponse = this._cache.get(request);
    return cachedResponse ? of(cachedResponse) : this._sendRequest(request, next, this._cache);
  }

  private _sendRequest(
    request: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCacheService
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          cache.put(request, event);
        }
      })
    );
  }
}
