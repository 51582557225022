import { Component, Inject, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  styleUrls: ['./confirm-dialog.component.css'],
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  /**
   * Inputs for dialog
   */
  @Input() templateRef: TemplateRef<any>;
  @Input() title: string;
  @Input() message: string;
  @Input() hideCancel = false;
  @Input() reject = 'CANCEL';
  @Input() accept = 'OK';
  @Output() finished = new EventEmitter<boolean>();

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.title = data.hasOwnProperty('title') ? data.title : '';
      this.message = data.message;
      this.hideCancel = data.hasOwnProperty('hideCancel') ? data.hideCancel : false;

      if (data.accept) {
        this.accept = data.accept;
      } else {
        this.accept = 'OK';
      }

      if (data.reject) {
        this.reject = data.reject;
      } else {
        this.reject = 'CANCEL';
      }
    }
  }

  public hideRejectButton(): boolean {
    return this.hideCancel;
  }

  public onSubmit() {
    this.close(true);
  }

  public onNoClick(): void {
    this.close(null);
  }

  public onCancel(): void {
    this.close(false);
  }

  public close(result: any): void {
    if (this.finished.observers.length <= 0) {
      this.dialogRef.close(result);
    } else {
      this.finished.emit(result);
    }
  }
}
