import { Pipe, PipeTransform } from '@angular/core';
import { GraphTile } from 'src/app/core/interfaces/definitions/graph.definition';
import {
  requestRepoSelector,
  maintenanceRepoSelector,
  installedSignRepoSelector,
} from 'src/app/core/services/graph/sign-graph-builder.service';

@Pipe({
  name: 'graph',
})
export class GraphPipe implements PipeTransform {
  transform(graph: GraphTile, property?: 'filter' | 'repo'): string {
    if (graph) {
      switch (property) {
        case 'repo':
          switch (graph.repo) {
            case requestRepoSelector:
              return 'Work Order Requests';
            case maintenanceRepoSelector:
              return 'Work Order Maintenances';
            case installedSignRepoSelector:
              return 'Installed Traffic Signs';
            default:
              console.error('Graph repository unknown');
              return '';
          }
        case 'filter':
        default:
          return graph.filter.display;
      }
    } else {
      return '';
    }
  }
}
