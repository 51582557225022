import { IActionItem } from 'src/app/core/interfaces/definitions/action-item.definition';

/**
 * List of action item ids
 *
 * Generic actions
 */
export const ACTION_CREATE = 'create';
export const ACTION_DUPE = 'dupe';
export const ACTION_EDIT = 'edit';
export const ACTION_DELETE = 'delete';
export const ACTION_EXPORT = 'export';
/** View Actions */
export const ACTION_VIEW_LIST = 'view-list';
export const ACTION_VIEW_MAP = 'view-map';
/** Work Order Request specific actions */
export const ACTION_WOR_ASSIGN = 'assign';
export const ACTION_WOR_START = 'start';
/** Work Order Maintenance specific actions */
export const ACTION_WOM_REVIEW = 'review';
export const ACTION_WOM_UNPLANNED = 'wom-unplanned';
/** User actions */
export const ACTION_RETIRE_USER = 'retire-user';

/**
 * List of action item descriptors.
 * Used to build action lists (ex. toolbar actions, menu actions, etc.)
 */
const actionItems: IActionItem[] = [
  {
    id: ACTION_CREATE,
    name: 'Create',
    icon: 'add',
    tooltip: 'Create new item',
    requireData: false,
    confirmExec: false,
    confirmExecMsg: null,
  },
  {
    id: ACTION_DUPE,
    name: 'Duplicate',
    icon: 'file_copy',
    tooltip: 'Create new item by duplicating selected item',
    requireData: true,
    confirmExec: false,
    confirmExecMsg: null,
  },
  {
    id: ACTION_EDIT,
    name: 'Edit',
    icon: 'edit',
    tooltip: 'Edit selected item',
    requireData: true,
    confirmExec: false,
    confirmExecMsg: null,
  },
  {
    id: ACTION_DELETE,
    name: 'Delete',
    icon: 'delete',
    tooltip: 'Delete selected items',
    requireData: true,
    confirmExec: true,
    confirmExecMsg: 'Are you sure you want to delete the selected items? This cannot be undone.',
  },
  {
    id: ACTION_EXPORT,
    name: 'Export...',
    icon: 'file_copy',
    tooltip: 'Export the data',
    requireData: false,
    confirmExec: false,
    confirmExecMsg: null,
  },
  {
    id: ACTION_WOR_ASSIGN,
    name: 'Assign To Field Staff',
    icon: 'person_add',
    tooltip: 'Assign Field Staff worker to item',
    requireData: true,
    confirmExec: false,
    confirmExecMsg: null,
  },
  {
    id: ACTION_WOM_REVIEW,
    name: 'Review Maintenance',
    icon: 'rate_review',
    tooltip: 'Review item for approval',
    requireData: true,
    confirmExec: false,
    confirmExecMsg: null,
  },
  {
    id: ACTION_WOR_START,
    name: 'Start Work',
    icon: 'play_circle_filled',
    tooltip: 'Start working on selected item',
    requireData: true,
    confirmExec: false,
    confirmExecMsg: null,
  },
  {
    id: ACTION_WOM_UNPLANNED,
    name: 'Start Unplanned Maintenance',
    icon: 'add_circle',
    tooltip: 'Create Work Order Maintenance for unplanned work',
    requireData: false,
    confirmExec: false,
    confirmExecMsg: null,
  },
  {
    id: ACTION_VIEW_LIST,
    name: 'List View',
    icon: 'view_list',
    tooltip: 'Show List View',
    requireData: false,
    confirmExec: false,
    confirmExecMsg: null,
  },
  {
    id: ACTION_VIEW_MAP,
    name: 'Map View',
    icon: 'view_array',
    tooltip: 'Show Map View',
    requireData: false,
    confirmExec: false,
    confirmExecMsg: null,
  },
  {
    id: ACTION_RETIRE_USER,
    name: 'Retire User',
    icon: 'person_off',
    tooltip: 'Set Retired Status For A User',
    requireData: true,
    confirmExec: true,
    confirmExecMsg: "Are you sure you want to change this user's retirement status?",
  },
];

/**
 * Action id to descritor mapping.
 */
const actionItemMap = {};
actionItems.forEach((item) => (actionItemMap[item.id] = item));

/**
 * Convenience method to get action item and set the event emitter
 */
export function getAction(action: string): IActionItem {
  return actionItemMap[action];
}
