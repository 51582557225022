import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, retryWhen, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EnvironmentService, roadApiUrl } from '../services';
import { ARC_GIS_HOSTS } from '../constants/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';

const roadServerError = 'Could not fetch road segments, this should be a temporary problem with the ArcGIS server.';
const arcgisError = 'Could not load map tiles. This appears to be a temporary problem.';
const contactAdmin = 'Please contact your server administrator if the problem persists.';
const contactTransPlan = 'Please contact Trans-Plan Inc. if the problem persists.';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _router: Router, private _env: EnvironmentService, private _dialog: MatDialog) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen(this.retryRequest()),
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          // time outs
          case 0:
          case 504:
            return throwError({
              code: '0',
              title: 'No Response',
              message: 'Could not establish a connection with the API, please check your internet connection.',
            });
          case 403:
          case 404:
            this._router.navigate([err.status]);
            return of(null);
          case 400:
          case 500:
            const hostname: string = new URL(err.url).hostname;

            // API
            if (new URL(this._env.getApiUrl()).hostname.match(hostname)) {
              // These errors are handled by the repository/subscription
              return throwError(err);
            }
            // ArcGIS
            else if (ARC_GIS_HOSTS.includes(hostname)) {
              const errorMessage = `${arcgisError} ${contactTransPlan}`;
              this._dialog.open(ConfirmDialogComponent, { data: { message: errorMessage, hideCancel: true } });
            }
            // Road Server
            else if (new URL(localStorage.getItem(roadApiUrl)).hostname.match(hostname)) {
              const errorMessage = `${roadServerError} ${contactAdmin}`;
              this._dialog.open(ConfirmDialogComponent, { data: { message: errorMessage, hideCancel: true } });
            }
            return of(null);
        }

        let errMessage = err && err.error ? err.error.message : err.message;
        errMessage = !!errMessage ? errMessage : 'Unknown';
        return throwError({ code: err.status, title: err.name, message: errMessage });
      })
    );
  }
  /**
   * Retry a source observable before throwing an error.
   * @param request The source observable.
   * @param maxRetryAttempts The number of times to retry before throwing an error.
   * @param scalingDuration The number of milliseconds to scale the interval between each retry.
   */
  retryRequest(
    maxRetryAttempts: number = 3,
    scalingDuration: number = 1000
  ): (attempts: Observable<any>) => Observable<number> {
    return (attempts: Observable<any>) =>
      attempts.pipe(
        mergeMap((error, i) =>
          !(++i > maxRetryAttempts || error.status !== 0) ? timer(i * scalingDuration) : throwError(error)
        )
      );
  }
}
