import { IQueryFilter, QueryFilterExpression } from 'src/app/core/interfaces/query/filters/query-filter';
import { Observable, iif } from 'rxjs';
import { FormControl } from '@angular/forms';
import { FilterInput } from '../filter-input';
import { OnInit, OnDestroy } from '@angular/core';
import {
  QueryFilterBinaryExpression,
  QueryFilterAnd,
  QueryFilterOr,
} from 'src/app/core/interfaces/query/filters/query-filter-binary';
import { Q_OR, Q_AND } from 'src/app/core/constants/query.constants';
import { switchMap, map, startWith, tap, takeUntil, take } from 'rxjs/operators';
import { UtilsService } from 'src/app/core/services/utils.service';
import { QueryFilterNot } from 'src/app/core/interfaces/query/filters/query-filter-not';

export type FilterSelection = IQueryFilter & { selected?: boolean };

export class OptionsFilterInput extends FilterInput implements OnInit, OnDestroy {
  public currentFilters: FilterSelection[];
  public filterControlChoices: Observable<FilterSelection[]>;
  protected allFilters: Observable<FilterSelection[]>;

  constructor(utilsService: UtilsService) {
    super(utilsService);
    this.control = new FormControl(null);
  }

  ngOnInit() {
    this.allFilters = this.filter.choices.pipe(map((qfs: IQueryFilter[]) => Object.assign(qfs, { selected: false })));

    // retreive the choices defined
    this.allFilters
      .pipe(
        tap((filters: FilterSelection[]) => (this.currentFilters = filters)),
        take(1)
      )
      .subscribe();

    // whenever the user types in the input, filter the list of available options by that input
    this.filterControlChoices = this.control.valueChanges.pipe(
      startWith(this.filter.filter.value),
      switchMap((filterStr: string) => {
        // filter the options based on the current control text
        return iif(
          () => filterStr && typeof filterStr === 'string',
          this.allFilters.pipe(
            map((filters: FilterSelection[]) =>
              filters.filter((f: FilterSelection) => f.displayName.toLowerCase().includes(filterStr))
            )
          ),
          this.allFilters
        );
      })
    );

    /**
     * Keep the filter value updated when it changes outside of this component and also
     * keep the contol and selection in sync when the filter value changes.
     */
    this.filter.filter.pipe(takeUntil(this._destroy$)).subscribe((res) => {
      this.control.setValue(res, { emitEvent: false });
      if (!res && this.currentFilters) {
        for (const f of this.currentFilters) {
          f.selected = false;
        }
      }
    });

    const defaultVal = this.filter.filter.value;
    if (defaultVal) {
      this.buildInitSelection(defaultVal);
    }
  }

  /**
   * Given an initial (possibly conjoined) filter, break it apart into individual filters
   * so it can be used to set the current controls and selection.
   */
  private buildInitSelection(initial: IQueryFilter) {
    if (initial instanceof QueryFilterBinaryExpression) {
      this.buildInitSelection(initial.left);
      this.buildInitSelection(initial.right);
    } else if (initial instanceof QueryFilterExpression || initial instanceof QueryFilterNot) {
      const existingFilter = this.currentFilters.find((f) => f.cmpQueryfilters(initial));
      if (!!existingFilter) {
        existingFilter.selected = true;
      }
    }
  }

  /**
   * Joins a list of query filters into a single query filter to be passed back
   * to the parent component to make the query.
   * @param value The list of filter values to join.
   * @param op The operator to join the filters by.
   */
  protected reduceOptionsFilter(value: FilterSelection[], op: string) {
    if (value && value.length === 1) {
      const f = value[0];
      return f.selected ? f : null;
    }
    switch (op) {
      case Q_AND:
        return value.reduce((curr: IQueryFilter, prev: IQueryFilter) => {
          return new QueryFilterAnd(curr, prev, `${curr.displayName} ${Q_AND} ${prev.displayName}`);
        });
      case Q_OR:
      default:
        return value.reduce((curr: IQueryFilter, prev: IQueryFilter) => {
          return new QueryFilterOr(curr, prev, `${curr.displayName} ${Q_OR} ${prev.displayName}`);
        });
    }
  }

  /**
   * If the current value has changed in the control, emit
   * the value to subscribers if it's different then the current filter's value.
   */
  protected emitIfChanged() {
    // update the control
    const currentSelection = this.currentFilters.filter((f) => f.selected);
    if (currentSelection.length) {
      const newValue = this.reduceOptionsFilter(currentSelection, Q_OR);
      if (this.hasChanged(newValue)) {
        this.filter.filter.next(newValue);
      }
    } else {
      this.clearFilter();
      this.control.setValue('');
    }
  }
}
