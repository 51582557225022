import { Injectable } from '@angular/core';
import { RepositoryBaseService } from './repository-base.service';
import { IFieldTypeLocation, IInventoryStatus, IOwner, IOwnerType } from '../../interfaces/models';
import { IQueryParam } from '../../interfaces/query/query';
import { WORK_ORDER_REQUEST_URI, WORK_ORDER_MAINTENANCE_URI } from './work-order-repository.service';
import { INSTALLED_SIGN_URI, SIGN_TYPE_URI } from './traffic-sign-repository.service';

// API endpoint constants
export const BASE_UNIT_URI = 'baseunit';
export const DIRECTION_URI = 'direction';
export const FIELD_TYPE_LOCATION_URI = 'fieldlocationtype';
export const INVENTORY_STATUS_URI = 'inventoryitemstatus';
export const OWNER_TYPE_URI = 'ownertype';
export const OWNER_URI = 'owner';
export const CITY_URI = 'city';
export const APP_MODULE_VERSION_URI = 'appmoduleversion';
export const APP_MODULE_TENANT_ASSIGNMENT_URI = 'appmoduletenantassignment';
export const APP_MODULE_URI = 'appmodule';
export const FIELD_REPORT_URI = 'reportfromfields';
export const QUERY_REPORT_URI = 'reportfromquery';
//
export const UNIT_SYMBOL_CM = 'cm';
export const UNIT_TYPE_LEN = 'length';
export const UNIT_TYPE_TIME = 'time';
//

@Injectable({
  providedIn: 'root',
})
export class SharedRepositoryService {
  constructor(private repo: RepositoryBaseService) {}

  /**
   * Reports
   */
  public getRequestsReportFromFields(query: IQueryParam) {
    return this.getReport(WORK_ORDER_REQUEST_URI, FIELD_REPORT_URI, query);
  }

  public getRequestsReportFromQuery(query: IQueryParam) {
    return this.getReport(WORK_ORDER_REQUEST_URI, QUERY_REPORT_URI, query);
  }

  public getMaintenanceReportFromFields(query: IQueryParam) {
    return this.getReport(WORK_ORDER_MAINTENANCE_URI, FIELD_REPORT_URI, query);
  }

  public getMaintenanceReportFromQuery(query: IQueryParam) {
    return this.getReport(WORK_ORDER_MAINTENANCE_URI, QUERY_REPORT_URI, query);
  }

  public getInstalledSignsReportFromFields(query: IQueryParam) {
    return this.getReport(INSTALLED_SIGN_URI, FIELD_REPORT_URI, query);
  }

  public getSignsReportFromQuery(query: IQueryParam) {
    return this.getReport(INSTALLED_SIGN_URI, QUERY_REPORT_URI, query);
  }

  public getSignTypesReportFromFields(query: IQueryParam) {
    return this.getReport(SIGN_TYPE_URI, FIELD_REPORT_URI, query);
  }

  public getSignTypesReportFromQuery(query: IQueryParam) {
    return this.getReport(SIGN_TYPE_URI, QUERY_REPORT_URI, query);
  }

  public getReport(objectType: string, reportMethod: string, query: IQueryParam) {
    return this.repo.getData(`${objectType}/${reportMethod}`, query, false, false, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  /**
   * Get
   */
  public getDirections(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(DIRECTION_URI, query, false, returnItemsOnly);
  }

  public getBaseUnits(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(BASE_UNIT_URI, query, false, returnItemsOnly);
  }

  public getAppVersions(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(APP_MODULE_VERSION_URI, query, false, returnItemsOnly);
  }

  public getAppModuleTenantAssignments(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(APP_MODULE_TENANT_ASSIGNMENT_URI, query, false, returnItemsOnly);
  }

  public getAppModules(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(APP_MODULE_URI, query, false, returnItemsOnly);
  }

  public getAppModuleTenantAssignmentsByTenant(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(`${APP_MODULE_TENANT_ASSIGNMENT_URI}/tenant`, query, false, returnItemsOnly);
  }

  public getFieldTypeLocations(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(FIELD_TYPE_LOCATION_URI, query, false, returnItemsOnly);
  }

  public getInventoryStatuses(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(INVENTORY_STATUS_URI, query, false, returnItemsOnly);
  }

  public getOwners(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(OWNER_URI, query, false, returnItemsOnly);
  }

  public getOwnerTypes(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(OWNER_TYPE_URI, query, false, returnItemsOnly);
  }

  public getCities(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(CITY_URI, query, false, returnItemsOnly);
  }

  /**
   * Create
   */
  public createFieldTypeLocation(location: IFieldTypeLocation) {
    return this.repo.create(FIELD_TYPE_LOCATION_URI, location);
  }

  public createInventoryStatus(status: IInventoryStatus) {
    return this.repo.create(INVENTORY_STATUS_URI, status);
  }

  public createOwner(ownerType: IOwner) {
    return this.repo.create(OWNER_URI, ownerType);
  }

  public createOwnerType(ownerType: IOwnerType) {
    return this.repo.create(OWNER_TYPE_URI, ownerType);
  }

  public deleteFieldTypeLocation(id: number) {
    return this.repo.delete(`${FIELD_TYPE_LOCATION_URI}/${id}`);
  }

  public deleteInventoryStatus(id: number) {
    return this.repo.delete(`${INVENTORY_STATUS_URI}/${id}`);
  }

  public deleteOwner(id: number) {
    return this.repo.delete(`${OWNER_URI}/${id}`);
  }

  public deleteOwnerType(id: number) {
    return this.repo.delete(`${OWNER_TYPE_URI}/${id}`);
  }
}
