import { Injectable } from '@angular/core';
import { RepositoryBaseService } from './repository-base.service';
import {
  ITrafficSignCategory,
  ITrafficSignFaceMaterial,
  ITrafficSignBaseType,
  ITrafficSignMountType,
  ITrafficSignSupportType,
  ITrafficSignStatus,
  ITrafficSignCondition,
  ITrafficSignReflectiveCoating,
  ITrafficSignReflectiveRating,
  ITrafficSignTypeCreate,
} from '../../interfaces/models';
import { IQueryParam } from '../../interfaces/query/query';

/*
 * API endpoint constants
 */
export const SIGN_TYPE_URI = 'trafficsigntype';
export const SIGN_CATEGORY_URI = 'trafficsigncategory';
export const SIGN_FACE_MATERIAL_URI = 'trafficsignfacematerial';
export const SIGN_REFLECTIVE_COATING_URI = 'trafficsignreflectivecoating';
export const INSTALLED_SIGN_URI = 'installedtrafficsign';
export const INSTALLED_SIGN_BASE_TYPE_URI = 'installedtrafficsignbasetype';
export const INSTALLED_SIGN_MOUNT_TYPE_URI = 'installedtrafficsignmounttype';
export const INSTALLED_SIGN_SUPPORT_TYPE_URI = 'installedtrafficsignsupporttype';
export const INSTALLED_SIGN_STATUS_URI = 'installedtrafficsignstatus';
export const INSTALLED_SIGN_CONDITION_URI = 'installedtrafficsigncondition';
export const INSTALLED_SIGN_REFLECTIVE_RATING_URI = 'installedtrafficsignreflectiverating';
export const TRAFFIC_SIGN_URI = 'trafficSign';
export const TS_CATEGORY_STREETNAME = 'Street Name';

/**
 * Repository service for Traffic Sign related API endpoints.
 */
@Injectable({
  providedIn: 'root',
})
export class TrafficSignRepositoryService {
  constructor(private repo: RepositoryBaseService) {}

  private _getHistoryUri(uri: string) {
    return `${uri}/history`;
  }

  /**
   * Get
   */
  public getSignTypes(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(SIGN_TYPE_URI, query, false, returnItemsOnly);
  }

  public getCategories(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(SIGN_CATEGORY_URI, query, false, returnItemsOnly);
  }

  public getFaceMaterials(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(SIGN_FACE_MATERIAL_URI, query, false, returnItemsOnly);
  }

  public getReflectiveCoatings(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(SIGN_REFLECTIVE_COATING_URI, query, false, returnItemsOnly);
  }

  public getMountTypes(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(INSTALLED_SIGN_MOUNT_TYPE_URI, query, false, returnItemsOnly);
  }

  public getSupportTypes(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(INSTALLED_SIGN_SUPPORT_TYPE_URI, query, false, returnItemsOnly);
  }

  public getConditions(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(INSTALLED_SIGN_CONDITION_URI, query, false, returnItemsOnly);
  }

  public getStatuses(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(INSTALLED_SIGN_STATUS_URI, query, false, returnItemsOnly);
  }

  public getRatings(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(INSTALLED_SIGN_REFLECTIVE_RATING_URI, query, false, returnItemsOnly);
  }

  public getBaseTypes(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(INSTALLED_SIGN_BASE_TYPE_URI, query, false, returnItemsOnly);
  }

  public getInstalledSigns(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(INSTALLED_SIGN_URI, query, false, returnItemsOnly);
  }

  public getInstalledSign(id: number, query: IQueryParam = null) {
    return this.repo.getData(`${INSTALLED_SIGN_URI}/${id}`, query);
  }

  public getAllTrafficSigns(query?: IQueryParam, returnItemsOnly: boolean = false) {
    return this.repo.getData(`${TRAFFIC_SIGN_URI}`, query, returnItemsOnly);
  }

  public getTrafficSign(id: number) {
    return this.repo.getData(`${TRAFFIC_SIGN_URI}/${id}`);
  }

  public getInstalledSignHistory(id: number) {
    return this.repo.getData(this._getHistoryUri(`${INSTALLED_SIGN_URI}/${id}`));
  }

  /**
   * Create
   */
  public createSignType(signType: ITrafficSignTypeCreate) {
    return this.repo.create(SIGN_TYPE_URI, signType);
  }

  public createCategory(category: ITrafficSignCategory) {
    return this.repo.create(SIGN_CATEGORY_URI, category);
  }

  public createFaceMaterial(obj: ITrafficSignFaceMaterial) {
    return this.repo.create(SIGN_FACE_MATERIAL_URI, obj);
  }

  public createReflectiveCoating(obj: ITrafficSignReflectiveCoating) {
    return this.repo.create(SIGN_REFLECTIVE_COATING_URI, obj);
  }

  public createBaseType(obj: ITrafficSignBaseType) {
    return this.repo.create(INSTALLED_SIGN_BASE_TYPE_URI, obj);
  }

  public createMountType(obj: ITrafficSignMountType) {
    return this.repo.create(INSTALLED_SIGN_MOUNT_TYPE_URI, obj);
  }

  public createSupportType(obj: ITrafficSignSupportType) {
    return this.repo.create(INSTALLED_SIGN_SUPPORT_TYPE_URI, obj);
  }

  public createCondition(obj: ITrafficSignCondition) {
    return this.repo.create(INSTALLED_SIGN_CONDITION_URI, obj);
  }

  public createStatus(obj: ITrafficSignStatus) {
    return this.repo.create(INSTALLED_SIGN_STATUS_URI, obj);
  }

  public createRating(obj: ITrafficSignReflectiveRating) {
    return this.repo.create(INSTALLED_SIGN_REFLECTIVE_RATING_URI, obj);
  }

  /**
   * Delete
   */
  public deleteReflectiveCoating(id: number) {
    return this.repo.delete(`${SIGN_REFLECTIVE_COATING_URI}/${id}`);
  }

  public deleteInstalledSign(id: number) {
    return this.repo.delete(`${INSTALLED_SIGN_URI}/${id}`);
  }

  public deleteFaceMaterial(id: number) {
    return this.repo.delete(`${SIGN_FACE_MATERIAL_URI}/${id}`);
  }

  public deleteCategory(id: number) {
    return this.repo.delete(`${SIGN_CATEGORY_URI}/${id}`);
  }

  public deleteSignType(id: number) {
    return this.repo.delete(`${SIGN_TYPE_URI}/${id}`);
  }

  public deleteBaseType(id: number) {
    return this.repo.delete(`${INSTALLED_SIGN_BASE_TYPE_URI}/${id}`);
  }

  public deleteMountType(id: number) {
    return this.repo.delete(`${INSTALLED_SIGN_MOUNT_TYPE_URI}/${id}`);
  }

  public deleteSupportType(id: number) {
    return this.repo.delete(`${INSTALLED_SIGN_SUPPORT_TYPE_URI}/${id}`);
  }

  public deleteCondition(id: number) {
    return this.repo.delete(`${INSTALLED_SIGN_CONDITION_URI}/${id}`);
  }

  public deleteStatus(id: number) {
    return this.repo.delete(`${INSTALLED_SIGN_STATUS_URI}/${id}`);
  }

  public deleteRating(id: number) {
    return this.repo.delete(`${INSTALLED_SIGN_REFLECTIVE_RATING_URI}/${id}`);
  }
}
