import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-dialog',
  styles: ['form { margin: 5px; }', '.actions { display: flex; justify-content: flex-end }'],
  template: `
    <form [formGroup]="editForm">
      <mat-form-field style="width:100%" (keyup.enter)="onSubmit(editForm.value)">
        <mat-select formControlName="editCtrl" placeholder="{{ editLabel }}">
          <mat-option *ngFor="let option of editOptions" [value]="option">
            {{ editDisplayFn(option) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="actions">
        <button mat-button color="primary" (click)="onCancel()">CANCEL</button>
        <button mat-button *ngIf="showClear" color="primary" (click)="onClear()">CLEAR</button>
        <button mat-stroked-button color="primary" (click)="onSubmit(editForm.value)">{{ submitButton }}</button>
      </div>
    </form>
  `,
})
export class EditDialogComponent {
  public editLabel: string;
  public editOptions: any[];
  public editDisplayFn: (item: any) => string;
  public editForm: FormGroup;
  public submitButton: string;
  public showClear: boolean;

  constructor(public dialogRef: MatDialogRef<EditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.editLabel = data.label;
    this.editOptions = data.options;
    this.editDisplayFn = data.displayFn;
    this.submitButton = data.submitButton ? data.submitButton : 'SAVE';
    this.showClear = data.hasOwnProperty('showClear') ? data.showClear : false;
    this.editForm = new FormGroup({
      editCtrl: new FormControl(data.value),
    });
  }

  public onSubmit(formValue) {
    this.dialogRef.close(formValue.editCtrl);
  }

  public onClear(): void {
    this.dialogRef.close({ id: null });
  }

  public onNoClick(): void {
    this.onCancel();
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
