import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TrafficSignRepositoryService } from './repositories';
import { ITrafficSignType } from '../interfaces/models';

// Buffer size to dteremine the max element count of the replay buffer,
// that is the number of elements that are cached and replayed for every
// subscriber.
// We will set ours to 1 to only replace the most recent value.
const CACHE_SIZE = 1;

/**
 * This Cache service has been replaced by the RequestCache now.
 */
@Injectable({
  providedIn: 'root'
})
export class CacheService {

  // Shared single instance across all subscribers.
  private _cachedTrafficSigns$: Observable<ITrafficSignType[]>;


  constructor(
    private _signsRepo: TrafficSignRepositoryService
  ) { }

  get trafficSigns() {
    if (!this._cachedTrafficSigns$) {
      this._cachedTrafficSigns$ = this._fetchTrafficSigns().pipe(
        shareReplay(CACHE_SIZE)
      );
    }

    return this._cachedTrafficSigns$;
  }

  private _fetchTrafficSigns(): Observable<ITrafficSignType[]> {
    return this._signsRepo.getSignTypes()
      .pipe(map(response => response as ITrafficSignType[]));
  }
}
