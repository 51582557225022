import { Component, OnInit, Input } from '@angular/core';
import { ITableCard } from 'src/app/core/interfaces/definitions/table-card.definition';

@Component({
  selector: 'app-table-card',
  templateUrl: './table-card.component.html',
  styleUrls: ['./table-card.component.scss'],
})
export class TableCardComponent {
  @Input() card: ITableCard;

  constructor() {}
}
