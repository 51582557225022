import { Injectable } from '@angular/core';
import { RepositoryBaseService } from './repository-base.service';

const notificationUri = 'notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private repo: RepositoryBaseService) { }

  public getNotifications() {
    return this.repo.getData(`${notificationUri}`);
  }

  public changeRead(notificationId: number, hasRead: boolean) {
    const patch = [{
        value: hasRead,
        path: '/hasRead',
        op: 'replace'
    }];
    return this.repo.patch(`${notificationUri}/${notificationId}`, patch);
  }
}
