import { Injectable } from '@angular/core';
import { Observable, Subscriber, of } from 'rxjs';
import { AssetRepositoryService } from './repositories/asset-repository.service';
import { IAsset } from '../interfaces/models';
import { mergeMap, take } from 'rxjs/operators';

export interface IAssetFileData {
  data: File | string;
  dataType: 'file' | 'base64' | 'url';
  filename?: string;
  fileInputName?: string;
  note?: string;
  imgData?: string;
}
/** This valeu should be passed as the IAssetFileData fileInputName, unless the API changes the formData name. */
export const ASSET_NAME = 'asset';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private _cache = {};

  constructor(private _assetRepo: AssetRepositoryService) {}

  /**
   * Return the asset from the cache, if it exists, else null.
   * @param url (string): The url defining the asset.
   */
  private _getAssetFromCache(url: string): string | ArrayBuffer {
    if (this._cache.hasOwnProperty(url)) {
      return this._cache[url];
    } else {
      return null;
    }
  }

  /**
   * Return the asset from the cache, if it exits, else go get it from the
   * server.
   * @param url (string): The url defining the asset.
   */
  getAsset(url: string): Observable<string | ArrayBuffer> {
    if (!url) {
      return null;
    }

    const cached = this._getAssetFromCache(url);
    return cached
      ? of(cached)
      : this._assetRepo.downloadAssetByUrl(url).pipe(mergeMap((res: any) => this.getImageFromBlob(res, url)));
  }

  /**
   * that will yield the image as a blob.
   * Retrieve an image by url, from the API. Return an Observable object
   * @param imageUrl (string): the image url.
   */
  getBlobFromUrl(imageUrl: string): Observable<object> {
    const asset: IAsset = { inputFilepath: imageUrl };
    return this._assetRepo.downloadAsset(asset);
  }

  /**
   * Return an Obeservable that yields the string representation of a blob image.
   * @param image (Blob): the Blob containing the image.
   */
  getImageFromBlob(image: Blob, url?: string): Observable<string | ArrayBuffer> {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(image);

    return new Observable((observer: Subscriber<string | ArrayBuffer>): void => {
      fileReader.onload = (ev: ProgressEvent): void => {
        if (url) {
          this._cache[url] = fileReader.result;
        }
        observer.next(fileReader.result);
        observer.complete();
      };

      fileReader.onerror = (error: ProgressEvent): void => {
        observer.error(error);
      };
    });
  }

  /**
   * Return an observable on subscribing will upload an asset based on the given data properties.
   * @param asset : the asset data to upload
   */
  uploadAsset(asset: IAssetFileData): Observable<any> {
    let uploadObservable: Observable<any> = of(null);
    if (asset.data) {
      switch (asset.dataType) {
        case 'file':
          if (asset.data instanceof File) {
            const formData = new FormData();
            formData.append(asset.fileInputName, asset.data);
            uploadObservable = this._assetRepo.uploadAssetFromFormData(formData);
          } else {
            console.error('Invalid data for file upload');
          }
          break;
        case 'base64':
          if (typeof asset.data === 'string') {
            uploadObservable = this._assetRepo.uploadAssetFromStringData(asset.data, asset.filename);
          } else {
            console.error('Invalid data for data upload');
          }
          break;
        case 'url':
          // File has already been uploaded, since we already have the url -- just return it.
          uploadObservable = of(asset.data);
          break;
        default:
          break;
      }
    }

    return uploadObservable;
  }

  /**
   * Set the 'imgData' for the IAssetFileData object -- load data if necessary.
   * @param fileData : the file data object
   * @param reload : true to force reload
   */
  loadFileDataImage(fileData: IAssetFileData, reload: boolean = false): void {
    if (fileData && (!fileData.imgData || reload)) {
      switch (fileData.dataType) {
        case 'url':
          // Load the image data from the url
          const result = this.getAsset(fileData.data as string);
          if (typeof result === 'string') {
            fileData.imgData = result;
          } else if (result) {
            const observable = result as Observable<string | ArrayBuffer>;
            //
            // is this right???
            observable.pipe(take(1)).subscribe((imgData: string) => {
              fileData.imgData = imgData;
            });
          }
          break;
        case 'base64':
          // Just set the data as the file data for base64 byte arrays
          fileData.imgData = fileData.data as string;
          break;
        case 'file':
        default:
          // No img data for these data types
          fileData.imgData = null;
          break;
      }
    }
  }
}
