import { IFilter } from 'src/app/core/interfaces/definitions/filter.definition';
import { BehaviorSubject } from 'rxjs';
import { FilterChoiceService } from 'src/app/core/services/filter-choices.service';

export class InstalledSignGraphFilters extends Array<IFilter> {
  public filters: IFilter[] = [
    {
      display: 'Status',
      propertyName: 'Status.Name',
      type: 'dropdown',
      multi: true,
      choices: this._baseFilters.getSignStatusFilters('Status.Name'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Condition',
      propertyName: 'Condition.Name',
      type: 'dropdown',
      multi: false,
      choices: this._baseFilters.getConditionsObservable('Condition.Name'),
      filter: new BehaviorSubject(null),
    },
  ];

  constructor(private _baseFilters: FilterChoiceService) {
    super();
    this.push(...this.filters);
  }
}
