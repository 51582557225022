import { Component, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IModule } from 'src/app/core/interfaces/definitions/module-route.definition';
import { StateService } from 'src/app/core/services/state.service';
import { threadId } from 'worker_threads';

/**
 * Component for the general application layout (top and side navigation, and content)
 */
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
  /** Class fields */
  isLoading: boolean;
  currentModule: Observable<IModule>;
  isMobile: boolean;

  @HostListener('window:resize', ['$event'])
  resize(): void {
    this.isMobile = this._stateService.isMobile();
  }

  /**
   * Constructor
   */
  constructor(private _router: Router, private _route: ActivatedRoute, private _stateService: StateService) {
    this.isLoading = true;
    this.resize();
    this.currentModule = this._route.data.pipe(map((data: { module: IModule }) => data.module));
  }

  toggleSidenav(): void {
    this._stateService.toggleSidenav();
  }

  /**
   * Navigate to the provided route.
   * @param route (string): the route to navigate to.
   */
  routeChanged(route: string, moduleRoute: string[] = null): void {
    const url = [route];
    if (moduleRoute) {
      url.unshift(...moduleRoute);
    }
    const relativeTo = { outlets: { primary: url } };
    this._router.navigate([relativeTo]).then((navigated: boolean) => {
      if (navigated && this._stateService.isMobile()) {
        this._stateService.toggleSidenav();
      }
    });
  }
}
