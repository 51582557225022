import { Injectable } from '@angular/core';
import { IAssetLocation } from '../../interfaces/models';
import { RepositoryBaseService } from './repository-base.service';
import { IQueryParam } from '../../interfaces/query/query';

// API endpoint constants
export const ASSET_LOCATION_URI = 'assetlocation';

@Injectable({
  providedIn: 'root'
})
export class AssetLocationRepositoryService {

  constructor(private repo: RepositoryBaseService) { }

  public getAllLocations(query: IQueryParam = null, returnItemsOnly: boolean = false) {
    return this.repo.getData(`${ASSET_LOCATION_URI}`, query, false, returnItemsOnly);
  }

  public getLocation(id: number, query: IQueryParam = null, returnItemsOnly: boolean = false) {
    return this.repo.getData(`${ASSET_LOCATION_URI}/${id}`, query, false, returnItemsOnly);
  }

  public createLocation(obj: IAssetLocation) {
    return this.repo.create(ASSET_LOCATION_URI, obj);
  }

  public deleteLocation(id: number) {
    return this.repo.delete(`${ASSET_LOCATION_URI}/${id}`);
  }
}
