import { Injectable } from '@angular/core';
import { IWorkOrderMaintenance } from '../interfaces/models/work-order-maintenance.model';
import { WorkOrderRepositoryService } from './repositories/work-order-repository.service';
import { IAppUser } from '../interfaces/models/app-user.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { NavigationExtras } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { IWorkOrderRequest } from '../interfaces/models/work-order-request.model';
import { QueryService } from './query.service';
import { QueryFilterExpression } from '../interfaces/query/filters/query-filter';
import { Q_OP_EQ } from '../constants/query.constants';
import { WOR_STATUS_NAME, WOR_STATUS_ID } from '../constants/model-properties/work-order-status.properties';
import { BaseDialogComponent } from 'src/app/components/dialogs/base-dialog/base-dialog.component';
import { ReviewMaintenanceFormBuilder } from 'src/app/shared/components/form-builders/review-maintenance.form-builder';

/**
 * Work Order Constants
 *
 * FIXME: define these type and status constants in tenant config
 *
 */
export const WO_TYPE_NEW_INSTALLATION = 'New Installation';
export const WO_TYPE_PLANNED = 'Planned Maintenance';
export const WO_TYPE_UNPLANNED = 'Unplanned Maintenance';
export const WO_STATUS_OPEN = 'Open';
export const WO_STATUS_ASSIGNED_SUPE = 'Assigned To Supervisor';
export const WO_STATUS_ASSIGNED_FS = 'Assigned To Field Staff';
export const WO_STATUS_INPROG = 'In Progress';
export const WO_STATUS_REWORK = 'Rework';
export const WO_STATUS_SUBMITTED = 'Submitted';
export const WO_STATUS_APPROVED = 'Approved';
export const WOR_STATUS_NOT_STARTED = [
  WO_STATUS_OPEN.toLocaleLowerCase(),
  WO_STATUS_ASSIGNED_SUPE.toLocaleLowerCase(),
  WO_STATUS_ASSIGNED_FS.toLocaleLowerCase(),
];
export const WOR_STATUS_CAN_EDIT = [
  ...WOR_STATUS_NOT_STARTED,
  `maintenance ${WO_STATUS_INPROG.toLocaleLowerCase()}`,
  `maintenance ${WO_STATUS_REWORK.toLocaleLowerCase()}`,
];
export const WOM_STATUS_CAN_EDIT = [
  WO_STATUS_ASSIGNED_FS.toLocaleLowerCase(),
  WO_STATUS_INPROG.toLocaleLowerCase(),
  WO_STATUS_REWORK.toLocaleLowerCase(),
];

@Injectable({
  providedIn: 'root',
})
export class WorkOrderUtilsService {
  constructor(
    private _workOrderRepo: WorkOrderRepositoryService,
    private _queryService: QueryService,
    private _utilsService: UtilsService,
    private formBuilder: ReviewMaintenanceFormBuilder,
    private _dialog: MatDialog
  ) {}

  /**
   * Return true if the user can edit the given request.
   * User can edit a request if they have been assigned as the supervisor on the request, or they
   * are the issuer of the request (e.g. they created the request),
   * @param request : the request to edit
   * @param user : the user who will be editing
   */
  canEditRequest(request: IWorkOrderRequest, user: IAppUser, verbose: boolean = false): boolean {
    let ok = false;
    let msg = '';

    if (!user) {
      if (verbose) {
        msg = 'Missing User';
      }
    } else if (!request) {
      if (verbose) {
        msg = 'Missing Work Order Request';
      }
    } else if (!request.status) {
      if (verbose) {
        msg = 'Missing Work Order Request Status';
      }
    } else if (!this.canUserEditRequest(request, user)) {
      if (verbose) {
        msg =
          'Cannot edit Work Order Request that you did not prepare and you are not the assigned Supervisor or Field Staff.';
      }
    } else if (!WOR_STATUS_CAN_EDIT.includes(request.status.toLowerCase())) {
      if (verbose) {
        msg = `Cannot edit Work Order Request with status "${request.status}"`;
      }
    } else {
      ok = true;
    }

    if (!ok && msg) {
      this._dialog.open(ConfirmDialogComponent, { data: { message: msg, hideCancel: true } });
    }

    return ok;
  }

  /**
   * Return true if the given user can edit the maintenance.
   * @param maintenance : the maintenance to edit
   * @param user : the user who is performing the editing
   */
  canEditMaintenance(maintenance: IWorkOrderMaintenance, user: IAppUser, verbose: boolean = false): boolean {
    let ok = false;
    let msg = '';

    if (!user) {
      if (verbose) {
        msg = 'Missing User';
      }
    } else if (!maintenance) {
      if (verbose) {
        msg = 'Missing Work Order Maintenance';
      }
    } else if (!maintenance.status) {
      if (verbose) {
        msg = 'Missing Work Order Maintenance Status';
      }
    } else if (!WOM_STATUS_CAN_EDIT.includes(maintenance.status.name.toLocaleLowerCase())) {
      if (verbose) {
        msg = `Cannot edit Work Order Maintenance with Status "${maintenance.status.name}"`;
      }
    } else if (maintenance.issuedByUserId !== user.id) {
      if (verbose) {
        msg = "Cannot edit another user's Work Order Maintenace.";
      }
    } else {
      ok = true;
    }

    if (!ok && msg) {
      this._dialog.open(ConfirmDialogComponent, { data: { message: msg, hideCancel: true } });
    }

    return ok;
  }

  /**
   * User can edit a request if they have been assigned as the supervisor on the request, or they
   * are the issuer of the request (e.g. they created the request), or they are the field staff
   * user assigned to it.
   * @param request : the request to edit
   * @param user : the user who will be editing
   */
  canUserEditRequest(request: IWorkOrderRequest, user: IAppUser): boolean {
    return (
      user.id &&
      (user.id === request?.issuedByUserId || user.id === request?.supervisorUserId || user.id === request?.staffUserId)
    );
  }

  /**
   * Return true if the user can assign the request
   * @param request : the request to assign
   * @param user : the user doing the assignment
   */
  canAssignRequest(requests: IWorkOrderRequest[], user: IAppUser): boolean {
    return requests.reduce(
      (canAssign: boolean, request: IWorkOrderRequest) =>
        canAssign && !!request && request.supervisorUserId === user.id,
      true
    );
  }

  /**
   * Return true if Work Order Maintenance object has status equal to 'submitted'.
   * @param maintenance : the Maintenance object to check
   */
  canApproveMaintenance(maintenances: IWorkOrderMaintenance[]): boolean {
    return maintenances.reduce(
      (canAssign: boolean, maintenance: IWorkOrderMaintenance) =>
        canAssign &&
        !!maintenance &&
        maintenance.status?.name?.toLocaleLowerCase() === WO_STATUS_SUBMITTED.toLocaleLowerCase(),
      true
    );
  }

  /**
   * Return true if the Work Order Request state accepts changing the Field Staff Assignment.
   * NOTE: this does not validate the user changing the assignment.
   * @param request: the work order request object.
   */
  canChangeStaffAssignment(requests: IWorkOrderRequest[]): boolean {
    return requests.reduce(
      (canAssign: boolean, request: IWorkOrderRequest) =>
        canAssign &&
        !!request &&
        (WOR_STATUS_NOT_STARTED.includes(request.status?.toLocaleLowerCase()) || !request.staffUserId),
      true
    );
  }

  /**
   * Return true if the Work Order Request object has status equal to 'submitted'.
   * @param request : the request object to check
   */
  isRequestSubmitted(request: IWorkOrderRequest): boolean {
    return request?.status?.toLocaleLowerCase() === `maintenance ${WO_STATUS_SUBMITTED.toLocaleLowerCase()}`;
  }

  /**
   * Helper method to get a WorkOrderStatus object from the database by name.
   * @param name : the WorkOrderStatus name
   */
  getStatusId(name: string): Observable<number> {
    const statusQuery = this._queryService.toQuery(
      null,
      new QueryFilterExpression(WOR_STATUS_NAME, Q_OP_EQ, name),
      null,
      [WOR_STATUS_ID]
    );

    return this._workOrderRepo.getStatuses(statusQuery).pipe(map((status: { id: number }[]) => status[0].id));
  }

  /**
   * Return the url queryparams for creating or updating a WorkOrderMaintenance.
   * @param request : request object - for creating new work from a request
   * @param maintenance : maintenance object - for creating a duplicate or updating existing work
   * @param user : verify that the user can create/update the maintenance
   * @param create : true indicates creating ne work, false for update
   */
  getMaintenanceCreateOrUpdateParams(workOrder: IWorkOrderRequest | IWorkOrderMaintenance): NavigationExtras {
    const navigationExtras: NavigationExtras = {};

    if (!!workOrder) {
      const isRequest = (<IWorkOrderRequest>workOrder)?.supervisorUserId !== undefined;
      navigationExtras.queryParams = {
        requestId: isRequest ? workOrder.id : null,
        maintenanceId: !isRequest ? workOrder.id : (<IWorkOrderRequest>workOrder)?.maintenance?.id,
      };
    }
    return navigationExtras;
  }

  /**
   * Review the WorkOrderMaintenance - check that the user has permission to review and the maintenance is
   * ready for review.
   * @param maintenance : the maintenance work to review
   * @param user : the user reviewing the maintenance
   * @param component : component where the review was requested - set the load flag on the component as needed.
   */
  reviewMaintenance(maintenance: IWorkOrderMaintenance, user: IAppUser): Observable<any> {
    const dialogRef = this._dialog.open(BaseDialogComponent, { data: { formBuilder: this.formBuilder } });
    return dialogRef.afterClosed().pipe(
      switchMap((result: { approveAction: boolean; rejectNote: string }) => {
        if (this._utilsService.isEmpty(result)) {
          return EMPTY;
        } else {
          return result.approveAction
            ? this.approveMaintenance(maintenance, user.id)
            : this.rejectMaintenance(maintenance, result.rejectNote, user.id);
        }
      })
    );
  }

  /**
   * Execute the necessary actions to approve a Work Order Maintenance object.
   * @param maintenance : the Work Order Maintenance object
   * @param userId : Id of the user approving the Maintenance.
   */
  approveMaintenance(maintenance: IWorkOrderMaintenance, userId: string): Observable<any> {
    const filters = new QueryFilterExpression(WOR_STATUS_NAME, Q_OP_EQ, WO_STATUS_APPROVED);
    const approveQuery = this._queryService.toQuery(null, filters, null, [WOR_STATUS_ID]);

    return this._workOrderRepo.getStatuses(approveQuery, true).pipe(
      switchMap((value: Array<{ id: number }>) =>
        this._workOrderRepo.patchReplaceMaintenanceFields(maintenance.id, [
          ['statusId', value[0].id],
          ['approvedByUserId', userId],
        ])
      )
    );
  }

  /**
   * Execute the necessary actions to reject a Work Order Maintenance object.
   * @param maintenance : the Work Order Maintenance object
   * @param note : the rejection note
   * @param userId : Id of the user rejecting the Maintenance.
   */
  rejectMaintenance(maintenance: IWorkOrderMaintenance, note: string, userId: string): Observable<any> {
    const filters = new QueryFilterExpression(WOR_STATUS_NAME, Q_OP_EQ, WO_STATUS_REWORK);
    const reworkQuery = this._queryService.toQuery(null, filters, null, [WOR_STATUS_ID]);

    return this._workOrderRepo.getStatuses(reworkQuery, true).pipe(
      switchMap((value: Array<{ id: number }>) =>
        this._workOrderRepo.patchReplaceMaintenanceField(maintenance.id, 'statusId', value[0].id)
      ),
      switchMap(() => this._workOrderRepo.createMaintenanceRework(maintenance.id, note, userId))
    );
  }
}
