import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, CanActivateChild } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { AppUserRepositoryService } from '../services/repositories';
import { AuthenticationService } from '../services';
import { HttpErrorResponse } from '@angular/common/http';
import { IErrorDef } from '../interfaces/definitions/error.definition';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private _router: Router,
    private _userRepo: AppUserRepositoryService,
    private _authService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const returnUrl = state.url;
    // use the direct getCurrentUser to force an authentication check
    return this._userRepo.getCurrentUser().pipe(
      switchMap((user) => (!user ? this.reauthenticate('Please login to view this page.', returnUrl) : of(true))),
      catchError((err: IErrorDef) =>
        +err.code === 401 ? this.reauthenticate('Session expired, please login.', returnUrl) : throwError(err)
      )
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }

  private reauthenticate(message: string, returnUrl: string) {
    if (!returnUrl.includes('login')) {
      this._authService.logout();
      this._router.navigate([{ outlets: { primary: 'login' } }], {
        queryParams: { error: message, returnUrl },
      });
    }
    return of(false);
  }
}
