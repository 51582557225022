import { Injectable } from '@angular/core';
import { tap, mapTo, switchMap, catchError, skip, switchMapTo, take } from 'rxjs/operators';
import { AppUserRepositoryService } from './repositories';
import { IAppUserAuthentication, IAppUser, ITenant } from '../interfaces/models';
import { NotificationHandlerService } from './notification-handler.service';
import { Subject, of, merge, BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AppModuleTenantAssignmentsService } from './app-module-tenant-assignments.service';
import { StateService } from './state.service';
import { FilterChoiceService } from './filter-choices.service';
import { BaseChoiceService } from './base-choices\.service';

// Local storage key to the authentication token for the current session.
export const tokenKey = 'jwt-token';
export const roadApiUrl = 'road-features-api';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  // the holy trinity
  currentUserChanges$ = new ReplaySubject<IAppUser>(1);
  private loginObs$ = new BehaviorSubject<void>(null);
  private logoutObs$ = new Subject<void>();

  private serviceWorkerEnabled = false;

  constructor(
    private _userRepo: AppUserRepositoryService,
    private _notificationService: NotificationHandlerService,
    private _tenantModuleService: AppModuleTenantAssignmentsService,
    private _stateService: StateService,
    private _filterChoiceService: FilterChoiceService,
    private _formChoiceService: BaseChoiceService,
    _router: Router
  ) {
    if (_notificationService.canRunServiceWorker()) {
      this.serviceWorkerEnabled = true;
      this._notificationService.initializeCallbacks();
    }

    // getCurrentUser API call, catch any error and return null
    const getUser = this._userRepo.getCurrentUser().pipe(catchError(() => of(null)));

    // combine the loginObs and logoutObs, map them to a truthy and falsey value respectively
    // whenever they emit a value, update the current user value and emit the result to any listeners
    merge(this.loginObs$.pipe(switchMapTo(getUser)), this.logoutObs$.pipe(mapTo(null)))
      .pipe(
        tap((user: IAppUser) => {
          if (!!user && !this._notificationService.initializedEvent.value) {
            this.setupNotifications(user);
          }
        })
      )
      .subscribe((user: IAppUser) => this.currentUserChanges$.next(user));
  }

  /**
   * Get the currently logged in user. This observable is a replay, meaning it will emit one value
   * and complete immediately after subscribing to it.
   */
  currentUser(): Observable<IAppUser> {
    return this.currentUserChanges$.pipe(take(1));
  }

  getToken(): string {
    return localStorage.getItem(tokenKey);
  }

  private removeToken(): void {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(roadApiUrl);
  }

  /*
   * On successful login, store the returned token in local storage.
   */
  login(tenantSchema: string, userName: string, password: string): Observable<object> {
    const auth: IAppUserAuthentication = {
      tenantSchema,
      userName,
      password,
    };
    return this._login(auth);
  }

  loginEmail(email: string, password: string): Observable<object> {
    const auth: IAppUserAuthentication = {
      email,
      password,
    };
    return this._login(auth);
  }

  private _login(auth: IAppUserAuthentication): Observable<object> {
    return this._userRepo.authenticate(auth).pipe(
      switchMap((obj: any) => {
        if (!!obj.token) {
          localStorage.setItem(tokenKey, obj.token);
          localStorage.setItem(roadApiUrl, (obj.tenant as ITenant).roadServiceApiUrl);
          this.loginObs$.next();
        }
        // Since currentUserChanges$ is a ReplaySubject,
        // if we switchMap it will immediately return the replay (null in this case)
        // So skip the replay value and wait for the next currentUser API call to finish before continuing
        return this.currentUserChanges$.pipe(skip(1), take(1));
      })
    );
  }

  /*
   * Remove token from local storage and clear the tenant when logging out.
   */
  logout(): void {
    if (this.serviceWorkerEnabled) {
      this._notificationService.invalidateHandle();
    }
    this.removeToken();
    this.logoutObs$.next();
    // clear out cached tenant module assignments
    this._tenantModuleService.logout();
    // close any open menus
    this._stateService.sideNavToggled$.next(false);
    this._stateService.notificationMenuToggled$.next(false);
    // clear the notification history
    this._stateService.notifications$.next([]);
    // clear the cached choices
    this._formChoiceService.updateChoices();
  }

  setupNotifications(user: IAppUser): void {
    if (this.serviceWorkerEnabled) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          this._notificationService.initializeMessaging(user);
        }
      });
    }
  }
}
