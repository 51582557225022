import { IFilter } from 'src/app/core/interfaces/definitions/filter.definition';
import { BehaviorSubject, of } from 'rxjs';
import { FilterChoiceService } from 'src/app/core/services/filter-choices.service';
import {
  notApprovedFilter,
  inProgressFilter,
  submittedFilter,
  approvedFilter,
} from '../table/work-order-request-filters';
import { DateTimeService } from 'src/app/core/services/datetime.service';
import { QueryFilterExpression, IQueryFilter } from 'src/app/core/interfaces/query/filters/query-filter';
import { Q_OP_NULL, Q_OP_LT, Q_OP_GTE } from 'src/app/core/constants/query.constants';
import { QueryFilterAnd } from 'src/app/core/interfaces/query/filters/query-filter-binary';

export class WorkOrderRequestGraphFilters extends Array<IFilter> {
  private overdueDateFilter: IQueryFilter;
  private lessThanDateFilter: IQueryFilter;
  private moreThanDateFilter: IQueryFilter;
  private noDateFilter: IQueryFilter;

  /**
   * The Filter Definitions for Work Order Requests
   */
  public filters: IFilter[] = [
    {
      display: 'Status',
      propertyName: 'Status',
      type: 'dropdown',
      multi: true,
      choices: of([notApprovedFilter, inProgressFilter, submittedFilter, approvedFilter]),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Type',
      propertyName: 'Type.Name',
      type: 'dropdown',
      multi: true,
      choices: this._baseFilters.getRequestWorkTypeFilters('Type.Name'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Prepared By',
      propertyName: 'IssuedByUser.DisplayName',
      type: 'dropdown',
      multi: true,
      choices: this._baseFilters.getUserFilters('IssuedByUser.DisplayName'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Supervisor',
      propertyName: 'SupervisorUser.DisplayName',
      type: 'dropdown',
      multi: true,
      choices: this._baseFilters.getSupervisorFilters('SupervisorUser.DisplayName'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Field Staff',
      propertyName: 'StaffUser.DisplayName',
      type: 'dropdown',
      multi: true,
      choices: this._baseFilters.getUserFilters('StaffUser.DisplayName'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Reason',
      propertyName: 'Reason.Name',
      type: 'dropdown',
      multi: false,
      choices: this._baseFilters.getRequestWorkReasonFilters('Reason.Name'),
      filter: new BehaviorSubject(null),
    },
  ];

  constructor(private _baseFilters: FilterChoiceService, _datetimeService: DateTimeService) {
    super();

    const todayStr = _datetimeService.today().format();
    const todayPlusWeekStr = _datetimeService.today().add(7, 'days').format();

    this.noDateFilter = new QueryFilterAnd(
      new QueryFilterExpression('DueDateTime', Q_OP_NULL, null),
      notApprovedFilter,
      'No Date'
    );
    this.overdueDateFilter = new QueryFilterAnd(
      new QueryFilterExpression('DueDateTime', Q_OP_LT, todayStr),
      notApprovedFilter,
      'Overdue'
    );
    this.moreThanDateFilter = new QueryFilterAnd(
      new QueryFilterExpression('DueDateTime', Q_OP_GTE, todayPlusWeekStr),
      notApprovedFilter,
      'More Than A Week'
    );
    this.lessThanDateFilter = new QueryFilterAnd(
      new QueryFilterAnd(
        new QueryFilterExpression('DueDateTime', Q_OP_GTE, todayStr),
        new QueryFilterExpression('DueDateTime', Q_OP_LT, todayPlusWeekStr)
      ),
      notApprovedFilter,
      'Less Than A Week'
    );

    this.push({
      display: 'Due Dates',
      propertyName: 'DueDateTime',
      type: 'dropdown',
      multi: false,

      choices: of([this.overdueDateFilter, this.lessThanDateFilter, this.moreThanDateFilter, this.noDateFilter]),
      filter: new BehaviorSubject(null),
    });

    this.push(...this.filters);
  }
}
