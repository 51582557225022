import { Component } from '@angular/core';
import { AssetRepositoryService } from './../../../core/services/repositories';
import { finalize } from 'rxjs/operators';
import { DownloadService } from 'src/app/core/services/download.service';

// Constants for download files.
const guideFileContainer = 'docs';
const guideWebFilename = 'asset-city-signs-web-guide.pdf';
const guideAppFilename = 'asset-city-signs-ios-guide.pdf';

@Component({
  selector: 'app-signs-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent {
  isDownloading = false;

  constructor(private _assetRepo: AssetRepositoryService, private dlService: DownloadService) {}

  downloadGuide(webGuide: boolean): void {
    this.isDownloading = true;
    this._assetRepo
      .downloadAssetByContainerAndName(guideFileContainer, webGuide ? guideWebFilename : guideAppFilename)
      .pipe(finalize(() => (this.isDownloading = false)))
      .subscribe((res: any) => {
        this.dlService.downloadLocalBlob(res, webGuide ? guideWebFilename : guideAppFilename);
      });
  }
}
