import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor() {}

  /**
   * Given a JS blob, download it to the user's computer
   * @param blob The binary to download
   * @param filename The name to save it under
   */
  public downloadLocalBlob(blob: Blob, filename: string): void {
    // Download the file from the blob.
    const url = window.URL.createObjectURL(blob);
    this.downloadData(url, filename);
  }

  /**
   * Given a string representation of an image, provide a download option
   * to the user.
   * @param dataUrl : The image data in the form of a url.
   * @param filename : The name of the file to download.
   */
  public downloadData(dataUrl: string, filename: string): void {
    // The download is handled differently in Microsoft browsers
    // because the download attribute for <a> elements is not supported

    if (!window.navigator.msSaveOrOpenBlob) {
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      a.href = dataUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(dataUrl);
    } else {
      // For MS browsers convert dataUrl to Blob
      const byteString = atob(dataUrl.split(',')[1]);
      const mimeString = dataUrl
        .split(',')[0]
        .split(':')[1]
        .split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });

      window.navigator.msSaveOrOpenBlob(blob, filename);
    }
  }
}
