import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ContentChild,
  ViewChild,
  AfterContentInit,
  HostListener,
} from '@angular/core';
import { BehaviorSubject, Observable, EMPTY, Subject } from 'rxjs';
import { IQueryParam } from 'src/app/core/interfaces/query/query';
import { IQueryResult } from 'src/app/core/interfaces/models';
import { QueryService } from 'src/app/core/services/query.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { FilterRouteMapService } from 'src/app/core/services/filter-route-map.service';
import { debounceTime, tap, switchMap, catchError, takeUntil } from 'rxjs/operators';
import { API_DB_TIME } from 'src/app/core/constants/constants';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatListOption } from '@angular/material/list';
import { TableQueryData } from 'src/app/core/interfaces/definitions/table-query-data.definition';
import { Q_ALL_FIELDS } from 'src/app/core/constants/query.constants';
import { TableRowsComponent } from '../table-rows/table-rows.component';
import { IQueryFilter } from 'src/app/core/interfaces/query/filters/query-filter';
import { ConfigRepositoryService } from 'src/app/core/services/repositories/config-repository.service';
import { ListSelectionDialogComponent } from '../../dialogs/list-selection-dialog/list-selection-dialog.component';
import { ITableColumn } from 'src/app/core/interfaces/definitions';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { IToolbarAction } from 'src/app/core/interfaces/definitions/toolbar-action.definition';
import { IFilter } from 'src/app/core/interfaces/definitions/filter.definition';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements OnInit, OnDestroy, AfterContentInit {
  @ContentChild('sidenavContent') contentSidenav: any;
  @ViewChild(TableRowsComponent) tableRows: TableRowsComponent<T>;
  /**
   * Model name for the table objects
   */
  @Input()
  set modelName(name: string) {
    this._modelName = name;
    this.canSaveColumns = !this._utilsService.isEmpty(this._modelName);
  }
  @Input() dense: boolean;
  @Input() pageSize: number;
  @Input() pageSizeOptions: number[];
  @Input() isLoading: boolean;
  @Input() multiSelect: boolean = false;
  @Input() toolbarActions: IToolbarAction[];
  /**
   * Observable that allows parent component to force an update by triggering this event
   */
  @Input() update: Observable<void>;
  /**
   * Data that contains the query columns, filters, initial params and corresponding repo get function
   */
  @Input()
  set queryData(data: TableQueryData<T>) {
    this._queryData = data;
    this.visibleColumns = data.columns.filter((c) => c.visible);
  }
  get queryData(): TableQueryData<T> {
    return this._queryData;
  }
  /**
   * Columns that are visible to the user.
   */
  @Input()
  set queryDataColumns(columns: ITableColumn<T>[]) {
    this.visibleColumns = columns.filter((c) => c.visible);
  }
  @Output() selectionChanged = new EventEmitter<T[]>();

  /** UI Fields */
  filterPanelToggle: boolean;
  sidePanelToggle: boolean;
  isMobile: boolean;
  isTablet: boolean;
  canSaveColumns: boolean;
  visibleColumns: ITableColumn<T>[];
  currentSelection: T[];
  /** Query Fields */
  dataSubject: BehaviorSubject<IQueryParam>;
  sourceSubject: Observable<IQueryResult>;
  queryParam: IQueryParam;

  private _queryData: TableQueryData<T>;
  private _modelName: string;
  private destroy$: Subject<void>;

  @HostListener('window:resize', ['$event'])
  resize(): void {
    this.isMobile = this._stateService.isMobile();
    this.isTablet = this._stateService.isTablet();
  }

  constructor(
    protected queryService: QueryService,
    protected _utilsService: UtilsService,
    private _configRepo: ConfigRepositoryService,
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _stateService: StateService,
    private _filterRouteMap?: FilterRouteMapService
  ) {
    this._modelName = null;
    this.canSaveColumns = false;
    this.dense = false;
    this.filterPanelToggle = null;
    this.sidePanelToggle = null;
    this.visibleColumns = [];
    this.pageSizeOptions = [5, 10, 25, 50, 100];
    this.pageSize = this.pageSizeOptions[1];
    this.resize();
    this.destroy$ = new Subject();
  }

  ngOnInit(): void {
    // Retreive and apply the user's column configuration, if any.
    this._configRepo.getUserColumnsConfig().subscribe((res: any) => {
      if (res && res.hasOwnProperty(this._modelName)) {
        this._updateTableColumns(res[this._modelName]);
      }
    });

    if (!this.queryData.param) {
      this.queryData.param = this.queryService.getEmptyQuery();
    }

    if (this.queryData.filters && this.queryData.filters.length > 0) {
      this.filterPanelToggle = !(this.isMobile || this.isTablet);
    }

    // If no include fields provided, use *
    if (this.queryData.param.fields && this.queryData.param.fields.length === 0) {
      this.queryData.param.fields = [Q_ALL_FIELDS];
    }

    this.queryParam = this.queryData.param;
    this.dataSubject = new BehaviorSubject(this.queryParam);
    this.initializeFilters();

    this.sourceSubject = this.dataSubject.pipe(
      debounceTime(API_DB_TIME),
      tap(() => (this.isLoading = true)),
      switchMap((queryParam: IQueryParam) => this.queryData.fn(queryParam)),
      tap(() => {
        this.clearSelection();
        this.selectionChanged.emit([]);
        this.isLoading = false;
      }),
      catchError(() => {
        this.isLoading = false;
        return EMPTY;
      })
    );
    if (this.update) {
      this.update.pipe(takeUntil(this.destroy$)).subscribe(() => this.refresh());
    }
  }

  ngAfterContentInit(): void {
    if (this.contentSidenav) {
      this.sidePanelToggle = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    // if we're on mobile and navigate away, we may have not reset the overflow properly, so do it here
    if (this.isMobile) {
      document.body.style.setProperty('overflow', 'auto');
    }
  }

  /**
   * On mobile, the sidenav doesn't prevent the body from scrolling. So listen for the sidenav open/close events
   * and hide the body's overflow when they're open.
   */
  onSidenavOpen(): void {
    if (this.isMobile && (this.sidePanelToggle || this.filterPanelToggle)) {
      document.body.style.setProperty('overflow', 'hidden');
    }
  }

  onSidenavClose(): void {
    if (document.body.style.overflow === 'hidden') {
      document.body.style.setProperty('overflow', 'auto');
    }
  }

  /**
   * Only available on mobile. Clear the selection on close because you can't see the content and the side panel
   * at the same time, indicating selection is kind of awkward, and it's confusing if you click on the same item
   * if there's no selection indication.
   */
  closeSidePanel(): void {
    // order matters, clearing the selection after doesn't close the panel
    this.clearSelection();
    this.sidePanelToggle = false;
  }

  removeFilter(filter: IFilter): void {
    filter.filter.next(null);
  }

  showFilterPanel(): void {
    this.filterPanelToggle = true;
  }

  private _updateTableColumns(columnIds: string[]): void {
    this.queryData.columns.map((c) => (c.visible = columnIds.includes(c.id)));
    this.visibleColumns = this.queryData.columns.filter((c) => c.visible);
    this.updateFields(this.visibleColumns.map((c) => c.id));
  }

  getSelection(): T[] {
    return this.tableRows.getSelection();
  }

  hasSelection(): boolean {
    const selection = this.tableRows.getSelection();
    return selection && selection.length > 0;
  }

  getSelectionAt(i: number): T {
    return this.tableRows.hasSelection() ? this.tableRows.getSelection()[i] : null;
  }

  clearSelection(): void {
    this.tableRows.clearSelection();
  }

  getData(): T[] {
    return this.tableRows.sourceSubject.value;
  }

  refresh(): void {
    this.dataSubject.next(this.queryParam);
    this.queryData.param = this.queryParam;
  }

  initializeFilters(): void {
    if (!this._filterRouteMap || !this.queryData.filters) {
      return;
    }

    const filterEvents = this._filterRouteMap.getRouteData();
    if (filterEvents) {
      for (const filter of this.queryData.filters) {
        if (filterEvents.hasOwnProperty(filter.propertyName)) {
          filter.filter.next(filterEvents[filter.propertyName]);
        }
      }
    }
    this._filterRouteMap.clear();
  }

  selectItem(data: T[]): void {
    this.tableRows.setSelection(data);
  }

  selectionChange(data: T[]) {
    this.currentSelection = data;
    this.selectionChanged.emit(data);
    if (this.sidePanelToggle !== null) {
      this.sidePanelToggle = true;
    }
  }

  updateFilters(newFilters: IQueryFilter): void {
    this.queryParam.filters = newFilters;
    this.refresh();
  }

  updateFields(columns: string[]): void {
    this.queryParam.fields = columns.concat(this.queryData.staticColumns);
    this.refresh();
  }

  paginateItems(event: PageEvent): void {
    this.queryParam.pagination = {
      qpCount: event.pageSize,
      qpOffset: event.pageIndex,
    };
    this.refresh();
  }

  sortItems(event: Sort): void {
    this.queryParam.sort = {
      qsPrimary: event.active,
      qsSecondary: this.queryParam.sort.qsSecondary,
    };
    if (event.direction !== '') {
      this.queryParam.sort.qsIsAsc = event.direction === 'asc';
    }
    this.refresh();
  }

  openColumnSettings(): void {
    const dialogRef = this._dialog.open(ListSelectionDialogComponent, {
      data: { items: this.queryData.columns, isCheckedFn: (item: ITableColumn<T>) => item.visible },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result: MatListOption[]) => {
      if (result) {
        if (result.length > 0) {
          const ids = result.map((item) => {
            if (item.value) {
              return item.value.id;
            }
          });
          this._updateTableColumns(ids);
          this._snackbar.open('Columns Updated', 'OK', { duration: 1000 });
        } else {
          this._dialog.open(ConfirmDialogComponent, {
            data: { message: 'Cannot remove all columns', hideCancel: true },
          });
        }
      }
    });
  }

  saveColumns(): void {
    this._configRepo
      .updateUserColumnsConfig(
        this._modelName,
        this.visibleColumns.map((c) => c.id)
      )
      .subscribe(() => this._snackbar.open('Columns Saved', 'OK', { duration: 1000 }));
  }
}
