import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routing } from './app.routing';
import { CacheInterceptor, ErrorInterceptor, JwtInterceptor } from './core/interceptors';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { LoginComponent } from './components/login/login.component';
import { NavComponent } from './components/navigation/nav/nav.component';
import { NavBarComponent } from './components/navigation/nav-bar/nav-bar.component';
import { WorkOrderRequestEditDialogComponent } from './components/dialogs/work-order-request-edit-dialog/work-order-request-edit-dialog.component';
import { HelpComponent } from './modules/signs/help/help.component';
import { ErrorComponent } from './components/error/error.component';
import { PictureDialogComponent } from './components/dialogs/picture-dialog/picture-dialog.component';
import { InformationDialogComponent } from './components/dialogs/information-dialog/information-dialog.component';
import { NotificationMenuComponent } from './components/navigation/notification-menu/notification-menu.component';
import { ExportDialogComponent } from './components/dialogs/export-dialog/export-dialog.component';
import { ColourPickerDialogComponent } from './components/dialogs/colour-picker-dialog/colour-picker-dialog.component';
import { GraphDesignerComponent } from './components/graph-designer/graph-designer.component';
import { environment } from 'src/environments/environment';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SplashPageComponent } from './components/splash-page/splash-page.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { BaseDialogComponent } from './components/dialogs/base-dialog/base-dialog.component';
import { BadRequestErrorHandler } from './core/guards/error.handler';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavBarComponent,
    NavComponent,
    DashboardComponent,
    ComingSoonComponent,
    WorkOrderRequestEditDialogComponent,
    HelpComponent,
    ErrorComponent,
    PictureDialogComponent,
    InformationDialogComponent,
    NotificationMenuComponent,
    ExportDialogComponent,
    ColourPickerDialogComponent,
    GraphDesignerComponent,
    ResetPasswordComponent,
    SplashPageComponent,
    ConfirmEmailComponent,
    BaseDialogComponent,
  ],
  imports: [
    /**
     * Will fail on ng serve because that doesn't trigger the angular SW to be built.
     * Nothing is affected, it still will register the firebase service worker and have notifications just no caching.
     */
    ServiceWorkerModule.register('asset-city-sw-v1.js', { enabled: true }),
    AngularFireModule.initializeApp(environment.firebaseOptions),
    AngularFireMessagingModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    // Import HttpClientModule to allow interceptors to be singletons for the app
    HttpClientModule,
    SharedModule,
    routing,
  ],
  exports: [],
  entryComponents: [
    BaseDialogComponent,
    WorkOrderRequestEditDialogComponent,
    PictureDialogComponent,
    InformationDialogComponent,
    ExportDialogComponent,
    ColourPickerDialogComponent,
    GraphDesignerComponent,
  ],
  providers: [
    { provide: ErrorHandler, useClass: BadRequestErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
