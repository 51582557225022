import { Directive, OnDestroy, Input } from '@angular/core';
import { Observable, Subject, from, of } from 'rxjs';
import { EsriMapService } from 'src/app/core/services';
import { IInstalledTrafficSign } from 'src/app/core/interfaces/models';
import { takeUntil, switchMap, take } from 'rxjs/operators';
import * as esri from 'src/app/core/esri/constants/constants';

@Directive({
  selector: '[appSignsFeature]',
})
export class SignsFeatureDirective implements OnDestroy {
  @Input()
  set selectSign(sign: IInstalledTrafficSign) {
    this._selected = sign;
    if (this._initialized) {
      this._mapService.selectGraphic(this._selected.id, esri.INSTALLED_SIGNS_LAYER_ID);
    }
  }

  @Input()
  set showSigns(val: boolean) {
    this._visible = val;
    this.setVisible();
  }

  @Input()
  set signs(data: IInstalledTrafficSign[]) {
    this._signs = data;

    if (this._mapService.initialized(false)) {
      from(this._mapService.loadInstalledSignsGraphicsLayer(esri.INSTALLED_SIGNS_LAYER_ID, this._signs, this._visible))
        .pipe(
          switchMap((layer: __esri.GraphicsLayer) => {
            if (!!layer) {
              if (this._initialized) {
                if (this._selected) {
                  this._mapService.selectGraphic(this._selected.id, esri.INSTALLED_SIGNS_LAYER_ID, [
                    this._selected.location.longitude,
                    this._selected.location.latitude,
                  ]);
                }
              } else {
                return this._mapService.addLayerViewHighlight(layer, esri.INSTALLED_SIGNS_OID);
              }
              return of(null);
            }
          }),
          take(1)
        )
        .subscribe((layerView: __esri.GraphicsLayerView) => {
          if (!!layerView) {
            if (this._selected) {
              this._mapService.selectGraphic(this._selected.id, esri.INSTALLED_SIGNS_LAYER_ID, [
                this._selected.location.longitude,
                this._selected.location.latitude,
              ]);
            }

            this._initialized = true;
          }
        });
    }
  }
  /**
   * Input mapInit: observable let's us know when the map info is available.
   */
  @Input()
  set mapInit(init$: Observable<void>) {
    this._destroy$.next();

    init$
      .pipe(
        switchMap(() =>
          !!this._signs && !!this._signs.length
            ? from(
                this._mapService.loadInstalledSignsGraphicsLayer(
                  esri.INSTALLED_SIGNS_LAYER_ID,
                  this._signs,
                  this._visible
                )
              )
            : of(null)
        ),
        switchMap((layer: __esri.GraphicsLayer) => {
          return !!layer ? this._mapService.addLayerViewHighlight(layer, esri.INSTALLED_SIGNS_OID) : of(null);
        }),
        takeUntil(this._destroy$)
      )
      .subscribe((layerView: __esri.GraphicsLayerView) => {
        if (!!layerView) {
          if (this._selected) {
            this._mapService.selectGraphic(this._selected.id, esri.INSTALLED_SIGNS_LAYER_ID, [
              this._selected.location.longitude,
              this._selected.location.latitude,
            ]);
          }

          this._initialized = true;
        }
      });
  }

  private _initialized: boolean;
  private _visible: boolean;
  private _signs: IInstalledTrafficSign[];
  private _selected: IInstalledTrafficSign;
  private _destroy$: Subject<void>;

  constructor(private _mapService: EsriMapService) {
    this._initialized = false;
    this._visible = true;
    this._signs = [];
    this._destroy$ = new Subject();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  setVisible(): void {
    if (this._initialized) {
      this._mapService.setLayerVisible(esri.INSTALLED_SIGNS_LAYER_ID, this._visible);
    }
  }
}
