import { Injectable } from '@angular/core';
import { IQueryParam } from '../interfaces/query/query';
import { IQueryPagination } from '../interfaces/query/query-pagination';
import { IQuerySort } from '../interfaces/query/query-sort';
import { Q_PREFIX, Q_ASSIGN, Q_JOIN, Q_FIELD, Q_FILTER } from '../constants/query.constants';
import { UtilsService } from './utils.service';
import { IQueryFilter, QueryFilterExpression } from '../interfaces/query/filters/query-filter';

@Injectable({
  providedIn: 'root',
})
export class QueryService {
  constructor(private _utils: UtilsService) {}

  getEmptyQuery(): IQueryParam {
    return { sort: {}, fields: ['*'], filters: null, pagination: {} };
  }

  /**
   * Helper method to construct an IQueryParam object given the
   * query parameter values.
   * @param pagination (IQueryPagination): The query pagination object.
   * @param filters (IQueryFilterGroup): The query filtering object.
   * @param sort (IQuerySort): The query sorting object.
   * @param fields (string[]): The query fields
   */
  toQuery(
    pagination: IQueryPagination = null,
    filters: IQueryFilter = null,
    sort: IQuerySort = null,
    fields: string[] = null
  ): IQueryParam {
    return { pagination, filters, sort, fields };
  }

  /**
   * Helper method to construct an IQueryParam object given the
   * query parameter values.
   * @param fields (string[]): The query fields
   */
  toQueryFromFields(fields: string[]): IQueryParam {
    return this.toQuery(null, null, null, fields);
  }

  /**
   * Helper method to construct an IQueryParam object given the
   * query parameter values.
   * @param filters (IQueryFilterGroup): The query filtering object.
   */
  toQueryFromFilters(filters: IQueryFilter): IQueryParam {
    return this.toQuery(null, filters, null, null);
  }

  /**
   * Helper method to construct an IQueryParam object given the
   * query parameter values.
   * @param filters (IQueryFilterGroup): The query filtering object.
   */
  toQueryFromFilter(property: string, op: string, value?: any, displayName?: string): IQueryParam {
    return this.toQuery(null, new QueryFilterExpression(property, op, value, displayName), null, null);
  }

  /**
   * Takes an IQueryParam defining the query attributes, and builds a
   * query url string to pass along an HTTP GET method.
   * @param query (IQueryParam): The query object to convert to url.
   */
  toUrlStr(query: IQueryParam): string {
    const queryParams: string[] = [];
    queryParams.push(this._paginationToQueryParam(query.pagination));
    queryParams.push(this._sortToQueryParam(query.sort));
    queryParams.push(this._fieldsToQueryParam(query.fields));
    queryParams.push(this._filterToQueryParam(query.filters));

    const queryUrl = this._joinQueryParams(queryParams);
    return queryUrl ? `${Q_PREFIX}${queryUrl}` : '';
  }

  /**
   * Convert the IQueryPagination object into a parameter to be injected into a query url string
   */
  private _paginationToQueryParam(pagination: IQueryPagination): string {
    return this._objectToQueryParam(pagination);
  }

  /**
   * Convert the IQuerySort object into a parameter to be injected into a query url string
   */
  private _sortToQueryParam(sort: IQuerySort): string {
    return this._objectToQueryParam(sort);
  }

  /**
   * Convert the string list of fields into a parameter to be injected into a query url string
   */
  private _fieldsToQueryParam(fields: string[]): string {
    if (fields) {
      const fieldParams = fields.map((field) => this._toQueryParam(Q_FIELD, field));
      return this._joinQueryParams(fieldParams);
    } else {
      return '';
    }
  }

  /**
   * Convert the IQuerySort object into a parameter to be injected into a query url string
   */
  private _filterToQueryParam(filter: IQueryFilter): string {
    return filter ? `${Q_FILTER}${Q_ASSIGN}${filter.toFilterStr()}` : '';
  }

  /**
   * Joins each non-empty item from the list with the given join char.
   */
  private _joinQueryParams(params: string[], char: string = Q_JOIN): string {
    return params.filter((param) => !this._utils.isEmpty(param)).join(char);
  }

  /**
   * Returns parameter string for query url.
   */
  private _toQueryParam(field: string, value: any): string {
    return this._utils.isEmpty(value) ? '' : `${field}${Q_ASSIGN}${encodeURIComponent(value)}`;
  }

  private _objectToQueryParam(o: object): string {
    if (o) {
      const objectParams = Object.keys(o).map((field) => this._toQueryParam(field, o[field]));
      return this._joinQueryParams(objectParams);
    } else {
      return '';
    }
  }
}
