import { FormControl, FormBuilder } from '@angular/forms';
import { IFormGroup, IFormBuilderDescriptor } from './form-builder.definition';
import { UtilsService } from 'src/app/core/services/utils.service';
import { Observable, forkJoin, of, Subject } from 'rxjs';
import { BaseFormControl } from '../base-form-control';
import { CTRL_TYPE_FILE } from './form-builder.constants';
import { AssetService, IAssetFileData } from 'src/app/core/services/asset.service';
import { map, takeUntil } from 'rxjs/operators';

/**
 * Generic builder that takes an IFormBuilderDescriptor and creates an IFormGroup object
 */
export class BaseFormBuilder {
  // FormBuilder to create a FormGroup instance
  private _formBuilder: FormBuilder;
  // The list of form groups that this builder is managing
  private _formGroups: IFormGroup[];
  // Map IFormControl id to the FormControl instance -- for quick lookup.
  private _fieldDescriptorMap: { [key: string]: BaseFormControl };
  protected descriptor: IFormBuilderDescriptor[];

  private destroy$ = new Subject<void>();

  constructor(public _utils: UtilsService, private _assetService: AssetService) {
    this._formBuilder = new FormBuilder();
    this._formGroups = [];
    this._fieldDescriptorMap = {};
    this.descriptor = [];
  }

  /**
   * Build and return the set of form groups created from the IFormBuilderDescriptor.
   * This is the main function of the BaseFormBuilder that must be called to initialize the
   * builder before any other methods can be effective.
   */
  build(): void {
    this._formGroups = [];
    this._fieldDescriptorMap = {};

    for (const groupDescriptor of this.descriptor) {
      // Build the mapping of unique control name to FormControl object to create the FormGroup.
      const formCtrls = {};
      const groupLayout: Array<BaseFormControl[]> = [];

      for (const rowLayout of groupDescriptor.layout) {
        const layout: BaseFormControl[] = [];

        for (const formCtrlDescriptor of rowLayout) {
          const baseCtrl = new BaseFormControl(formCtrlDescriptor, this._utils);
          layout.push(baseCtrl);
          formCtrls[formCtrlDescriptor.id] = baseCtrl.form;
          // Keep a direct mapping of form control name to the corresponding descriptor (so that
          // we don't have to look through each form group to find the form control we need each time).
          this._fieldDescriptorMap[formCtrlDescriptor.id] = baseCtrl;
        }

        groupLayout.push(layout);
      }

      // Finally push the form group descriptor with the created FormGroup and FormControls instances
      this._formGroups.push({
        name: groupDescriptor.name,
        layout: groupLayout,
        showMap: groupDescriptor.showMap,
        group: this._formBuilder.group(formCtrls),
        isLoading: false,
      });
    }
  }

  /**
   * Create new object from the all the FormControl values. Iterate through every form control,
   * hidden controls will be ignored, and the resulting object will be built up by using each
   * form control's updateProperties.
   */
  create(): Observable<any> {
    const result = {};
    const uploadAssets$: Observable<any>[] = [];

    for (const formName of Object.keys(this._fieldDescriptorMap)) {
      const baseCtrl: BaseFormControl = this._fieldDescriptorMap[formName];
      const formVal = baseCtrl.form.value;
      if (!baseCtrl.hidden) {
        if (baseCtrl.updateProperties) {
          switch (baseCtrl.type) {
            case CTRL_TYPE_FILE:
              // Special case handling for file type controls: add observable to upload assets on subcribe
              // and updates the result object accordingly.
              if (!!formVal) {
                const uploadAssets = formVal.map((fileData: IAssetFileData) =>
                  this._assetService.uploadAsset(fileData)
                );

                if (!!uploadAssets.length) {
                  uploadAssets$.push(
                    forkJoin(uploadAssets).pipe(
                      map((urls: string[]) => {
                        for (const [key, _] of baseCtrl.updateProperties) {
                          const values = [];
                          for (let i = 0; i < urls.length; i++) {
                            if (baseCtrl.fileNote) {
                              values.push({
                                item1: urls[i],
                                item2: formVal[i].note ? formVal[i].note : '',
                              });
                            } else {
                              values.push(urls[i]);
                            }
                          }

                          // Update the result object after the asset is uploaded and the necessary info is available
                          result[key] = baseCtrl.multiple ? values : values[0];
                        }
                      })
                    )
                  );
                }
              }
              break;
            default:
              // Default case that handles most form control types
              for (const [key, fields] of baseCtrl.updateProperties) {
                if (baseCtrl.multiple) {
                  result[key] = formVal.map((val: any) => this._utils.getNestedValue(val, fields));
                } else {
                  result[key] = this._utils.getNestedValue(formVal, fields);
                }
              }
              break;
          }
        }
      }
    }

    // Upload any assets first, each upload asset observable will update the result object as needed,
    // once all asset uploads are finished, the result object will be returned. If no assets to upload,
    // just wrap the result object in an observable.
    return !!uploadAssets$.length ? forkJoin(uploadAssets$).pipe(map(() => result)) : of(result);
  }

  /**
   * Return the form descriptor for the given field.
   * @param id : the field name corresponding to the form descriptor.
   */
  private _baseControl(id: string): BaseFormControl {
    return this._fieldDescriptorMap[id];
  }

  /**
   * Return the form control for the given field.
   * @param field : the field name corresponding to the form control
   */
  private _formControl(field: string): FormControl {
    return this._baseControl(field).form;
  }

  destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Return the form groups descriptor.
   */
  getFormGroups(): IFormGroup[] {
    return this._formGroups;
  }

  /**
   * Set the loading indicator for the form group at index, or all groups if index is null.
   * @param loading : flag whether or not form group is loading
   * @param index : the form group index
   */
  setLoading(loading: boolean, index: number = null): void {
    if (index) {
      if (index >= 0 && index < this._formGroups.length) {
        this._formGroups[index].isLoading = loading;
      }
    } else {
      this._formGroups.forEach((fg: IFormGroup) => {
        fg.isLoading = loading;
      });
    }
  }

  /**
   * Set the FormGroup enabled.
   * @param enabled : true if enabled
   * @param index : index pointing to the form group
   */
  setGroupEnabled(enabled: boolean, index: number): void {
    if (enabled) {
      this._formGroups[index].group.enable();
    } else {
      this._formGroups[index].group.disable();
    }
  }

  /**
   * Set the BaseFormControl filtered choices.
   * @param id : the BaseFormControl's FormControl name (unique)
   * @param choices : list of the filtered choices for the FormControl input
   */
  setChoices(
    id: string,
    choices: Observable<any>,
    filterChoicesFn: (filterBy: any, curVal: any) => boolean = null
  ): void {
    this._baseControl(id).setChoices(choices, filterChoicesFn);
  }

  /**
   * Set the BaseFormControl filtered choices.
   * @param id : the BaseFormControl's FormControl name (unique)
   * @param choices : list of the filtered choices for the FormControl input
   */
  setFilterChoices(id: string, choices: Observable<any>): void {
    this._baseControl(id).filterChoices = choices;
  }

  /**
   * Set the BaseFormControl dynamic filter choices by value.
   * @param id : the BaseFormControl's FormControl name (unique)
   * @param value : the dynamic value to alter the choices list by
   */
  setFilterChoicesBy(id: string, value: any): void {
    this._baseControl(id).filterChoicesBy = value;
  }

  /**
   * Return the BaseFormControls current value.
   * @param id : the BaseFormControl's FormControl name (unique)
   */
  getValue(id: string): any {
    return this._formControl(id).value;
  }

  /**
   * Set the BaseFormControls value. If property specified, this indicates
   * we need to get the whole object the value refers to, from the base
   * controls 'allChoices' list.
   * @param id : the BaseFormControl's FormControl name (unique)
   * @param value : the value to set
   */
  setValue(id: string, value: any, property: string = null): void {
    const ctrl = this._baseControl(id);

    if (!!property && !!ctrl.allChoices) {
      let values = null;
      if (value instanceof Array) {
        values = [];
        value.forEach((v: any) => values.push(ctrl.allChoices.filter((choice: any) => choice[property] === v)[0]));
      } else {
        values = ctrl.allChoices.filter((choice: any) => choice[property] === value);
      }

      if (ctrl.multiple) {
        value = values;
      } else if (!!values && values.length > 0) {
        value = values[0];
      }
    }

    ctrl.form.setValue(value);

    // Validate after setting the value, reset if invalid.
    // Note: must use invalid instead of valid property, since valid will always be false
    // if control is disabled.
    if (ctrl.form.invalid) {
      ctrl.form.reset();
    }
  }

  /**
   * Return the valueChanges Observable object for the FormControl object.
   * The caller may want to intercept the valueChanges event for custom handling.
   *
   * NOTE: caller must unsubscribe from valueChanges subscription to avoid memory leak.
   *
   * @param id : the BaseFormControl's FormControl name (unique)
   */
  valueChanges(id: string): Observable<any> {
    return this._formControl(id).valueChanges.pipe(takeUntil(this.destroy$));
  }

  setHidden(id: string, hidden: boolean): void {
    this._baseControl(id).hidden = hidden;
    this.setEnabled(id, !hidden);
  }
  /**
   * Enable or disable the FormControl
   * @param id : the BaseFormControl's FormControl name (unique)
   */
  setEnabled(id: string, enable: boolean, tooltip: string = ''): void {
    const baseCtrl = this._baseControl(id);
    if (enable) {
      baseCtrl.form.enable();
    } else {
      baseCtrl.form.disable();
    }

    baseCtrl.tooltip = tooltip;
  }

  /**
   * Return true whether or not all forms are valid.
   */
  isValid(id: string = null): boolean {
    if (id) {
      return this._formControl(id).valid;
    } else {
      for (const form of this._formGroups) {
        if (!form.group.valid) {
          return false;
        }
      }
      return true;
    }
  }

  /**
   * Return true whether or not all forms are valid.
   */
  getErrors(): string[] {
    const errors: string[] = [];
    for (const form of this._formGroups) {
      if (!form.group.valid) {
        Object.keys(form.group.controls).forEach((id) => {
          const baseCtrl = this._baseControl(id);
          if (!baseCtrl.hidden) {
            let tempEnabled = false;
            if (form.group.controls[id].disabled) {
              tempEnabled = true;
              form.group.controls[id].enable();
            }

            if (!form.group.controls[id].valid) {
              errors.push(baseCtrl.placeholder ? baseCtrl.placeholder : baseCtrl.id);
            }

            if (tempEnabled) {
              form.group.controls[id].disable();
            }
          }
        });
      }
    }
    return errors;
  }
}
