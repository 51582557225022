import { Directive } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { PasswordValidation } from 'src/app/shared/validators';

@Directive({
  selector: '[appPasswordVerifier]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordVerifierDirective, multi: true }]
})
export class PasswordVerifierDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors {
    return PasswordValidation.CheckPassword(control);
  }
}
