import { Injectable } from '@angular/core';
import { GraphBuilderService } from './graph-builder.service';
import { WorkOrderRequestGraphFilters } from 'src/app/shared/components/query/filters/graphs/work-order-request-filters';
import { WorkOrderMaintenanceGraphFilters } from 'src/app/shared/components/query/filters/graphs/work-order-maintenance-filters';
import { InstalledSignGraphFilters } from 'src/app/shared/components/query/filters/graphs/installed-sign-filters';
import { WorkOrderRepositoryService, TrafficSignRepositoryService } from '../repositories';
import { QueryService } from '../query.service';
import { DateTimeService } from '../datetime.service';
import { FilterChoiceService } from '../filter-choices.service';
import { GraphTile } from '../../interfaces/definitions/graph.definition';
import { IFilter } from '../../interfaces/definitions/filter.definition';
import { IQueryParam } from '../../interfaces/query/query';
import { SignsComponentType, SignsModule } from 'src/app/shared/enums/signs-module';
import { WOR_CREATED_DT, WOR_STAFF_USER } from '../../constants/model-properties/work-order-request.properties';
import {
  WOM_CREATED_DATE_TIME,
  WOM_ISSUED_BY_USER,
} from '../../constants/model-properties/work-order-maintenance.properties';
import { INSTALLED_TS_DATE_TIME } from '../../constants/model-properties/installed-traffic-sign.properties';
import { APP_USER_DISPLAY } from '../../constants/model-properties/app-user.properties';
import { Observable } from 'rxjs';

export const requestRepoSelector = 'requests';
export const maintenanceRepoSelector = 'maintenance';
export const installedSignRepoSelector = 'installed-signs';

@Injectable({
  providedIn: 'root',
})
export class SignGraphBuilderService extends GraphBuilderService {
  private graphRequestFilters: WorkOrderRequestGraphFilters;
  private graphMaintenanceFilters: WorkOrderMaintenanceGraphFilters;
  private graphInstalledFilters: InstalledSignGraphFilters;

  constructor(
    private _workOrderRepo: WorkOrderRepositoryService,
    private _signRepo: TrafficSignRepositoryService,
    _queryService: QueryService,
    _datetimeService: DateTimeService,
    _baseFilters: FilterChoiceService
  ) {
    super(_queryService, _datetimeService);
    this.graphRequestFilters = new WorkOrderRequestGraphFilters(_baseFilters, _datetimeService);
    this.graphMaintenanceFilters = new WorkOrderMaintenanceGraphFilters(_baseFilters, _datetimeService);
    this.graphInstalledFilters = new InstalledSignGraphFilters(_baseFilters);
  }

  /**
   * Takes a GraphTile object and date filter and initializes the filter choices available if
   * they were loaded from strings. Basically turns the string representations of filters into their
   * actual filter list.
   * @param graph The graph that dictates what filters to use.
   * @param dateFilter The date filter so that the correct property name can be set on it.
   * @param userFilter The user filter so that the correct property name can be set on it.
   */
  // initialize(graph: GraphTile, dateFilter: IFilter, userFilter: IFilter): void {
  initialize(graph: GraphTile): void {
    switch (graph.repo) {
      case requestRepoSelector:
        this.setGraphFilter(graph, this.graphRequestFilters);
        graph.redirectUrl = `${SignsModule.componentTypeRoute(SignsComponentType.request)}`;
        break;
      case maintenanceRepoSelector:
        this.setGraphFilter(graph, this.graphMaintenanceFilters);
        graph.redirectUrl = `${SignsModule.componentTypeRoute(SignsComponentType.maintenance)}`;
        break;
      case installedSignRepoSelector:
        this.setGraphFilter(graph, this.graphInstalledFilters);
        graph.redirectUrl = `${SignsModule.componentTypeRoute(SignsComponentType.installedSign)}`;
        break;
    }
  }

  getDateFilterProperty(repo: string): string {
    switch (repo) {
      case requestRepoSelector:
        return WOR_CREATED_DT;
      case maintenanceRepoSelector:
        return WOM_CREATED_DATE_TIME;
      case installedSignRepoSelector:
        return INSTALLED_TS_DATE_TIME;
      default:
        return null;
    }
  }

  getUserFilterProperty(repo: string): string {
    switch (repo) {
      case requestRepoSelector:
        return `${WOR_STAFF_USER}.${APP_USER_DISPLAY}`;
      case maintenanceRepoSelector:
        return `${WOM_ISSUED_BY_USER}.${APP_USER_DISPLAY}`;
      case installedSignRepoSelector:
      default:
        return null;
    }
  }

  /**
   * Return the corresponding filters (i.e. properties that can be used for graphs) from the graph's repo type.
   * @param graph The graph that dictates what filters to use.
   */
  getFilterChoices(graph: GraphTile): IFilter[] {
    switch (graph.repo) {
      case requestRepoSelector:
        return this.graphRequestFilters;
      case maintenanceRepoSelector:
        return this.graphMaintenanceFilters;
      case installedSignRepoSelector:
        return this.graphInstalledFilters;
    }
  }

  /**
   * Return the corresponding repository observable from the graph's repo type.
   * @param queryParam The QueryParam to send to the API.
   * @param graph The graph that dictates what repo to use.
   */
  protected detectRepo(queryParam: IQueryParam, graph: GraphTile): Observable<any> {
    switch (graph.repo) {
      case requestRepoSelector:
        return this._workOrderRepo.getRequests(queryParam);
      case maintenanceRepoSelector:
        return this._workOrderRepo.getMaintenance(queryParam);
      case installedSignRepoSelector:
        return this._signRepo.getInstalledSigns(queryParam);
    }
  }
}
