import { IWorkOrderRequest } from 'src/app/core/interfaces/models';
import { ITableColumn } from 'src/app/core/interfaces/definitions';
import {
  WOR_ID,
  WOR_STATUS,
  WOR_CREATED_DT,
  WOR_DUE_DT,
  WOR_ISSUED_BY_USER,
  WOR_SUPERVISOR_USER,
  WOR_STAFF_USER,
  WOR_ROAD,
  WOR_ROAD_BACK,
  WOR_ROAD_AHEAD,
  WOR_DESC,
  WOR_TYPE,
  WOR_REASON,
  WOR_REASON_DESC,
  WOR_REASON_DATE_TIME,
  WOR_TRAFFIC_SIGN_TYPE,
  WOR_ROAD_SEG_ID,
  WOR_ROAD_SIDE_DIR,
  WOR_NOTES,
  WOR_SIGN_CROSS_STREET_NAME,
  WOR_MAINTENANCE,
  WOR_DIAGRAM_IMAGE_URI,
} from 'src/app/core/constants/model-properties/work-order-request.properties';

export const columns: ITableColumn<IWorkOrderRequest>[] = [
  {
    id: WOR_ID,
    name: 'No.',
    property: 'id',
    visible: true,
  },
  {
    id: WOR_STATUS,
    name: 'Status',
    property: 'status',
    visible: true,
  },
  {
    id: WOR_CREATED_DT,
    name: 'Created',
    type: 'datetime',
    property: 'createdDateTime',
    visible: true,
  },
  {
    id: WOR_DUE_DT,
    name: 'Due',
    type: 'datetime',
    property: 'dueDateTime',
    visible: true,
  },
  {
    id: WOR_ISSUED_BY_USER,
    name: 'Prepared By',
    property: 'issuedByUser',
    visible: true,
  },
  {
    id: WOR_SUPERVISOR_USER,
    name: 'Supervisor',
    property: 'supervisorUser',
    visible: true,
  },
  {
    id: WOR_STAFF_USER,
    name: 'Field Staff',
    property: 'staffUser',
    visible: true,
  },
  {
    id: WOR_ROAD,
    name: 'Road',
    property: 'road',
    visible: true,
  },
  {
    id: WOR_ROAD_BACK,
    name: 'From',
    property: 'roadBack',
    visible: true,
  },
  {
    id: WOR_ROAD_AHEAD,
    name: 'To',
    property: 'roadAhead',
    visible: true,
  },
  {
    id: WOR_DESC,
    name: 'Description',
    property: 'description',
    visible: false,
  },
  {
    id: WOR_TYPE,
    name: 'Type',
    property: 'type',
    visible: false,
  },
  {
    id: WOR_REASON,
    name: 'Reason',
    property: 'reason',
    visible: false,
  },
  {
    id: WOR_REASON_DESC,
    name: 'Reason Description',
    property: 'reasonDescription',
    visible: false,
  },
  {
    id: WOR_REASON_DATE_TIME,
    name: 'Reason Date',
    type: 'datetime',
    property: 'reasonDateTime',
    visible: false,
  },
  {
    id: WOR_TRAFFIC_SIGN_TYPE,
    name: 'Traffic Sign',
    property: 'trafficSignType',
    visible: false,
  },
  {
    id: WOR_ROAD_SEG_ID,
    name: 'Road Segment',
    property: 'roadSegmentId',
    visible: false,
  },
  {
    id: WOR_ROAD_SIDE_DIR,
    name: 'Side of Road',
    property: 'roadSideDir',
    visible: false,
  },
  {
    id: WOR_NOTES,
    name: 'Notes',
    property: 'notes',
    visible: false,
  },
  {
    id: WOR_SIGN_CROSS_STREET_NAME,
    name: 'Sign Cross Street Name',
    property: 'signCrossStreetName',
    visible: false,
  },
  {
    id: WOR_MAINTENANCE,
    name: 'Maintenance ID',
    property: 'maintenance',
    visible: false,
  },
  {
    id: WOR_DIAGRAM_IMAGE_URI,
    name: 'File',
    type: 'img',
    property: 'diagramImageUri',
    visible: true,
  },
];
