import { IQueryFilter } from './query-filter';

export class QueryFilterNot implements IQueryFilter {
  public single: IQueryFilter;
  public displayName?: string;
  public property: string;
  public op: string;

  constructor(single: IQueryFilter, displayName?: string) {
    this.single = single;
    this.displayName = displayName;
    this.property = single.property;
    this.op = single.op;
  }

  public toFilterStr(): string {
    return `{!${this.single.toFilterStr()}}`;
  }

  public cmpQueryfilters(other: QueryFilterNot): boolean {
    if (!(other && other.single)) {
      return false;
    }
    return this.single.cmpQueryfilters(other.single);
  }

  public copy(): QueryFilterNot {
    return new QueryFilterNot(this.single.copy(), this.displayName);
  }
}
