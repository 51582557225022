import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-export-dialog',
  styleUrls: ['./export-dialog.component.css'],
  templateUrl: './export-dialog.component.html',
})
export class ExportDialogComponent implements OnInit {
  /**
   * ViewChild exportTemplate: template to use for content in dialog.
   */
  @ViewChild('exportTemplate') exportTemplate: TemplateRef<any>;
  /**
   * Class Fields
   */
  public exportAllData: boolean;
  public exportAllColumns: boolean;

  constructor(public dialogRef: MatDialogRef<ExportDialogComponent>) {
    this.exportAllData = false;
  }

  ngOnInit() {}

  public setAllData(event: MatRadioChange): void {
    this.exportAllData = event.value;
  }

  public setAllColumns(event: MatRadioChange): void {
    this.exportAllColumns = event.value;
  }

  public close(result: boolean): void {
    result ? this.onSubmit() : this.onCancel();
  }

  public onSubmit() {
    this.dialogRef.close({ allData: this.exportAllData, allColumns: this.exportAllColumns });
  }

  public onCancel(): void {
    this.dialogRef.close(null);
  }
}
