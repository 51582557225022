import { Input, OnDestroy } from '@angular/core';
import { IFilter } from 'src/app/core/interfaces/definitions/filter.definition';
import { IQueryFilter } from 'src/app/core/interfaces/query/filters/query-filter';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { UtilsService } from 'src/app/core/services/utils.service';

export class FilterInput implements OnDestroy {
  @Input() filter: IFilter;
  @Input() appearance = 'outline';
  control: FormControl;
  protected _destroy$ = new Subject();

  constructor(protected _utilsService: UtilsService) {}

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  protected hasChanged(newFilter: IQueryFilter): boolean {
    const oldValue = this.filter.filter.getValue();
    if (newFilter && !newFilter.cmpQueryfilters(oldValue)) {
      // filter's old value is not the same
      return true;
    } else if (!newFilter && oldValue) {
      // changed from null to not null or vice versa
      return true;
    }
    return false;
  }

  clearControl(): void {
    this.control.setValue(null);
  }

  clearFilter(): void {
    this.filter.filter.next(null);
  }
}
