import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlBuilder',
})
export class UrlBuilderPipe implements PipeTransform {
  /**
   * Add a child path to a navigation string array and return a copy.
   * @param value The current navigation array
   * @param child The path string to append to the value.
   */
  transform(value: string[], child?: string): string[] {
    const valCopy = value.slice();
    if (child) {
      valCopy.push(child);
    }
    return valCopy;
  }
}
