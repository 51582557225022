import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { IAppUser } from '../interfaces/models/app-user.model';
import { AuthenticationService } from '../services';
import { APP_ROLES } from '../services/repositories';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(private _router: Router, private _authService: AuthenticationService) {}

  /*
   * Validate that the user has permission to proceed to the given route. Return an
   * observable, which the routing will wait for to resolve true or false.
   */
  canActivate(_router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let permissionRoles = APP_ROLES;
    if (_router.routeConfig.data && _router.routeConfig.data.roles) {
      permissionRoles = _router.routeConfig.data.roles;
    }
    return this._authService.currentUser().pipe(
      map((user: IAppUser) => {
        for (const role of user.userRoles) {
          if (permissionRoles.includes(role.role.name)) {
            return true;
          }
        }

        return this._router.parseUrl('/403');
      })
    );
  }
}
