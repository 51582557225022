import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[noSelectionDisabled]'
})
export class NoSelectionDisableDirective implements OnInit {

  private _disabled: boolean = true;

  @Input()
  set noSelectionDisabled(value: Array<any>) {
    this._disabled = !value || value.length <= 0;
    this._setElementDisabled();
  }

  constructor(private el: ElementRef) { }

  public ngOnInit() {
    this._setElementDisabled();
  }

  private _setElementDisabled() {
    this.el.nativeElement.disabled = this._disabled;
  }
}
