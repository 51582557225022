import { FormControl, ValidatorFn } from '@angular/forms';

/*
 * Validate user input in fields with dropdown lists and force selection
 * in cases where only one value remains. Validation is checking the value
 * against the list to ensure it is a member.
 *
 * FIXME: This requires values list to be objects with id field.
 */
export function ValidateDropdownList(list: any[], autocorrect: boolean = true): ValidatorFn {
  return (FC: FormControl) => {
    const ValueInList = 'ValueInList';
    if (FC.errors && FC.errors[ValueInList]) {
      delete FC.errors[ValueInList];
    }

    if (autocorrect) {
      AutoCompleteField(FC, list);
    }

    let valuesList = null;
    if (FC.value instanceof Array) {
      valuesList = FC.value.map((val) => val.id);
    }

    // Input is valid if the form control value an empty string (let Validators.required handle that case),
    // or if the value is in the list
    const inList: boolean =
      (typeof FC.value === 'string' && !FC.value) ||
      (list &&
        list.length > 0 &&
        ((!!valuesList &&
          (valuesList.length <= 0 || list.filter((v: any) => v && valuesList.includes(v.id)).length !== 0)) ||
          list.filter((val: any) => val && FC.value && val.id === FC.value.id).length !== 0));

    return inList ? null : { ValueInList: true };
  };
}

export function AutoCompleteField(FC: FormControl, list: any[]) {
  const FCValueIsString: boolean = FC.value instanceof String || typeof FC.value === 'string';

  if (FCValueIsString && list && list instanceof Array) {
    const fltrList = list.filter((option) => {
      if (!option) {
        return false;
      }

      // The value to compare against.
      const value = FC.value.toLocaleLowerCase();

      // TODO: implement a more generic set up to be able to handle different objects
      // not so hard coded.

      // String values
      if (typeof option === 'string' && option.toLocaleLowerCase() === value) {
        return true;
      }

      // General objects
      if (
        (option.displayName || option.name || option.streetname) &&
        (option.displayName || option.name || option.streetname).toLocaleLowerCase() === value
      ) {
        return true;
      }

      // Users
      if (
        option.firstName &&
        option.lastName &&
        `${option.firstName} ${option.lastName}`.toLocaleLowerCase() === value
      ) {
        return true;
      }

      // Installed Traffic Signs
      if (
        option.trafficSignType &&
        option.trafficSign &&
        `No. ${option.id} ${option.trafficSignType.displayName} - Barcode: ${option.trafficSign.barcode}`.toLocaleLowerCase() ===
          value
      ) {
        return true;
      }
      if (
        option.trafficSignType &&
        `No. ${option.id} ${option.trafficSignType.displayName}`.toLocaleLowerCase() === value
      ) {
        return true;
      }

      return false;
    });

    if (fltrList.length === 1) {
      FC.setValue(fltrList[0]);
    }
  }
}
