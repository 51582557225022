import { Pipe, PipeTransform } from '@angular/core';
import { IAppUser } from 'src/app/core/interfaces/models/app-user.model';

@Pipe({
  name: 'user',
})
export class UserPipe implements PipeTransform {
  transform(user: IAppUser, property?: any): any {
    if (user) {
      return property ? user[property] : user.displayName;
    }
  }
}
