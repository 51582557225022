import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';

/**
 * Custom data source for entity objects
 * Handles updating, filtering and sorting without needing to know about the underlying API call
 */
export class EntityDataSource<T> extends MatTableDataSource<T> {
  private _resultsLength: number;

  constructor(private subject: BehaviorSubject<T[]>) {
    super();
    this._resultsLength = -1;
  }

  connect(): BehaviorSubject<T[]> {
    return this.subject;
  }

  disconnect(): void {}

  public updateResultsLength(len: number): void {
    this._resultsLength = len;
    this._updatePaginator(this._resultsLength);
  }

  public _updatePaginator(dataLength: number): void {
    const len = this._resultsLength >= 0 ? this._resultsLength : dataLength;
    super._updatePaginator(len);
  }

  public resultsLength(): number {
    return Math.max(0, this._resultsLength);
  }

  /**
   * Custom sorting
   *
   * FIXME: sorting should be defined by the object T, then have a generic sort method
   * that acts according to the object sorting definition.
   */
  public onSort() {
    let data = this.data.slice();
    if (this.sort.active && this.sort.direction !== '') {
      const key = this.sort.active;
      const ascending = this.sort.direction === 'asc';
      data = data.sort((entity1, entity2) => {
        // Store intermediate result
        let result1: string;
        let result2: string;
        switch (key) {
          case 'type':
            return this.compare(entity1[key].name, entity2[key].name, ascending);
          case 'location':
            if (entity1['location']) {
              result1 = `${entity1[key].road}, from ${entity1[key].roadBack} to ${entity1[key].roadAhead}`;
              result2 = `${entity2[key].road}, from ${entity2[key].roadBack} to ${entity2[key].roadAhead}`;
            } else {
              result1 = `${entity1['road']}, from ${entity1['roadBack']} to ${entity1['roadAhead']}`;
              result2 = `${entity2['road']}, from ${entity2['roadBack']} to ${entity2['roadAhead']}`;
            }
            return this.compare(result1, result2, ascending);
          case 'issuedByUser':
          case 'supervisorUser':
          case 'staffUser':
            result1 = entity1[key] ? entity1[key].userName : 'Not Assigned';
            result2 = entity2[key] ? entity2[key].userName : 'Not Assigned';
            return this.compare(result1, result2, ascending);
          case 'dueDateTime':
          case 'createdDateTime':
            const date1 = new Date(entity1[key]);
            const date2 = new Date(entity2[key]);
            return this.compare(date1, date2, ascending);
          case 'work-performed':
            return this.compare(entity1['workPerformed'].name, entity2['workPerformed'].name, ascending);
          default:
            return this.compare(entity1[key], entity2[key], ascending);
        }
      });
      this.subject.next(data);
    }
  }

  /**
   * Simple comparer
   * @param a First item
   * @param b Second Item
   * @param ascending Whether we're sorting by ascending or descending
   */
  private compare(a: any, b: any, ascending: boolean): number {
    return (a > b ? 1 : -1) * (ascending ? 1 : -1);
  }
}
