import { IAudit } from 'src/app/core/interfaces/models';
import { DateTimeService } from 'src/app/core/services/datetime.service';
import { Observable } from 'rxjs';

/**
 * Base class for displaying a history view generated from IAudit endpoint calls.
 * Handles value type conversions and a basic level of formatting.
 *
 * To create a new history view:
 * 1. Extend from this class
 * 2. Set historyObs to be the IAudit endpoint call
 * 3. Pipe any extra processing onto historyObs and return the final IAudit[]
 * @see InstalledTrafficSignHistoryView for an example of more complicated processing.
 */
export class HistoryView {
  protected historyObs: Observable<IAudit[]>;

  constructor(protected _datetimeService: DateTimeService) {}

  /**
   * Get the Audit history associated with the view
   */
  public getHistory(): Observable<IAudit[]> {
    return this.historyObs;
  }

  public processAuditRecord(record: IAudit): string[] | undefined {
    if (record) {
      // Process and format the audit change for the record.

      const jsonNew = JSON.parse(record.newValues);
      const jsonOld = JSON.parse(record.oldValues);

      if (jsonNew && jsonOld) {
        const changes: string[] = [];
        const keys = Object.keys(jsonNew);

        for (const key of keys) {
          if (!key.toLocaleLowerCase().endsWith('id') && jsonNew[key] !== jsonOld[key]) {
            let oldVal = jsonOld[key];
            if (oldVal && this._datetimeService.isDate(oldVal)) {
              const oldDate = this._datetimeService.convertUtcToLocal(oldVal);
              oldVal = this._datetimeService.formatDatetime(oldDate);
            }

            let newVal = jsonNew[key];
            if (newVal && this._datetimeService.isDate(newVal)) {
              const newDate = this._datetimeService.convertUtcToLocal(newVal);
              newVal = this._datetimeService.formatDatetime(newDate);
            }

            if (oldVal && newVal) {
              changes.push(`${key} changed from '${oldVal}' to '${newVal}'`);
            } else if (oldVal) {
              changes.push(`${key} cleared (was '${oldVal}')`);
            } else if (newVal) {
              changes.push(`${key} set to '${newVal}'`);
            }
          }
        }

        if (changes.length > 0) {
          return changes;
        } else {
          return undefined;
        }
      } else if (jsonNew) {
        const changes: string[] = [];
        const keys = Object.keys(jsonNew);

        for (const key of keys) {
          let change: string;
          if (this._datetimeService.isDate(jsonNew[key])) {
            const newDate = this._datetimeService.convertUtcToLocal(jsonNew[key]);
            change = `${key}: ${this._datetimeService.formatDatetime(newDate)}`;
          } else {
            change = `${key}: ${jsonNew[key]}`;
          }
          changes.push(change);
        }

        return changes;
      } else if (jsonOld) {
        return ['Record Removed'];
      }
    } else {
      return ['Invalid Record'];
    }
  }
}
