import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from 'src/app/core/services/utils.service';

@Pipe({
  name: 'display',
})
export class DisplayPipe implements PipeTransform {
  constructor() {}

  transform(value: any, property: string = null): any {
    return UtilsService.displayData(value, property);
  }
}
