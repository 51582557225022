import { ITableColumn } from 'src/app/core/interfaces/definitions';
import { IWorkOrderMaintenance } from 'src/app/core/interfaces/models';
import {
  WOM_ID,
  WOM_CREATED_DATE_TIME,
  WOM_ISSUED_BY_USER,
  WOM_STATUS,
  WOM_TYPE,
  WOM_REASON,
  WOM_WORK_PERFORMED,
  WOM_APPROVED_BY_USER,
  WOM_INSTALLED_TS_ID,
  WOM_NOTES,
  WOM_DURATION,
  WOM_DURATION_UNIT,
  WOM_ASSIGNMENTS,
} from 'src/app/core/constants/model-properties/work-order-maintenance.properties';

export const columns: ITableColumn<IWorkOrderMaintenance>[] = [
  {
    id: WOM_ID,
    name: 'ID',
    property: 'id',
    visible: true,
  },
  {
    id: WOM_CREATED_DATE_TIME,
    name: 'Date',
    type: 'datetime',
    property: 'createdDateTime',
    visible: true,
  },
  {
    id: WOM_ISSUED_BY_USER,
    name: 'Prepared by',
    property: 'issuedByUser',
    visible: true,
  },
  {
    id: WOM_APPROVED_BY_USER,
    name: 'Approved by',
    property: 'approvedByUser',
    visible: true,
  },
  {
    id: WOM_STATUS,
    name: 'Status',
    property: 'status',
    visible: true,
  },
  {
    id: WOM_TYPE,
    name: 'Type',
    property: 'type',
    visible: true,
  },
  {
    id: WOM_REASON,
    name: 'Reason',
    property: 'reason',
    visible: true,
  },
  {
    id: WOM_WORK_PERFORMED,
    name: 'Work Performed',
    property: 'workPerformed',
    visible: true,
  },
  {
    id: WOM_INSTALLED_TS_ID,
    name: 'Installed Traffic Sign Id',
    property: 'installedTrafficSignId',
    visible: true,
  },
  {
    id: WOM_NOTES,
    name: 'Notes',
    property: 'notes',
    visible: true,
  },
  // TODO: merge duration value and unit into one column.
  {
    id: WOM_DURATION,
    name: 'Duration',
    property: 'duration',
    visible: true,
  },
  {
    id: WOM_DURATION_UNIT,
    name: 'Duration Unit',
    property: 'durationUnit',
    visible: true,
  },
];
