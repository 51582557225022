import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-splash-page',
  templateUrl: './splash-page.component.html',
  styleUrls: ['./splash-page.component.scss'],
})
export class SplashPageComponent implements OnInit {
  // todo: change to SVG if possible, this is kind of blurry on mobile
  loginIconPath = 'assets/images/logos/asset-city-login-icon.png';

  @Input() isLoading: boolean;
  @Input() message: string;

  constructor() {}

  ngOnInit(): void {}
}
