/**
 * Query constants for building query parameters in an
 * Http request url.
 */

// Prefix char to begin any query in a request url
export const Q_PREFIX = '?';
// Join char for multiple query parameters
export const Q_JOIN = '&';
// Assignment char for creating a query key, value pair
export const Q_ASSIGN = '=';

/**
 * Query Parameter Constants
 * These constants are specific to the API, they must
 * match what the API defines, in order for the query url to bind.
 *
 * https://github.com/Trans-PlanInc/tp-api-internal-traffic-studies/wiki/Querying
 */
export const Q_FIELD = 'qf';
export const Q_FILTER = 'qFilter';
export const Q_SORT_PRIMARY = 'qsPrimary';
export const Q_SORT_SECONDARY = 'qsSecondary';
export const Q_SORT_IS_ASC = 'qsIsAsc';
export const Q_PAGE_COUNT = 'qpCount';
export const Q_PAGE_OFFSET = 'qpOffset';

// Join char for building query filter clauses
export const Q_FILTER_JOIN = ':';

/** Query Filter Operators */
export const Q_AND = '^';
export const Q_OR = '|';
export const Q_OP_GT = 'GT';
export const Q_OP_GTE = 'GTE';
export const Q_OP_LT = 'LT';
export const Q_OP_LTE = 'LTE';
export const Q_OP_EQ = 'EQ';
export const Q_OP_NEQ = 'NEQ';
export const Q_OP_IN = 'IN';
export const Q_OP_CON = 'CON';
export const Q_OP_SW = 'SW';
export const Q_OP_EW = 'EW';
export const Q_OP_BTW = 'BTW';
export const Q_OP_TRUE = 'IsTrue';
export const Q_OP_FALSE = 'IsFalse';
export const Q_OP_NULL = 'IsNull';
export const Q_OP_NOTNULL = 'NotNull';

/** Query Select All */
export const Q_ALL_FIELDS = '*';

const Q_UNARY_OPS = [Q_OP_TRUE, Q_OP_FALSE, Q_OP_NULL, Q_OP_NOTNULL];
export function isUnary(op: string): boolean {
  return Q_UNARY_OPS.includes(op);
}
