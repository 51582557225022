import { Component, Input, Output, EventEmitter, OnInit, HostListener } from '@angular/core';
import { ConnectedPosition, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangeSelectorComponent implements OnInit {
  /**
   * Output: valueEntered
   */
  @Output() dateEntered = new EventEmitter<[moment.Moment, moment.Moment] | string>();
  /**
   * Input: dateRange
   */
  @Input()
  set dateRange(value: [moment.Moment, moment.Moment]) {
    if (value) {
      this.dateStart.setValue(value[0]);
      this.dateEnd.setValue(value[1]);
    }
  }

  @Input() useNoDate: boolean;
  @Input() overlayPosition: 'start' | 'center' | 'end';
  @Input() overlayOrigin: CdkOverlayOrigin;
  @Input() toggle: boolean;
  @Output() toggleChange = new EventEmitter<boolean>();

  /**
   * Class Fields
   */
  positions: ConnectedPosition[];
  dateStart: FormControl;
  dateEnd: FormControl;
  date: 'no date' | [moment.Moment, moment.Moment];
  isMobile: boolean;
  isTablet: boolean;

  @HostListener('window:resize', ['$event'])
  resize(): void {
    this.isMobile = this._stateService.isMobile();
    this.isTablet = this._stateService.isTablet();
  }

  constructor(private _stateService: StateService) {
    this.dateStart = new FormControl();
    this.dateEnd = new FormControl();
    this.resize();
  }

  ngOnInit(): void {
    if (!this.overlayPosition) {
      this.overlayPosition = 'center';
    }
    this.positions = [
      {
        overlayX: this.overlayPosition,
        overlayY: 'top',
        originX: this.overlayPosition,
        originY: 'bottom',
      },
    ];
  }

  close(emitChange: boolean = false): void {
    if (this.toggle) {
      if (emitChange) {
        this.date = [this.dateStart.value, this.dateEnd.value];
        this.emitNewDate();
      }
      this.toggle = false;
      this.toggleChange.emit(this.toggle);
    }
  }

  emitNewDate(): void {
    this.date = !!this.dateStart.value || !!this.dateEnd.value || this.date === 'no date' ? this.date : null;
    this.dateEntered.emit(this.date);
  }

  remove(start?: boolean, end?: boolean): void {
    if (this.date === 'no date') {
      this.date = null;
    } else if (start) {
      this.dateStart.setValue(null);
      this.date = [null, this.dateEnd.value];
    } else if (end) {
      this.dateEnd.setValue(null);
      this.date = [this.dateStart.value, null];
    } else {
      this.dateStart.setValue(null);
      this.dateEnd.setValue(null);
      this.date = [null, null];
    }
    this.emitNewDate();
  }

  setNoDate(): void {
    if (this.date !== 'no date') {
      this.date = 'no date';
      this.emitNewDate();
    }
    this.close(false);
  }

  clear(): void {
    this.dateStart.setValue(null);
    this.dateEnd.setValue(null);
    this.date = null;
  }
}
