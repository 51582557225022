/**
 * Object representing a query filter that is used to build
 * a query url string to pass to an HTTP GET endpoint. Query filters'
 * values are strict in such a way that their values must match
 * what the backend server defines. For example, 'property' values
 * must match (case-sensitive) exactly the name of the model property
 * defined by the API. As well as the 'op' must match from the list of
 * query operators defined by the API.
 */
export interface IQueryFilter {
  property: string;
  op: string;
  displayName?: string;
  cmpQueryfilters: (other: IQueryFilter) => boolean;
  toFilterStr: () => string;
  copy: () => IQueryFilter;
}

export class QueryFilterExpression implements IQueryFilter {
  public property: string;
  public op: string;
  public value?: any;
  public displayName?: string;

  constructor(property: string, op: string, value?: any, displayName?: string) {
    this.property = property;
    this.op = op;
    this.value = value;
    this.displayName = displayName;
  }

  public toFilterStr(): string {
    const filter = `${this.property}:${this.op}`;
    return this.value ? `${filter}:${this.value}` : filter;
  }

  public isQueryFilter(value: any): boolean {
    return value && typeof value === 'object' && value.hasOwnProperty('property') && value.hasOwnProperty('op');
  }

  public cmpQueryfilters(other: QueryFilterExpression): boolean {
    let res = this.isQueryFilter(other);
    res = res && this.property === other.property;
    res = res && this.op === other.op;
    res = res && this.value === other.value;
    return res;
  }

  public copy(): QueryFilterExpression {
    return new QueryFilterExpression(this.property, this.op, this.value, this.displayName);
  }
}
