import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';

interface ISelectionItem {
  id: any;
  name: string;
}

@Component({
  selector: 'app-list-selection-dialog',
  styleUrls: ['./list-selection-dialog.component.scss'],
  templateUrl: './list-selection-dialog.component.html',
})
export class ListSelectionDialogComponent implements OnInit {
  @ViewChild('selection') selection: MatSelectionList;
  public items: ISelectionItem[];
  public isChecked: (item: ISelectionItem) => boolean;
  public allSelected: boolean;

  constructor(public dialogRef: MatDialogRef<ListSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.items = data.items;
      this.isChecked = data.isCheckedFn;
    }
  }

  ngOnInit() {
    this.allSelected = this.items.map((x) => this.isChecked(x)).filter((x) => x).length === this.items.length;
  }

  public selectionChanged(event: MatSelectionListChange): void {
    if (event.option.value === null) {
      event.option.selected ? this.selection.selectAll() : this.selection.deselectAll();
      this.allSelected = event.option.selected;
    } else {
      this.checkAllSelected();
    }
  }

  private checkAllSelected() {
    let numSelected = this.selection.selectedOptions.selected.length;
    if (this.allSelected) {
      // account for the actual "Select All" option.
      numSelected -= 1;
    }
    this.allSelected = numSelected === this.items.length;
  }

  public onSubmit() {
    this.dialogRef.close(this.selection.selectedOptions.selected);
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }
}
