import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryFilterExpression, IQueryFilter } from '../interfaces/query/filters/query-filter';
import { Q_OP_EQ } from '../constants/query.constants';
import {
  IWorkOrderType,
  IWorkOrderReason,
  IWorkOrderStatus,
  IWorkOrderWorkPerformed,
  ITrafficSignCondition,
  ITrafficSignType,
  ITrafficSignStatus,
  IAppUser,
  IAppRole,
} from '../interfaces/models';
import { BaseChoiceService } from './base-choices.service';

@Injectable({
  providedIn: 'root',
})
export class FilterChoiceService {
  constructor(private baseChoiceService: BaseChoiceService) {}

  /**
   * Get Work Types for work order requests (i.e. with unplanned maintenance filtered)
   * @param id The property on the object that matches the object we're filtering on
   */
  public getRequestWorkTypeFilters(id: string): Observable<IQueryFilter[]> {
    return this.baseChoiceService.getTypeChoices().pipe(
      map((res: IWorkOrderType[]) => res.filter((x) => !x.name.includes('Unplanned'))),
      map((res: IWorkOrderType[]) => res.map((x) => new QueryFilterExpression(id, Q_OP_EQ, x.name, x.name)))
    );
  }

  /**
   * Get Work Reasons for work order requests (i.e. with unplanned maintenance filtered)
   * @param id The property on the object that matches the object we're filtering on
   */
  public getRequestWorkReasonFilters(id: string): Observable<IQueryFilter[]> {
    return this.baseChoiceService.getReasonChoices().pipe(
      map((res: IWorkOrderReason[]) => res.filter((x) => !x.name.includes('Unplanned'))),
      map((res: IWorkOrderReason[]) =>
        res.map((x) => new QueryFilterExpression(id, Q_OP_EQ, x.name, `${x.name} - ${x.type.name}`))
      )
    );
  }

  public getWorkTypeFilters(id: string): Observable<IQueryFilter[]> {
    return this.baseChoiceService
      .getTypeChoices()
      .pipe(map((res: IWorkOrderType[]) => res.map((x) => new QueryFilterExpression(id, Q_OP_EQ, x.name, x.name))));
  }

  public getWorkReasonFilters(id: string): Observable<IQueryFilter[]> {
    return this.baseChoiceService
      .getReasonChoices()
      .pipe(
        map((res: IWorkOrderReason[]) =>
          res.map((x) => new QueryFilterExpression(id, Q_OP_EQ, x.name, `${x.name} - ${x.type.name}`))
        )
      );
  }

  public getWorkStatusFilters(id: string): Observable<IQueryFilter[]> {
    return this.baseChoiceService
      .getWorkStatusChoices()
      .pipe(map((res: IWorkOrderStatus[]) => res.map((x) => new QueryFilterExpression(id, Q_OP_EQ, x.name, x.name))));
  }

  public getWorkPerformedFilters(id: string): Observable<IQueryFilter[]> {
    return this.baseChoiceService
      .getWorkPerformedChoices()
      .pipe(
        map((res: IWorkOrderWorkPerformed[]) => res.map((x) => new QueryFilterExpression(id, Q_OP_EQ, x.name, x.name)))
      );
  }

  public getConditionsObservable(id: string): Observable<IQueryFilter[]> {
    return this.baseChoiceService.getConditionChoices().pipe(
      map((res: ITrafficSignCondition[]) => {
        return res.map((x) => new QueryFilterExpression(id, Q_OP_EQ, x.name, x.name));
      })
    );
  }

  public getSignTypeFilters(id: string) {
    return this.baseChoiceService
      .getSignChoices()
      .pipe(
        map((res: ITrafficSignType[]) => res.map((x) => new QueryFilterExpression(id, Q_OP_EQ, x.name, x.displayName)))
      );
  }

  public getSignStatusFilters(id: string) {
    return this.baseChoiceService
      .getSignStatusChoices()
      .pipe(map((res: ITrafficSignStatus[]) => res.map((x) => new QueryFilterExpression(id, Q_OP_EQ, x.name, x.name))));
  }

  public getUserFilters(id: string): Observable<IQueryFilter[]> {
    return this.baseChoiceService
      .getUsersChoices()
      .pipe(
        map((res: IAppUser[]) => res.map((x) => new QueryFilterExpression(id, Q_OP_EQ, x.displayName, x.displayName)))
      );
  }

  public getSupervisorFilters(id: string): Observable<IQueryFilter[]> {
    return this.baseChoiceService
      .getSupervisorUsersChoices()
      .pipe(
        map((res: IAppUser[]) => res.map((x) => new QueryFilterExpression(id, Q_OP_EQ, x.displayName, x.displayName)))
      );
  }

  public getUserRoleFilters(id: string): Observable<IQueryFilter[]> {
    return this.baseChoiceService
      .getUserRoleChoices()
      .pipe(map((res: IAppRole[]) => res.map((x) => new QueryFilterExpression(id, Q_OP_EQ, x.name, x.name))));
  }
}
