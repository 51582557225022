import { Pipe, PipeTransform } from '@angular/core';
import { IFilter } from '../../core/interfaces/definitions/filter.definition';
import { IQueryFilter } from '../../core/interfaces/query/filters/query-filter';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(filter: IFilter, valueType?: 'string' | 'queryfilter'): string | IQueryFilter {
    if (filter) {
      switch (valueType) {
        case 'queryfilter':
          return filter.filter.value;
        case 'string':
        default:
          return filter.display;
      }
    } else {
      return '';
    }
  }
}
