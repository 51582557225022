import { Pipe, PipeTransform } from '@angular/core';
import { AssetService } from 'src/app/core/services';
import { UtilsService } from 'src/app/core/services/utils.service';
import { of } from 'rxjs';

@Pipe({
  name: 'image',
})
export class ImagePipe implements PipeTransform {
  constructor(private _assetService: AssetService, private _utils: UtilsService) {}

  transform(value: any, property: string = null): any {
    const val = this._utils.getNestedValue(value, property);
    return this._assetService.getAsset(val) ?? of('assets/images/no-image.png');
  }
}
