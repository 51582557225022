// Esri ArcGIS constants
//
// Map positions
export const POSITION_TOP_LEFT = 'top-left';
export const POSITION_TOP_RIGHT = 'top-right';
export const POSITION_BOTTOM_LEFT = 'bottom-left';
export const POSITION_BOTTOM_RIGHT = 'bottom-right';
//
// Cursor shapes
export const MAP_POINTER_DEFAULT = 'default';
//
// Symbol Types
export const SYMBOL_TYPE_SIMPLE = 'simple-marker';
export const SYMBOL_TYPE_PICTURE = 'picture-marker';
//
// Unique identifier field name for graphics
export const FIELD_U_ID = 'U_ID';
// Misc.
export const GEOMETRY_TYPE_POINT = 'point';
export const VIEW_TYPE_MAPVIEW = 'MapView';

// AssetCity specific constants for ArcGIS
//
// Layer IDs and a list of interactive layer that user can interact with via mouse click, move, etc.
export const ROAD_LAYER_ID = 'assetcity-roads-layer';
export const INSTALLED_SIGNS_LAYER_ID = 'assetcity-installed-signs-layer';
export const INTERACTIVE_LAYER_IDS = [ROAD_LAYER_ID, INSTALLED_SIGNS_LAYER_ID];
// Installed Traffic Signs Object Id (used for feature highlights, popup templates, etc.)
export const INSTALLED_SIGNS_OID = 'assitcity-installed-sign-id';
// Widget IDs
export const WID_FEAT_HL_ID = 'assetcity-feature-highlight-widget';
export const WID_BASEMAPGAL_ID = 'assetcity-basemap-gallery-widget';
export const WID_LAYERLIST_ID = 'assetcity-layer-list-widget';
export const WID_SEARCH_ID = 'assetcity-search-widget';
export const WID_SKETCH_ID = 'assetcity-sketch-widget';
// Defaults
export const DEFAULT_BASEMAP = 'topo-vector';
export const DEFAULT_ZOOM = 13;
