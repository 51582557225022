//
// Esri Core Object
//
export const MOD_GRAPHIC = 'esri/Graphic';
export const MOD_MAP = 'esri/Map';
export const MOD_WEBMAP = 'esri/WebMap';
//
// Esri Core Modules
//
export const MOD_CORE = 'esri/core';
export const MOD_GEOMETRY = 'esri/geometry';
export const MOD_LAYERS = 'esri/layers';
export const MOD_VIEWS = 'esri/views';
export const MOD_WIDGETS = 'esri/widgets';
//
// Geometry Modules
//
export const MOD_GEO_POINT = `${MOD_GEOMETRY}/Point`;
export const MOD_GEO_POLYLINE = `${MOD_GEOMETRY}/Polyline`;
//
// Layer Modules
//
export const MOD_LAY_GRAPHIC = `${MOD_LAYERS}/GraphicsLayer`;
export const MOD_LAY_FEATURE = `${MOD_LAYERS}/FeatureLayer`;
//
// View Modules
//
export const MOD_VIEW_MAPVIEW = `${MOD_VIEWS}/MapView`;
//
// Widget Modules
//
export const MOD_WID_BASEMAPGAL = `${MOD_WIDGETS}/BasemapGallery`;
export const MOD_WID_LAYERLIST = `${MOD_WIDGETS}/LayerList`;
export const MOD_WID_EXPAND = `${MOD_WIDGETS}/Expand`;
export const MOD_WID_FEATURE = `${MOD_WIDGETS}/Feature`;
export const MOD_WID_SEARCH = `${MOD_WIDGETS}/Search`;
export const MOD_WID_SKETCH = `${MOD_WIDGETS}/Sketch`;
//
// Utils
//
export const MOD_UTIL_PROMISE = `${MOD_CORE}/promiseUtils`;
