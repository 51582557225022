import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from 'src/app/core/services/utils.service';

@Pipe({
  name: 'html',
})
export class HtmlPipe implements PipeTransform {
  constructor(private _utils: UtilsService) {}
  /**
   * Take an object and list of properties to display from the object, and transform it into an html
   * string that shows each object property value on a newline.
   * @param objVal : the object value to display
   * @param displayFields : list of tuples with first item being the property of the object to display,
   * and second item being the label
   */
  transform(objVal: any, displayFields: [string, string][]): unknown {
    const strs = [];
    for (const field of displayFields) {
      const val = this._utils.getNestedValue(objVal, field[0]);
      strs.push(`<span>${field[1]}: ${val}</span>`);
    }
    return strs.join('\n');
  }
}
