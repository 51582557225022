import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeService } from 'src/app/core/services/datetime.service';

@Pipe({
  name: 'momentDate',
})
export class MomentDatePipe implements PipeTransform {
  constructor(private _datetimeService: DateTimeService) {}

  transform(date: string): any {
    return this._datetimeService.convertUtcToLocal(date);
  }
}
