import { Pipe, PipeTransform } from '@angular/core';
import { IQueryFilter } from '../../core/interfaces/query/filters/query-filter';

@Pipe({
  name: 'queryFilter',
})
export class QueryFilterPipe implements PipeTransform {
  transform(filter: IQueryFilter): string {
    return filter ? filter.displayName || 'None' : '';
  }
}
