/**
 * IFormControl supported input types
 */
export const CTRL_TYPE_TEXT = 'text';
export const CTRL_TYPE_BOOL = 'checkbox';
export const CTRL_TYPE_INT = 'number';
export const CTRL_TYPE_DATETIME = 'datetime';
/** Input type displays list of choices that can be selected and filtered by typing. */
export const CTRL_TYPE_CHOICES = 'choices';
/** Input type displays list of choices that can only be selected. */
export const CTRL_TYPE_SELECT = 'select';
/** Control displays file chooser button to upload files. */
export const CTRL_TYPE_FILE = 'file';
export const CTRL_TYPE_GPS = 'gps';
