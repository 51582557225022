import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkOrderUtilsService } from '../services/work-order-utils.service';
import { WorkOrderRepositoryService, AppUserRepositoryService } from '../services/repositories';
import { IAppUser, IWorkOrderMaintenance, IWorkOrderRequest } from '../interfaces/models';
import { QueryService } from '../services/query.service';
import { WOR_STAFF_USER_ID, WOR_MAINTENANCE } from '../constants/model-properties/work-order-request.properties';
import { WOM_ISSUED_BY_USER_ID, WOM_STATUS } from '../constants/model-properties/work-order-maintenance.properties';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderMaintenanceFormGuard implements CanActivate {
  constructor(
    private _repo: WorkOrderRepositoryService,
    private _userRepo: AppUserRepositoryService,
    private _queryService: QueryService,
    private _utils: WorkOrderUtilsService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const request$ = !!next.queryParams.requestId
      ? this._repo.getRequest(
          next.queryParams.requestId,
          this._queryService.toQueryFromFields([
            WOR_STAFF_USER_ID,
            WOR_MAINTENANCE,
            `${WOR_MAINTENANCE}.${WOM_ISSUED_BY_USER_ID}`,
            `${WOR_MAINTENANCE}.${WOM_STATUS}`,
          ])
        )
      : of(null);

    const maintenance$ = !!next.queryParams.maintenanceId
      ? this._repo.getMaintenanceById(
          next.queryParams.maintenanceId,
          this._queryService.toQueryFromFields([WOM_ISSUED_BY_USER_ID, WOM_STATUS])
        )
      : of(null);

    return forkJoin([this._userRepo.getCurrentUser(), request$, maintenance$]).pipe(
      map((results: [IAppUser, IWorkOrderRequest, IWorkOrderMaintenance]) => {
        const user = results[0];
        const request = results[1];
        const maintenance = results[2];

        if (!user) {
          return false;
        }

        if (request) {
          if (!this._utils.canEditRequest(request, user, true)) {
            return false;
          }

          if (request.maintenance && !this._utils.canEditMaintenance(request.maintenance, user, true)) {
            return false;
          }
        }

        if (maintenance && !this._utils.canEditMaintenance(maintenance, user, true)) {
          return false;
        }

        return true;
      })
    );
  }
}
