import { IFilter } from 'src/app/core/interfaces/definitions/filter.definition';
import { BehaviorSubject } from 'rxjs';
import { FilterChoiceService } from 'src/app/core/services/filter-choices.service';
import { DateTimeService } from 'src/app/core/services/datetime.service';
import { IQueryFilter } from 'src/app/core/interfaces/query/filters/query-filter';

export class WorkOrderMaintenanceGraphFilters extends Array<IFilter> {
  private lessThanDateFilter: IQueryFilter;
  private moreThanDateFilter: IQueryFilter;
  private noDateFilter: IQueryFilter;

  /**
   * Filter definitions for Work Order Maintenance objects.
   */
  public filters: IFilter[] = [
    {
      display: 'Prepared By',
      propertyName: 'IssuedByUser.DisplayName',
      type: 'dropdown',
      multi: false,
      choices: this._baseFilters.getUserFilters('IssuedByUser.DisplayName'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Status',
      propertyName: 'Status.Name',
      type: 'dropdown',
      multi: false,
      choices: this._baseFilters.getWorkStatusFilters('Status.Name'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Type',
      propertyName: 'Type.Name',
      type: 'dropdown',
      multi: true,
      choices: this._baseFilters.getWorkTypeFilters('Type.Name'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Reason',
      propertyName: 'Reason.Name',
      type: 'dropdown',
      multi: false,
      choices: this._baseFilters.getWorkReasonFilters('Reason.Name'),
      filter: new BehaviorSubject(null),
    },
    {
      display: 'Work Performed',
      propertyName: 'WorkPerformed.Name',
      type: 'dropdown',
      multi: false,
      choices: this._baseFilters.getWorkPerformedFilters('WorkPerformed.Name'),
      filter: new BehaviorSubject(null),
    },
  ];

  constructor(private _baseFilters: FilterChoiceService, _datetimeService: DateTimeService) {
    super();
    this.push(...this.filters);
  }
}
