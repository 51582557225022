import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { ISubmitAction } from '../../base-form/base-form.component';
import { BaseFormBuilder } from '../../builder/base-form-builder';
import { IFormGroup } from '../../builder/form-builder.definition';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { StateService } from 'src/app/core/services/state.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-vertical-stepper-form',
  templateUrl: './vertical-stepper-form.component.html',
  styleUrls: ['./vertical-stepper-form.component.scss'],
})
export class VerticalStepperFormComponent implements AfterViewInit {
  @Input() linear: boolean;
  @Input() submitActions: ISubmitAction[];
  @Input()
  set formBuilder(value: BaseFormBuilder) {
    this._formBuilder = value;
    this.formGroups = this._formBuilder.getFormGroups();
  }

  @Output() formSubmit = new EventEmitter<boolean>();

  @ViewChild('formContent') contentElement: TemplateRef<HTMLSpanElement>;

  formGroups: IFormGroup[];
  linearStep: boolean;
  hideContent: boolean;
  isMobile: boolean;
  isTablet: boolean;
  private _formBuilder: BaseFormBuilder;

  @HostListener('window:resize', ['$event'])
  resize(): void {
    this.isMobile = this._stateService.isMobile();
    this.isTablet = this._stateService.isTablet();
  }

  constructor(private _stateService: StateService, private dialog: MatDialog) {
    this.linear = true;
    this.hideContent = true;
    this.submitActions = [{ name: 'Create', value: true, color: 'primary' }];
    this.resize();
  }

  ngAfterViewInit(): void {
    this._toggleContent(0);
  }

  stepChanged(event: StepperSelectionEvent): void {
    this._toggleContent(event.selectedIndex);
  }

  submitForm(action: ISubmitAction): void {
    this.formSubmit.emit(action.value);
  }

  popoutMap(): void {
    this.dialog.open(this.contentElement, {
      panelClass: 'content-outlet-dialog',
    });
  }

  private _toggleContent(formIndex: number): void {
    this.hideContent =
      this.formGroups && formIndex >= 0 && formIndex < this.formGroups.length
        ? !this.formGroups[formIndex].showMap
        : true;
  }
}
