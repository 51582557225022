import {
  Component,
  OnInit,
  ViewChild,
  ContentChild,
  TemplateRef,
  Input,
  HostListener,
  HostBinding,
  AfterViewInit,
  AfterContentInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import 'moment-timezone';
import { FilterInput } from '../filter-input';
import { DateTimeService } from 'src/app/core/services/datetime.service';
import { Q_OP_NULL, Q_OP_GTE, Q_OP_LTE, Q_OP_GT, Q_OP_LT, Q_OP_EQ } from 'src/app/core/constants/query.constants';
import { QueryFilterExpression, IQueryFilter } from 'src/app/core/interfaces/query/filters/query-filter';
import { QueryFilterBinaryExpression, QueryFilterAnd } from 'src/app/core/interfaces/query/filters/query-filter-binary';
import { DateRangeSelectorComponent } from 'src/app/shared/components/widgets/date-range-picker/date-range-picker.component';
import { UtilsService } from 'src/app/core/services/utils.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-date-filter-input',
  templateUrl: './date-filter-input.component.html',
  styleUrls: ['./date-filter-input.component.css'],
})
export class DateFilterInputComponent extends FilterInput implements OnInit, AfterViewInit, AfterContentInit {
  @ContentChild(TemplateRef) childTemplate: TemplateRef<any>;
  @ViewChild('picker') datePicker: DateRangeSelectorComponent;
  @Input() useNoDate = true;
  @Input() overlayPosition: 'start' | 'center' | 'end';
  isOpened: boolean;
  displayedDate: string;
  hasChildTemplate = false;
  dateChip: string[];
  private propId: string;

  // adds the open-dropdown class to the element that hosts this component
  @HostBinding('class.open-dropdown') dropdownClass = true;

  @HostListener('click', ['$event.target'])
  onClick(target: Element): void {
    if (target.closest('.open-dropdown')) {
      this.isOpened = !this.isOpened;
    }
  }

  constructor(private _dateService: DateTimeService, _utilsService: UtilsService) {
    super(_utilsService);
    this.isOpened = false;
  }

  ngOnInit(): void {
    this.control = new FormControl();
    this.displayedDate = this.filter.display;
    this.propId = this.filter.propertyName;
  }

  ngAfterViewInit(): void {
    const val = this.filter.filter.getValue();
    if (val) {
      this.fromQueryFilter(val);
    }
  }

  ngAfterContentInit(): void {
    if (this.childTemplate) {
      this.hasChildTemplate = true;
    }

    this.filter.filter.pipe(takeUntil(this._destroy$)).subscribe((qf: IQueryFilter) => {
      if (!qf && this.datePicker) {
        this.datePicker.date = null;
        this.dateChip = [];
        // don't emit an event to prevent retriggering this subscription
        this.datePicker.dateStart.setValue(null, { emitEvent: false });
        this.datePicker.dateEnd.setValue(null, { emitEvent: false });
      }
    });
  }

  chipListClicked(): void {
    this.isOpened = !this.isOpened;
  }

  /**
   * Initialize the component and a picker from an existing IQueryFIlter
   * @param filter The filter to initialize from, should be
   * a QueryFilterExpression or QueryFIlterAnd to match with the DatePicker
   */
  private fromQueryFilter(filter: IQueryFilter): void {
    if (filter instanceof QueryFilterExpression) {
      switch (filter.op) {
        case Q_OP_GTE:
        case Q_OP_GT:
          this.datePicker.date = [filter.value, null];
          this.datePicker.dateStart.setValue(filter.value);
          this.datePicker.dateEnd.setValue(null);
          break;
        case Q_OP_LTE:
        case Q_OP_LT:
          this.datePicker.date = [null, filter.value];
          this.datePicker.dateStart.setValue(null);
          this.datePicker.dateEnd.setValue(filter.value);
          break;
        case Q_OP_EQ:
          this.datePicker.date = [filter.value, filter.value];
          this.datePicker.dateStart.setValue(filter.value);
          this.datePicker.dateEnd.setValue(filter.value);
          break;
        case Q_OP_NULL:
          this.datePicker.date = 'no date';
          this.datePicker.dateStart.setValue(null);
          this.datePicker.dateEnd.setValue(null);
          break;
      }
    } else if (filter instanceof QueryFilterBinaryExpression) {
      let left = filter.left as QueryFilterExpression;
      let right = filter.right as QueryFilterExpression;
      if (!(left.op === Q_OP_GTE || left.op === Q_OP_GT)) {
        const temp = left;
        left = right;
        right = temp;
      }

      this.datePicker.date = [left.value, right.value];
      this.datePicker.dateStart.setValue(left.value);
      this.datePicker.dateEnd.setValue(right.value);
    }

    // force an update
    this.emitIfChanged(filter, true);
  }

  remove(val: string): void {
    this.dateChip = [];
    if (!val) {
      this.datePicker.remove();
    } else if (val.includes('From') || val.includes('Start')) {
      this.datePicker.remove(true, false);
    } else if (val.includes('To') || val.includes('End')) {
      this.datePicker.remove(false, true);
    } else {
      this.datePicker.remove();
    }
    // if using 'clear button' template.The chip remove icon delete the chip
    // will add 'Add Date Range' chip instead.
    if (!this.useNoDate) {
      this.dateChip = ['Add Date Range'];
    }
  }

  /**
   * Given an emitted event from the date picker, build an IQueryFilter from it.
   * @param dateRange The date range to build the filter from.
   */
  buildDateFilter(dateRange: [moment.Moment, moment.Moment] | 'no date'): IQueryFilter {
    let filterEvent: IQueryFilter = null;
    let startDate: string;
    let endDate: string;

    if (dateRange === 'no date') {
      filterEvent = new QueryFilterExpression(this.propId, Q_OP_NULL);
      startDate = 'no date';
    } else if (dateRange && (dateRange[0] || dateRange[1])) {
      startDate = this._dateService.startOfDay(dateRange[0]);
      endDate = this._dateService.endOfDay(dateRange[1]);
      if (startDate && endDate) {
        const left = new QueryFilterExpression(this.propId, Q_OP_GTE, startDate);
        const right = new QueryFilterExpression(this.propId, Q_OP_LTE, endDate);
        filterEvent = new QueryFilterAnd(left, right);
      } else if (startDate) {
        filterEvent = new QueryFilterExpression(this.propId, Q_OP_GTE, startDate);
      } else if (endDate) {
        filterEvent = new QueryFilterExpression(this.propId, Q_OP_LTE, endDate);
      }
    } else {
      this.datePicker.clear();
    }
    return filterEvent;
  }

  /**
   * Call back when the date picker dropdown emits a change event.
   * Builds a IQueryFilter from the emitted date range
   * @param dateRange The range emitted from the picker,
   * can be and array of dates like [Date, Date], the string 'no date', or null for no filter
   */
  dateChanged(dateRange: [moment.Moment, moment.Moment] | 'no date'): void {
    // Build the filter event from the date range.
    const newFilter = this.buildDateFilter(dateRange);
    this.emitIfChanged(newFilter);
  }

  /**
   * Checks if the input value against the set filter's value to see if there is
   * a change, if so emit it to subscribers.
   * @param newFilter The proposed new value to emit to subscribers
   * @param force Whether we should check if the value has changed or not,
   * if true this emits regardless if they're the same
   */
  private emitIfChanged(newFilter: IQueryFilter, force: boolean = false): void {
    let hasChanged: boolean;
    if (!force) {
      hasChanged = this.hasChanged(newFilter);
    } else {
      hasChanged = true;
    }

    // emit only if changed
    if (hasChanged) {
      if (newFilter) {
        newFilter.displayName = this.buildDisplayedDate();
      }
      this.filter.filter.next(newFilter);

      // For clear button click action
      // remove the current date filter and add 'Add Date Range' chip instead
      if (!newFilter && !this.useNoDate) {
        this.dateChip = ['Add Date Range'];
      }
    }
  }

  /**
   * Build the label text for the input control, uses the filter display if
   * both startDate and endDate are null
   * @param startDate The start date string, 'no date' literal or null
   * @param endDate The end date string or null
   */
  private buildDisplayedDate(): string {
    if (this.datePicker.date === 'no date') {
      const val = 'No Date';
      this.dateChip = [val];
      return val;
    } else if (!this.datePicker.date) {
      this.dateChip = [];
      return this.filter.display;
    } else {
      const [startDate, endDate] = this.datePicker.date;

      this.dateChip = this._dateService.dateRangeToStringList(startDate, endDate);
      return this.dateChip.join(' ');
    }
  }
}
