import { Component, OnInit, ViewChild, Input, EventEmitter, Output, AfterViewInit, HostListener } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable, BehaviorSubject } from 'rxjs';
import { withLatestFrom, map } from 'rxjs/operators';
import { IQueryResult } from 'src/app/core/interfaces/models';
import { ITableColumn } from 'src/app/core/interfaces/definitions';
import { EntityDataSource } from 'src/app/core/providers/entity-data-source';
import { StateService } from 'src/app/core/services/state.service';
import { PAGINATOR_ALL_ITEMS_COUNT } from 'src/app/core/constants/constants';

@Component({
  selector: 'app-table-rows',
  templateUrl: './table-rows.component.html',
  styleUrls: ['./table-rows.component.scss'],
})
export class TableRowsComponent<T> implements OnInit, AfterViewInit {
  /**
   * View Child: paginator
   */
  @ViewChild(MatPaginator) paginator: MatPaginator;
  /**
   * View Child: sort
   */
  @ViewChild(MatSort) tableSort: MatSort;
  /**
   * Input dataSubject
   */
  @Input() dataSubject: Observable<IQueryResult>;
  /**
   * Input paginator options
   */
  @Input() pageSize: number;
  @Input() pageSizeOptions: number[];
  /**
   * Input multiSelect
   */
  @Input() multiSelect: boolean;
  /**
   * Input modelName
   */
  @Input() modelName: string;
  /**
   * Input columns
   */
  @Input()
  set columns(value: ITableColumn<T>[]) {
    this.showColumns = value.filter((c) => c.visible);
    this.columnHeaders = this.showColumns.map((c) => c.id);
  }
  get columns(): ITableColumn<T>[] {
    return this.showColumns;
  }
  /**
   * Output downloadRequest
   */
  @Output() downloadRequest = new EventEmitter<T>();
  /**
   * Output selectEvent
   */
  @Output() selectEvent = new EventEmitter<T[]>();
  /**
   * Output selectEvent
   */
  @Output() sortEvent = new EventEmitter<Sort>();
  /**
   * Output selectEvent
   */
  @Output() pageEvent = new EventEmitter<PageEvent>();
  /**
   * Class Fields
   */
  showColumns: ITableColumn<T>[];
  canDownload: boolean;
  sourceSubject: BehaviorSubject<T[]>;
  columnHeaders: string[];
  dataSource: EntityDataSource<T>;
  selection: SelectionModel<T>;
  isMobile: boolean;
  forceMultiselect: boolean;
  pageSizeOptionsWithTotal: number[];
  private _oldPage: PageEvent;
  private _stopSelectEvenPropogation: boolean;

  @HostListener('window:resize', ['$event'])
  resize(): void {
    this.isMobile = this._stateService.isMobile();
  }

  constructor(private _stateService: StateService) {
    this.canDownload = false;
    this.sourceSubject = new BehaviorSubject([]);
    this.dataSource = new EntityDataSource<T>(this.sourceSubject);
    this._stopSelectEvenPropogation = false;
    this.resize();
  }

  ngOnInit(): void {
    /* Hack: No way to dynamically update paginator after retrieving the data count, so use the max int value */
    this.pageSizeOptionsWithTotal = [...this.pageSizeOptions, PAGINATOR_ALL_ITEMS_COUNT];

    this.dataSubject
      .pipe(
        withLatestFrom(this.pageEvent),
        map(([res, page]) => {
          if (page === this._oldPage && this._oldPage.pageIndex !== 0) {
            // Check that the paginator has been initalized first, there is a small chance it may not be yet.
            if (this.paginator) {
              // The page didn't change so something else updated which means the paginator should be reset
              this.paginator.firstPage();
            }

            // The page changing will trigger a new event so don't update
            return null;
          } else {
            this._oldPage = page as PageEvent;
            return res;
          }
        })
      )
      .subscribe((res: IQueryResult) => {
        if (res !== null) {
          this.sourceSubject.next(res.items);
          this.dataSource.updateResultsLength(res.totalCount);
        }
      });

    this.selection = new SelectionModel<T>(this.multiSelect, []);

    // Intercept the selection changed event and emit a custom
    // event for the Host Component to handle..
    this.selection.changed.subscribe(() => {
      if (!this._stopSelectEvenPropogation) {
        this.toggleMultiselect();
        this.selectEvent.emit(this.getSelection());
      }
    });
  }

  ngAfterViewInit(): void {
    // Intercept paginator page events and emit a custom
    // event for the Host Component to handle.
    this.paginator.page.subscribe((event: PageEvent) => {
      this.pageEvent.emit(event);
    });
    this.dataSource.paginator = this.paginator;

    // Intercept the sort changed event and emit a custom
    // event for the Host Component to handle..
    if (this.tableSort) {
      this.tableSort.sortChange.subscribe((event: Sort) => {
        this.sortEvent.emit(event);
      });
      this.dataSource.sort = this.tableSort;
    }

    // Force a refresh to update the pagnator settings
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  toggleSelection(data: T, event: MouseEvent): void {
    if (!this.hasMultipleSelected() && !event?.ctrlKey) {
      this.selection.clear();
    }
    this.selection.toggle(data);
  }

  /**
   * If all rows are selected, deselect all
   * If some rows are selected, select all
   */
  public masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.forceMultiselect = false;
      this.toggleMultiselect();
    } else {
      this.selection.clear();
      for (const row of this.sourceSubject.value) {
        this.selection.toggle(row);
      }
    }
  }

  public isAllSelected(): boolean {
    return this.selection.selected.length === this.sourceSubject.value.length;
  }

  public hasSelection(): boolean {
    return this.selection.selected.length > 0;
  }

  public toggleForceMultiselect(): void {
    // don't toggle if we already have multiple selected
    if (this.selection.selected.length < 2) {
      this.forceMultiselect = !this.forceMultiselect;
      this.toggleMultiselect();
    }
  }

  private toggleMultiselect(): void {
    if (this.hasMultipleSelected() && !this.columnHeaders.includes('select')) {
      this.columnHeaders.unshift('select');
    } else if (!this.hasMultipleSelected() && this.columnHeaders.includes('select')) {
      this.columnHeaders.shift();
    }
  }

  private hasMultipleSelected(): boolean {
    return this.forceMultiselect || this.selection.selected.length > 1;
  }

  getSelection(): T[] {
    return this.hasSelection() ? this.selection.selected : null;
  }

  setSelection(data: T[]): void {
    this._stopSelectEvenPropogation = true;
    this.selection.clear();
    this.selection.select(...data);
    this._stopSelectEvenPropogation = false;
  }

  clearSelection(): void {
    this.selection.clear();
  }

  downloadItem(data: T): void {
    if (this.canDownload) {
      if (data !== null) {
        this.downloadRequest.emit(data);
      }
    }
  }
}
